import React from "react";
import ErrorImg from "../../Assets/ErrorPage/401 Error Unauthorized-cuate 1.svg";

export default function Error401() {
  return (
    <main className="h-screen flex justify-center items-center">
      <div className="absolute top-0">
        <p className="text-[red] font-extrabold text-[16px] w-full text-center ">
          CC -401 ERROR V1 / MAR 15, 2025
        </p>
        <p className="text-[#000] font-bold  text-[11px] w-full text-center">
          Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
        </p>
      </div>
      <div className="flex justify-center items-center flex-col">
        <img src={ErrorImg} />
        <div className="w-3/4">
          <h3 className="text-[16px] font-bold mt-4 text-center">
            The request requires users authentication
          </h3>
        </div>
        <div className="flex justify-between w-full mt-4">
          <button
            className="mt-4 bg-[#000] w-36 h-15  text-[#fff] p-1 font-bold text-base"
            style={{ boxShadow: "rgb(0, 255, 71) 6px 6px 0px 2px" }}
          >
            Back
          </button>
          <button
            className="mt-4 bg-[#000] w-36 h-15  text-[#fff] p-1 font-bold text-base"
            style={{ boxShadow: "rgb(0, 255, 71) 6px 6px 0px 2px" }}
          >
            Need Help!
          </button>
        </div>
      </div>
    </main>
  );
}
