import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

/* get the Saved Videos */
export const getSavedFolders = createAsyncThunk(
  "getSavedFolders",
  async (id) => {
    const response = await axiosInstance.get(`/post/folders/${id}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
/* delete the folder */
export const deleteSavedFolders = createAsyncThunk(
  "deleteSavedFolders",
  async (id) => {
    const response = await axiosInstance.delete(`/post/folders/${id}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
/* delete the folder inside video delete */
export const deleteSavedVideo = createAsyncThunk(
  "deleteSavedVideo",
  async (data) => {
    const response = await axiosInstance.delete(`/post/folders/${data.folder_id}/delete_video/${data.post_id}`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
/* Rename the folder */
export const renameSavedFolders = createAsyncThunk(
  "renameSavedFolders",
  async (data) => {
    let form_data = new FormData();
    form_data.append("name", data.name);
    try {
      const response = await axiosInstance.put(
        `/post/folders/${data.folderId}/`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

/* create the folder */
export const createFolder = createAsyncThunk("createFolder", async (data) => {
  let form_data = new FormData();
  form_data.append("name", data.name);
  try {
    const response = await axiosInstance.post(
      `/post/folders/${data.userId}/`,
      form_data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});

/* add the video into save folder */

export const SavedTheVideoInsideFolder = createAsyncThunk(
  "SavedTheVideoInsideFolder",
  async (data) => {
    const response = await axiosInstance.post(
      `/post/folders/${data.folderId}/add_video/${data.videoId}/`
    );
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
/* get individual folder data */
export const getIndividualFolderData = createAsyncThunk(
  "getIndividualFolderData",
  async (data) => {
    const response = await axiosInstance.get(`/post/folders/${data}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const SavedVideosSlice = createSlice({
  name: "SavedVideosSlice",
  initialState: {
    SavedVideos: [],
    SavedFolderList: [],
    FolderData: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // SavedVideosSlice action promises
    builder.addCase(createFolder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createFolder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createFolder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // getSavedFolders action promises
    builder.addCase(getSavedFolders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSavedFolders.fulfilled, (state, action) => {
      state.SavedFolderList = action.payload;
      state.loading = false;
    });
    builder.addCase(getSavedFolders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // deleteSavedFolders action promises
    builder.addCase(deleteSavedFolders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSavedFolders.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSavedFolders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // SavedTheVideoInsideFolder action promises
    builder.addCase(SavedTheVideoInsideFolder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SavedTheVideoInsideFolder.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(SavedTheVideoInsideFolder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // getIndividualFolderData action promises
    builder.addCase(getIndividualFolderData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getIndividualFolderData.fulfilled, (state, action) => {
      state.FolderData = action.payload;
      state.loading = false;
    });
    builder.addCase(getIndividualFolderData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default SavedVideosSlice.reducer;
