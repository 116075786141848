import React, { Fragment } from "react"
import Account from "../../Component/settingspage/Account"

const AccountPage = () => {
   return (
      <Fragment>
         <p className="absolute top-1 text-red-700 uppercase font-bold w-full text-[10px] text-center">
            cc settings account / fe / v2 / july 03, 2024
         </p>
         
         <div className="pt-1">
            <Account />
         </div>
      </Fragment>
   )
}

export default AccountPage