import React, { useEffect } from "react";
import AccountTab from "../../Component/AccountPage/AccountTab";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import { UserlikedVideos } from "../../Redux/ApiSlice/AccountProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { LikedTab } from "../../Component/AccountPage/LikedTab";

export default function Liked() {
  const dispatch = useDispatch();
  const accountProfile = useSelector((state) => state.AccountProfile);

  const { User_likedVideos } = accountProfile;
  const fetchData = async()=>{
   await dispatch(UserlikedVideos());
  }
  useEffect(() => {
    fetchData()
  }, [dispatch]);
  return (
    <div className="bg-[#000] overflow-hidden h-full relative ">
      <p className="text-[#ff3b3b] text-center w-full  mb-2 text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
        CC ACCOUNT LIKED PAGE-FE - 11 / JUNE 29, 2024
      </p>
      <div className=" ">
        <AccountProfileSection />
      </div>
      <AccountTab activeTab={"Liked"} />
      <div className="p-2 overflow-y-scroll no-scrollbar w-full h-[-webkit-fill-available] absolute ">
        <LikedTab User_likedVideos={User_likedVideos} />
      </div>
    </div>
  );
}
