import { useNavigate } from "react-router-dom";
import SecretSauceButton from "../SecretSauceButton/SecretSauceButton";
import { Checkbox } from "@material-tailwind/react";

export default function ChangeNewPassWord({ formik }) {
  const navigate = useNavigate();
  const handleClick = (value) => {
    navigate(`/${value}`);
  };

  return (
    <div className="h-full w-full relative overflow-hidden">
      <div className="h-[85%] grid grid-cols-12 w-full">
        <div className=" col-span-12 flex items-center bg-[#fff] flex-col justify-center">
          <div className="flex justify-center font-semibold text-sm  bg-[#fff] items-center ">
            <p className="text-[#ff3b3b] font-extrabold text-[14px] md:text-[10px] absolute top-0 w-full text-center ">
              CC CHANGE PASSWORD - FE - V3 - JULY 19, 2024
            </p>
          </div>
          <h1 className="text-[#00A3FF] text-[6rem] md:text-[5rem] font-bold">CC</h1>
          <h3 className="font-extrabold text-xl">Change Password</h3>
          <p className="text-xl md:text-md font-semibold text-center w-full m-2">
            Create New password
          </p>
        </div>
        <div className="col-span-12">
          <p className="text-[16px] md:text-[14px] text-center font-bold">
            Password must be a minimum of 8 characters
          </p>
        </div>
        <div className="col-span-12 overflow-auto w-full bg-[#fff] px-4">
          <div className="mobile-forgotpassword-form-container">
            <form className="w-full h-full" onSubmit={formik.handleSubmit}>
              <div className="flex flex-col mb-4 w-full">
                <label className="mb-2">New password</label>
                <input
                  className="border border-black p-3 md:p-2 outline-none"
                  type="password"
                  placeholder="New password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password && (
                  <p className="text-[#ff3b3b] md:text-[15px]  font-medium">
                    {formik.errors.password}
                  </p>
                )}
              </div>
              <div className="flex flex-col mb-4 w-full">
                <label className="mb-2">Confirm New Password</label>
                <input
                  className="border border-black p-3 md:p-2 outline-none"
                  type="password"
                  placeholder="Confirm new password"
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <p className="text-[#ff3b3b] md:text-[15px]  font-medium">
                      {formik.errors.confirmPassword}
                    </p>
                  )}
              </div>

              <div className="flex items-center mt-2 mb-4 md:mb-2">
                <p className="flex gap-2 items-center text-sm font-semibold">
                  <Checkbox color="blue" />
                  Logout from all devices
                </p>
              </div>
              <div className="w-full flex justify-center items-center ">
                <button
                  className="bg-black text-[#fff] text-sm p-4 px-3 py-2 rounded-md font-bold"
                  onClick={() => navigate("/login")}
                >
                  Reset & Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <SecretSauceButton />
      <div className="flex justify-center items-center h-[8%] md:h-[7%] bg-[#000]">
        <button
          className="rounded-3xl px-4 py-1  bg-[#d9d9de]  font-bold text-base"
          onClick={() => handleClick("register")}
        >
          Register
        </button>
      </div>
    </div>
  );
}
