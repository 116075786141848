// import React, { useState } from 'react';
// import { FFmpeg } from '@ffmpeg/ffmpeg';

// const ffmpeg = new FFmpeg({ log: true });

// const Example = () => {
//   const [videoFile, setVideoFile] = useState();
//   const [audioFile, setAudioFile] = useState();
//   const [outputVideo, setOutputVideo] = useState();

//   const replaceAudio = async () => {
//     // Directly attempt to load FFmpeg without checking isLoaded
//     await ffmpeg.load();

//     // Write the video and audio files to FFmpeg's virtual file system
//     ffmpeg.FS('writeFile', 'inputVideo.mp4', await ffmpeg.fetchFile(videoFile));
//     ffmpeg.FS('writeFile', 'inputAudio.mp3', await ffmpeg.fetchFile(audioFile));

//     // Run the FFmpeg command to replace the audio track
//     await ffmpeg.run('-i', 'inputVideo.mp4', '-i', 'inputAudio.mp3', '-c:v', 'copy', '-map', '0:v:0', '-map', '1:a:0', 'outputVideo.mp4');
//     // Read the result
//     const data = ffmpeg.FS('readFile', 'outputVideo.mp4');

//     // Continue with creating a URL for the output video file
//   };

//   return (
//     <div>

//       <input type="file" onChange={(e) => setVideoFile(e.target.files?.[0])} />
//       <input type="file" onChange={(e) => setAudioFile(e.target.files?.[0])} />
//       <button onClick={replaceAudio}>Replace Audio</button>
//       {outputVideo && <video src={outputVideo} controls />}
//     </div>
//   );
// };

// export default Example;


// import React, { useState, useEffect } from 'react';
// import { FFmpeg } from '@ffmpeg/ffmpeg';

// const Example = () => {
//     const [ffmpeg, setFfmpeg] = useState(null);
//     const [ready, setReady] = useState(false);
//     const [videoFile, setVideoFile] = useState(null);
//     const [audioFile, setAudioFile] = useState(null);
//     const [outputUrl, setOutputUrl] = useState('');

//     useEffect(() => {
//         const loadFFmpeg = async () => {
//             const ffmpegInstance = new FFmpeg({ log: true });
//             await ffmpegInstance.load();
//             setFfmpeg(ffmpegInstance);
//             setReady(true);
//         };
//         loadFFmpeg();
//     }, []);

//     const handleVideoUpload = (event) => {
//         setVideoFile(event.target.files[0]);
//     };

//     const handleAudioUpload = (event) => {
//         setAudioFile(event.target.files[0]);
//     };

//     const replaceAudio = async () => {
//         ffmpeg.FS('writeFile', 'video.mp4', await fetchFile(videoFile));
//         ffmpeg.FS('writeFile', 'audio.mp3', await fetchFile(audioFile));

//         await ffmpeg.run('-i', 'video.mp4', '-i', 'audio.mp3', '-c:v', 'copy', '-c:a', 'aac', '-strict', 'experimental', 'output.mp4');

//         const data = ffmpeg.FS('readFile', 'output.mp4');
//         const url = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
//         setOutputUrl(url);
//     };

//     const fetchFile = async (file) => {
//         const response = await fetch(URL.createObjectURL(file));
//         return new Uint8Array(await response.arrayBuffer());
//     };

//     return (
//         <div>
//             <h1>Replace Audio in Video</h1>
//             {!ready && <div>Loading FFmpeg...</div>}
//             {ready && (
//                 <>
//                     <input type="file" onChange={handleVideoUpload} accept="video/*" />
//                     <input type="file" onChange={handleAudioUpload} accept="audio/*" />
//                     <button onClick={replaceAudio}>Replace Audio</button>
//                     {outputUrl && (
//                         <video controls src={outputUrl} style={{ width: '500px' }}></video>
//                     )}
//                 </>
//             )}
//         </div>
//     );
// };

// export default Example;

// import React, { useState, useEffect } from 'react';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

// const Example = () => {
//     const [ffmpeg, setFfmpeg] = useState(null);
//     const [ready, setReady] = useState(false);
//     const [videoFile, setVideoFile] = useState(null);
//     const [audioFile, setAudioFile] = useState(null);
//     const [outputUrl, setOutputUrl] = useState('');

//     useEffect(() => {
//         const loadFFmpeg = async () => {
//             const ffmpegInstance = createFFmpeg({ log: true });
//             await ffmpegInstance.load();
//             setFfmpeg(ffmpegInstance);
//             setReady(true);
//         };
//         loadFFmpeg();
//     }, []);

//     const handleVideoUpload = (event) => {
//         setVideoFile(event.target.files[0]);
//     };

//     const handleAudioUpload = (event) => {
//         setAudioFile(event.target.files[0]);
//     };

//     const replaceAudio = async () => {
//         ffmpeg.FS('writeFile', 'video.mp4', await fetchFile(videoFile));
//         ffmpeg.FS('writeFile', 'audio.mp3', await fetchFile(audioFile));

//         await ffmpeg.run('-i', 'video.mp4', '-i', 'audio.mp3', '-c:v', 'copy', '-c:a', 'aac', '-strict', 'experimental', 'output.mp4');

//         const data = ffmpeg.FS('readFile', 'output.mp4');
//         const url = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
//         setOutputUrl(url);
//     };

//     return (
//         <div>
//             <h1>Replace Audio in Video</h1>
//             {!ready && <div>Loading FFmpeg...</div>}
//             {ready && (
//                 <>
//                     <input type="file" onChange={handleVideoUpload} accept="video/*" />
//                     <input type="file" onChange={handleAudioUpload} accept="audio/*" />
//                     <button onClick={replaceAudio}>Replace Audio</button>
//                     {outputUrl && (
//                         <video controls src={outputUrl} style={{ width: '500px' }}></video>
//                     )}
//                 </>
//             )}
//         </div>
//     );
// };

// export default Example;


// import React, { useState, useEffect } from 'react';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

// const Example = () => {
//     const [ffmpeg, setFfmpeg] = useState(null);
//     const [ready, setReady] = useState(false);
//     const [videoFile, setVideoFile] = useState(null);
//     const [audioFile, setAudioFile] = useState(null);
//     const [outputUrl, setOutputUrl] = useState('');

//     useEffect(() => {
//         const loadFFmpeg = async () => {
//             const ffmpegInstance = createFFmpeg({ log: true });
//             await ffmpegInstance.load();
//             setFfmpeg(ffmpegInstance);
//             setReady(true);
//         };
//         loadFFmpeg();
//     }, []);

//     const handleVideoUpload = (event) => {
//         setVideoFile(event.target.files[0]);
//     };

//     const handleAudioUpload = (event) => {
//         setAudioFile(event.target.files[0]);
//     };

//     const replaceAudio = async () => {
//         if (!ffmpeg) {
//             console.error('FFmpeg is not loaded');
//             return;
//         }

//         ffmpeg.FS('writeFile', 'video.mp4', await fetchFile(videoFile));
//         ffmpeg.FS('writeFile', 'audio.mp3', await fetchFile(audioFile));

//         await ffmpeg.run('-i', 'video.mp4', '-i', 'audio.mp3', '-c:v', 'copy', '-c:a', 'aac', '-strict', 'experimental', 'output.mp4');

//         const data = ffmpeg.FS('readFile', 'output.mp4');
//         const url = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
//         setOutputUrl(url);
//     };

//     return (
//         <div>
//             <h1>Replace Audio in Video</h1>
//             {!ready && <div>Loading FFmpeg...</div>}
//             {ready && (
//                 <>
//                     <input type="file" onChange={handleVideoUpload} accept="video/*" />
//                     <input type="file" onChange={handleAudioUpload} accept="audio/*" />
//                     <button onClick={replaceAudio}>Replace Audio</button>
//                     {outputUrl && (
//                         <video controls src={outputUrl} style={{ width: '500px' }}></video>
//                     )}
//                 </>
//             )}
//         </div>
//     );
// };

// export default Example;





import React, { useState } from 'react';

const Example = () => {
    const [videoFile, setVideoFile] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [outputVideoUrl, setOutputVideoUrl] = useState('');

    const handleVideoFileChange = (event) => {
        setVideoFile(event.target.files[0]);
    };

    const handleAudioFileChange = (event) => {
        setAudioFile(event.target.files[0]);
    };

    const replaceAudio = async () => {
        if (!videoFile || !audioFile) {
            console.error('Both video and audio files are required');
            return;
        }

        const formData = new FormData();
        formData.append('video', videoFile);
        formData.append('audio', audioFile);

        try {
            const response = await fetch('YOUR_SERVER_ENDPOINT', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Server error');
            }

            const result = await response.blob();
            const url = URL.createObjectURL(result);
            setOutputVideoUrl(url);
        } catch (error) {
            console.error('Error processing video and audio:', error);
        }
    };

    return (
        <div>
            <h1>Replace Audio in Video</h1>
            <input type="file" accept="video/*" onChange={handleVideoFileChange} />
            <input type="file" accept="audio/*" onChange={handleAudioFileChange} />
            <button onClick={replaceAudio}>Replace Audio</button>
            {outputVideoUrl && <video src={outputVideoUrl} controls />}
        </div>
    );
};

export default Example;