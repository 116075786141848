import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Components/Css/VideoCard.css"
import Layout from "./Layout";
import MessagesPage from "./Components/Webversion/MessagesPage/MessagesPage";
import RegisterPage from "./Components/MobileVersion/MobileRegisterPage/RegisterPage";
import OtpPage from "./Components/MobileVersion/OtpPage/OtpPage";
import ChangeNewPassWord from "./Components/MobileVersion/ChangeNewPassWord/ChangeNewPassWord";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import SecurityPage from "./pages/SecurityPage";
import SecurityPageSettings from "./pages/settings/SecurityPage";
import CommingSoonPage from "./pages/CommingSoonPage";
import WebLayout from "./Layouts/WebLayout";
import AccountPage from "./pages/AccountPage";
import AccountPageSettings from "./pages/settings/AccountPage";
import HashTagPage from "./pages/HashTagPage";
import LiveStreamPage from "./pages/LiveStreamPage";
import CreatedInduvitualGroup from "./pages/CreatedInduvitualGroup";
import CreatedGroupInfo from "./Component/CreatedGroup/CreatedGroupInfo";
import CreatedGroupUpdates from "./Component/CreatedGroup/CreatedGroupUpdates";
import CreatedGroupChatRoom from "./Component/CreatedGroup/CreatedGroupChatRoom";
import SearchResultPage from "./pages/SearchResultPage";
import CreatedGroupSetting from "./Component/CreatedGroup/CreatedGroupSetting";
import CreatedGroupMembers from "./Component/CreatedGroup/CreatedGroupMembers";
import Upload from "./Component/videopage/Upload";
import LostPassword from "./pages/LostPassword";
import EnterOTP from "./pages/EnterOTP";
import ChangePassword from "./pages/ChangePassword";
import { MessagesConversationTab } from "./Component/MobileMessagesTabs/MessagesConversationTab";
import MessagesWellComePage from "./Components/Webversion/MessagesPage/MessagesWellComePage";
import WebMessagesChat from "./Component/MobileMessagesTabs/WebMessagesChat";
import Create from "./Component/videopage/Create";
import Error400 from "./pages/ErrorPages/Error400";
import Error401 from "./pages/ErrorPages/Error401";
import Error403 from "./pages/ErrorPages/Error403";
import Error404 from "./pages/ErrorPages/Error404";
import Error408 from "./pages/ErrorPages/Error408";
import Error503 from "./pages/ErrorPages/Error503";
import Error500 from "./pages/ErrorPages/Error500";
import Error501 from "./pages/ErrorPages/Error501";
import Error502 from "./pages/ErrorPages/Error502";
import ShopPage from "./pages/shop/ShopPage";
import ShopDetails from "./pages/shop/ShopDetails";
import Category from "./pages/shop/Category";
import WishlistPage from "./pages/shop/WishlistPage";
import BasketPage from "./pages/shop/BasketPage";
import AddressPage from "./pages/shop/AddressPage";
import PaymentPage from "./pages/shop/PaymentPage";
import OrderPlacedPage from "./pages/shop/OrderPlacedPage";
import OrderPage from "./pages/shop/OrderPage";
import OrderDetailsPage from "./pages/shop/OdrderDetailsPage";
import CreateGroupPage from "./pages/group/CreateGroupPage";
import InviteMemberPage from "./pages/group/InviteMemberPage";
import Updates from "./pages/Account/Updates";
import Repost from "./pages/Account/Repost";
import Saved from "./pages/Account/Saved";
import Private from "./pages/Account/Private";
import Liked from "./pages/Account/Liked";
import Group from "./pages/Account/Group";
// import Messages from "./pages/Account/Messages";
import Posted from "./pages/Account/Posted";
import Following from "./pages/Account/Following";
import Follower from "./pages/Account/Follower";
import Posts from "./pages/Account/Posts";
import OtherUserAccount from "./pages/OtherUserAccount/OtherUserAccount";
import HashTagStore from "./pages/HashTag/HashTagStore";
import HashTagTrending from "./pages/HashTag/HashTagTrending";
import MessagesPageMobile from "./pages/MessagesPage";
import HashTagRecent from "./pages/HashTag/HashTagRecent";
import HashTagPopular from "./pages/HashTag/HashTagPopular";
import HashTagOtherUser from "./Component/HashTag/HashTagOtherUser";
import DraftNewMessages from "./pages/MessagesSub/DraftNewMessages";
import CreatedGroupSearch from "./Component/CreatedGroup/CreatedGroupSearch";
import CreatedGroupPost from "./Component/CreatedGroup/CreatedGroupPost";
import GroupChat from "./pages/MessagesSub/GroupChat";
import UserChat from "./pages/MessagesSub/UserChat";
import { useDispatch } from "react-redux";
import OrderCancel from "./Component/shoppage/OrderCancel";
import CreatedGroupModerators from "./Component/CreatedGroup/CreatedGroupModerators";
import OtherUserGroupsPage from "./pages/OtherUserGroupsPage";
import { AccountProfile } from "./Redux/ApiSlice/AccountProfileSlice";
import OtherGroupSearch from "./Component/OtherUserGroup/OtherGroupSearch";
import OtherGroupPost from "./Component/OtherUserGroup/OtherGroupPost";
import OtherGroupInfo from "./Component/OtherUserGroup/OtherGroupInfo";
import OtherGroupModerators from "./Component/OtherUserGroup/OtherGroupModerators";
import OtherGroupMember from "./Component/OtherUserGroup/OtherGroupMember";
import OtherGroupChatroom from "./Component/OtherUserGroup/OtherGroupChatroom";
import SavedFolderData from "./pages/Account/SavedFolderData";
import HelpMain from "./pages/Help/HelpMain";
import AfterInstallation from "./pages/AfterInstallation";
import ReadMoreAnswers from "./pages/Help/ReadMoreAnswers";
import Questions from "./pages/Help/Questions";
import MessagesProfileOtherUser from "./pages/MessagesSub/MessagesProfileOtherUser";
import ContactUsMain from "./pages/ContactUs/ContactUsMain";
import LoginPage from "./pages/Login/LoginPage";
import SignupPage from "./pages/SignUp/SignupPage";
import HomeMainPage from "./pages/HomeMainPage";
import { HomepageVideo } from "./Redux/ApiSlice/HomePageVideoSlice";
import CreateLiveStream from "./pages/LiveSream/CreateLiveStream";
import TrendingMain from "./pages/TrendingVideos/TrendingMain";
import UploadStream from "./pages/LiveSream/UploadStream";
import StreamSettings from "./pages/LiveSream/StreamSettings";
import MobileLayout from "./Components/MobileVersion/MobilePanel/Layout";
import ForgotPasswordContainer from "./pages/Account/Private/ForgotPasswordContainer";
import ResetPasswordContainer from "./pages/Account/Private/ResetPasswordContainer";
import SetPassword from "./pages/Account/Private/SetPassword";
import AccountLayOut from "./pages/Account/AccountLayOut";
import EnterOtpContainer from "./pages/Account/Private/EnterOtpContainer";
import Cookies from "./pages/Cookies";
import Media from "./pages/Media";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SettingsPage from "./pages/settings/SettingsPage";
import PreferencesPage from "./pages/settings/PreferencesPage";
import NotificationsPage from "./pages/settings/NotificationsPage";
import PrivacyPage from "./pages/settings/PrivacyPage";
import WhatsNewPage from "./pages/settings/WhatsNewPage";
import PrivateCreate from "./pages/Account/PrivateCreate";
import PrivateEmpty from "./pages/Account/Private/PrivateEmpty";
import WatchVideo from "./pages/WatchVideo/WatchVideo";
import GroupPostReport from "./pages/group/GroupPostReport";
import GroupPostComments from "./pages/group/GroupPostComments";
import MutualFriendsList from "./pages/MessagesSub/MutualFriendsList";
import OtherUserPostComments from "./pages/OtherUserGroup/OtherUserPostComments";
import OtherUserPostReport from "./pages/OtherUserGroup/OtherUserPostReport";
import Register from "./pages/SignUp/Register";
import OtherUserGroupList from "./pages/OtherUserGroup/OtherUserGroupList";
import ProfileEdit from "./pages/Account/ProfileEdit";
import AchivedMessages from "./pages/MessagesSub/AchivedMessages";
import Example from "./Component/Example";

function App() {
   const dispatch = useDispatch();
   const accessToken = localStorage.getItem("accessToken");
   const security_login = localStorage.getItem("security_login");

   const getData = async () => {
      if (accessToken && security_login) {
         await dispatch(HomepageVideo());
         dispatch(AccountProfile());
      }
   };
   useEffect(() => {
      getData();
   }, []);
   return (
      <Router>
         <Routes>
            <Route path="/" element={
               <WebLayout>
                  <SecurityPage />
               </WebLayout>
            }></Route>
            <Route
               path="/login"
               element={
                  <PublicRoute>
                     <WebLayout>
                        <LoginPage />
                     </WebLayout>
                  </PublicRoute>
               }
            ></Route>
            <Route
               path="/example"
               element={<Example />}>

            </Route>

            <Route
               path="/signup"
               element={
                  <PublicRoute>
                     <WebLayout>
                        <SignupPage />
                     </WebLayout>
                  </PublicRoute>
               }
            ></Route>
            <Route
               path="/register"
               element={
                  <PublicRoute>

                     <WebLayout>
                        <Register />
                     </WebLayout>
                  </PublicRoute>
               }
            ></Route>

            {/* <Route
               path="/register"
               element={
                  <PublicRoute>
                     <RegisterPage />
                  </PublicRoute>
               }
            ></Route> */}

            {/* new router  */}
            <Route
               path="/home"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <HomeMainPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />

            <Route
               path="/watchvideo/:id"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <WatchVideo />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            {/* 
  
          {/* account router start */}c
            <Route
               path="/Account"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <AccountPage />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/updates"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Updates />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/repost"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Repost />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/saved"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Saved />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/saved/folder/:id"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <SavedFolderData />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/createpassword"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <PrivateCreate />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/private/empty"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <PrivateEmpty />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/private"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Private />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/private/forgotpassword"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <ForgotPasswordContainer />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/private/resetpassword"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           {/* <AccountLayOut> */}
                           <ResetPasswordContainer />
                           {/* </AccountLayOut> */}

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/private/setPassword"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <AccountLayOut>
                              <SetPassword />
                           </AccountLayOut>
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/private/enter-otp"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           {/* <AccountLayOut> */}
                           <EnterOtpContainer />
                           {/* </AccountLayOut> */}
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/liked"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Liked />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/group"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <Group />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>

            <Route
               path="/account/post"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Posted />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/posted"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Posts />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/following"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Following />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/account/followers"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>

                           <Follower />

                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>



            <Route
               path="/account/edit/profile"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <ProfileEdit />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            {/* other users account pages */}
            <Route
               path="/otheruseraccount/:id"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherUserAccount />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            {/* account router end */}
            <Route
               path="/mobileMessages"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <MessagesPageMobile />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/mobileMessages/draft"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <DraftNewMessages />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/mobileMessages/GroupChat/:id"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <GroupChat />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/mobileMessages/userChat/:id"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <UserChat />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/mobileMessages/user-detail/:id"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <MessagesProfileOtherUser />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/mobileMessages/mutualfriend/list/:id"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <MutualFriendsList />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/mobileMessages/achived"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <AchivedMessages />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>

            <Route
               path="/LiveStream"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <LiveStreamPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createLiveStream"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreateLiveStream />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/upload-Stream"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <UploadStream />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/settings-Stream"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <StreamSettings />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/search"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <SearchResultPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            {/* hashtag page start */}
            <Route
               path="/hashtag"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <HashTagPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="hashtag/store"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <HashTagStore />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="hashtag/recent"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <HashTagRecent />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="hashtag/otheruser"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <HashTagOtherUser />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="hashtag/popular"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <HashTagPopular />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="hashtag/trending"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <HashTagTrending />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>

            {/* hashtag page end */}

            <Route
               path="/createdgroupupdates"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedGroupUpdates />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup/chatroom"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedGroupChatRoom />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup/moderators"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedGroupModerators />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup/member"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedGroupMembers />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroupsetting"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedGroupSetting />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup/info"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedGroupInfo />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedInduvitualGroup />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup/search"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedGroupSearch />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup/post"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreatedGroupPost />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup/post/comment"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <GroupPostComments />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createdgroup/postreport"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <GroupPostReport />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            {/* OtherUser Group */}
            <Route
               path="/othergroup/list"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherUserGroupList />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/othergroup"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherUserGroupsPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/othergroup/search"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherGroupSearch />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/othergroup/post"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherGroupPost />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/othergroup/info"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherGroupInfo />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/othergroup/moderators"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherGroupModerators />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/othergroup/member"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherGroupMember />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/othergroup/chatroom"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherGroupChatroom />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/othergroup/post/comment"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherUserPostComments />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/othergroup/postreport"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OtherUserPostReport />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>

            <Route
               path="/uploadvideo"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <Upload />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/createvideo"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <Create />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>

            <Route
               path="/messages"
               element={
                  <PrivateRoute>
                     <Layout>
                        <MessagesPage>
                           <MessagesWellComePage />
                        </MessagesPage>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/messages/MessagesConversationTab"
               element={
                  <PrivateRoute>
                     <Layout>
                        <MessagesPage>
                           <MessagesConversationTab iswebVersion={true} />
                        </MessagesPage>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>
            <Route
               path="/messages/:id"
               element={
                  <PrivateRoute>
                     <Layout>
                        <MessagesPage>
                           <WebMessagesChat />
                        </MessagesPage>
                     </Layout>
                  </PrivateRoute>
               }
            ></Route>

            <Route
               path="/changeNewPassWord"
               element={
                  <PublicRoute>
                     <ChangeNewPassWord />{" "}
                  </PublicRoute>
               }
            ></Route>
            {/* Shop Page Routing Start */}
            <Route
               path="/Shop"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <ShopPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/shopdetails"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <ShopDetails />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/shopcategory"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <Category />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/wishlist"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <WishlistPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/basket"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <BasketPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/shopaddress"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <AddressPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/shoppayment"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <PaymentPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/shoporderplaced"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OrderPlacedPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/order"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OrderPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/ordercancel"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OrderCancel />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />

            <Route
               path="/orderdetails"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <OrderDetailsPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />

            {/* Group Page Routing Start */}
            <Route
               path="/createnewgroup"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <CreateGroupPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/invitemember"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <InviteMemberPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            {/* Group Page Routing End */}

            {/* Help Page  start */}

            <Route
               path="/help"
               element={
                  <PublicRoute>
                     {/* <Layout> */}
                     <WebLayout>
                        <HelpMain />
                     </WebLayout>
                     {/* </Layout> */}
                  </PublicRoute>
               }
            />
            <Route
               path="/get-questions/:id"
               element={
                  <PublicRoute>
                     {/* <Layout> */}
                     <WebLayout>
                        <Questions />
                     </WebLayout>
                     {/* </Layout> */}
                  </PublicRoute>
               }
            />
            <Route
               path="/readmore-answer"
               element={
                  <PublicRoute>
                     {/* <Layout> */}
                     <WebLayout>
                        <ReadMoreAnswers />
                     </WebLayout>
                     {/* </Layout> */}
                  </PublicRoute>
               }
            />
            <Route path="/help-web" element={<HelpMain isFullScreen={true} />} />
            <Route
               path="/help/questions/:id"
               element={<Questions isFullScreen={true} />}
            />
            <Route
               path="/help/readmore-answer"
               element={<ReadMoreAnswers isFullScreen={true} />}
            />

            {/* Help page end */}
            <Route
               path="/contact-us"
               element={
                  <Layout>
                     <WebLayout>
                        <ContactUsMain />
                     </WebLayout>
                  </Layout>
               }
            />
            <Route
               path="/contacts-us"
               element={
                  <PublicRoute>
                     <ContactUsMain isWeb={true} />
                  </PublicRoute>
               }
            />
            {/* Cookies page */}
            <Route
               path="/cookies"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <Cookies />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route path="/cookie" element={<Cookies />} />
            {/* media page */}
            <Route
               path="/media"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <Media />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route
               path="/medias"
               element={
                  <Layout>
                     <Media />
                  </Layout>
               }
            />
            {/* me page */}
            <Route
               path="/privacy-policy"
               element={
                  <Layout>
                     <WebLayout>
                        <PrivacyPolicy />
                     </WebLayout>
                  </Layout>
               }
            />
            <Route path="/privacy-policys" element={<PrivacyPolicy />} />
            {/* terms of use page page */}
            <Route
               path="/terms-of-use"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <TermsOfUse />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            <Route path="/terms-of-uses" element={<TermsOfUse />} />

            {/* Trending Videos page */}
            <Route
               path="/trending-videos"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <TrendingMain />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            {/* settings page start */}
            <Route
               path="/setting"
               element={
                  <Layout>
                     <SettingsPage />
                  </Layout>
               }
            />
            <Route
               path="/settings"
               element={
                  <Layout>
                     <WebLayout>
                        <SettingsPage />
                     </WebLayout>
                  </Layout>
               }
            />

            <Route
               path="/settings/account"
               element={
                  <Layout>
                     <WebLayout>
                        <AccountPageSettings />
                     </WebLayout>
                  </Layout>
               }
            />
            <Route
               path="/settings/security"
               element={
                  <Layout>
                     <WebLayout>
                        <SecurityPageSettings isMobile={true} />
                     </WebLayout>
                  </Layout>
               }
            />
            <Route
               path="/settings/privacy"
               element={
                  <Layout>
                     <WebLayout>
                        <PrivacyPage isMobile={true} />
                     </WebLayout>
                  </Layout>
               }
            />
            <Route
               path="/settings/preferences"
               element={
                  <Layout>
                     <WebLayout>
                        <PreferencesPage />
                     </WebLayout>
                  </Layout>
               }
            />

            <Route
               path="/settings/notifications"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <NotificationsPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />

            <Route
               path="/settings/whatsnew"
               element={
                  <PrivateRoute>
                     <Layout>
                        <WebLayout>
                           <WhatsNewPage />
                        </WebLayout>
                     </Layout>
                  </PrivateRoute>
               }
            />
            {/* settings page end */}

            <Route
               path="/resetpassword"
               element={<PublicRoute>
                  <WebLayout>
                     <LostPassword />
                  </WebLayout>
               </PublicRoute>
               }
            />
            <Route
               path="/changepassword"
               element={
                  <PublicRoute>
                     <WebLayout>
                        <ChangePassword />
                     </WebLayout>
                  </PublicRoute>
               }
            />
            <Route
               path="/enterotp"
               element={
                  <PublicRoute>
                     <WebLayout>
                        <EnterOTP />
                     </WebLayout>
                  </PublicRoute>
               }
            />
            <Route
               path="/commingsoon"
               element={
                  <CommingSoonPage />
               }
            />
            <Route
               path="/entrypage"
               element={
                  <PublicRoute>
                     <WebLayout>
                        <AfterInstallation />
                     </WebLayout>
                  </PublicRoute>
               }
            />
            <Route
               path="/Error400"
               element={
                  <PublicRoute>
                     <Error400 />
                  </PublicRoute>
               }
            />
            <Route
               path="/Error401"
               element={
                  <PublicRoute>
                     <Error401 />
                  </PublicRoute>
               }
            />
            <Route
               path="/Error403"
               element={
                  <PublicRoute>
                     <Error403 />
                  </PublicRoute>
               }
            />
            <Route
               path="/Error404"
               element={
                  <PublicRoute>
                     <Error404 />
                  </PublicRoute>
               }
            />
            <Route
               path="/Error408"
               element={
                  <PublicRoute>
                     <Error408 />
                  </PublicRoute>
               }
            />
            <Route
               path="/Error503"
               element={
                  <PublicRoute>
                     <Error503 />
                  </PublicRoute>
               }
            />
            <Route
               path="/Error500"
               element={
                  <PublicRoute>
                     <Error500 />
                  </PublicRoute>
               }
            />
            <Route
               path="/Error501"
               element={
                  <PublicRoute>
                     <Error501 />
                  </PublicRoute>
               }
            />
            <Route
               path="/Error502"
               element={
                  <PublicRoute>
                     <Error502 />
                  </PublicRoute>
               }
            />
            <Route
               path="/otpPage"
               element={
                  <PublicRoute>
                     <OtpPage />
                  </PublicRoute>
               }
            />
            <Route path="*" element={<h1>PAGE NOT FOUND</h1>} />
         </Routes>
      </Router>
   );
}

export default App;
