import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-tailwind/react";
import { IoArrowBackCircle } from "react-icons/io5";
import { TbPhotoEdit } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import { useOutsideClick } from "../../Hooks/useOutsideClick";

export default function ProfileEdit() {
   const menuRef = useRef();

   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile } = ProfileDetails;
   const [userNames, setUserNames] = useState({
      firstName: "",
      lastName: "",
   });
   const [showConfirm, setShowConfirm] = useState(false);
   const navigate = useNavigate();
   useOutsideClick(menuRef, () =>
      setShowConfirm(false)
   );
   useEffect(() => {
      setUserNames({
         firstName: User_profile.first_name,
         lastName: User_profile.last_name,
      })
   }, [])

   return (
      <div className="h-full w-full bg-black relative">
         <div className="grid grid-cols-12  justify-center items-center w-full h-14 gap-x-4  sticky top-0 px-2">
            <div className="col-span-4">
               <IoArrowBackCircle
                  className="text-[24px] text-white cursor-pointer"
                  onClick={() => setShowConfirm(true)}
               />
            </div>
            <h1 className="col-span-4 text-white text-[18px] font-bold text-center">
               Edit Profile
            </h1>
            <div className="col-span-4 flex justify-end items-center">
               <Button className="px-2 py-1 bg-teal-400">Save</Button>
            </div>
            <hr className="absolute bottom-0 h-[0.5px] bg-blue-gray-300 w-full" />
         </div>
         <div className="grid-cols-12 px-2 mt-4">
            <div className="col-span-12 flex flex-col justify-center items-center my-4">
               <div className="relative group">
                  <img
                     alt="IMG"
                     src={User_profile.avatar}
                     className="h-[6rem] w-[6rem] rounded-[50%] object-cover"
                  />
                  <div className="absolute inset-0 z-10 items-center justify-center hidden h-[6rem] w-[6rem] rounded-[50%] object-cover transition-all duration-300 bg-black/10 group-hover:flex">
                     <button className="flex items-center justify-center  h-2 w-2 text-center rounded-full cursor-pointer ring-1  ring-transparent ">
                        <TbPhotoEdit color="white" />
                     </button>
                  </div>
               </div>
               <div className="text-white text-[14px] font-bold">Change picture</div>
            </div>
            <div className="col-span-12 grid grid-cols-12">
               <p className=" col-span-4 text-[16px]  text-white font-bold">Name</p>
               <div className="col-span-8">
                  <input
                     type="text"
                     className="w-full p-2 py-1 border-2 border-gray-300 focus:outline-none rounded-sm bg-blue-gray-100  placeholder:text-gray-600 placeholder:text-[12px]"
                     placeholder="Enter your name"
                     value={userNames?.firstName + " " + userNames?.lastName}
                  />
               </div>
            </div>
            <div className="col-span-12 grid grid-cols-12 my-4">
               <p className=" col-span-4 text-[16px]  text-white font-bold">
                  UserName
               </p>
               <div className="col-span-8">
                  <input
                     type="text"
                     className="w-full p-2 py-1 border-2 border-gray-300 focus:outline-none rounded-sm bg-blue-gray-100  placeholder:text-gray-600 placeholder:text-[12px]"
                     placeholder="Enter your username"
                     value={
                        "@" + userNames.firstName + " " + userNames.lastName + "786"
                     }
                  />
               </div>
            </div>
            <div className="col-span-12 grid grid-cols-12 my-4">
               <p className=" col-span-4 text-[16px]  text-white font-bold">Bio</p>
               <div className="col-span-8">
                  <textarea
                     type="text"
                     className="w-full outline-none px-2 py-1 border-2 border-gray-300 bg-blue-gray-100  placeholder:text-gray-600 placeholder:text-[12px] focus:outline-none rounded-sm"
                     placeholder="Bio..."
                     rows={3}
                     value={User_profile.about_us}
                  />
               </div>
            </div>
            <div className="col-span-12 grid grid-cols-12 my-4">
               <p className=" col-span-4 text-[16px]  text-white font-bold">
                  Gender
               </p>
               <div className="col-span-8">
                  <select
                     id="underline_select"
                     class="block py-2.5 px-2 w-full text-sm text-gray-800 bg-blue-gray-100   placeholder:text-[12px] border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none rounded-sm bg-blue-gray-100focus:ring-0 focus:border-gray-200 peer"
                  >
                     <option>Choose a Gender</option>
                     <option selected value="Male">
                        Male
                     </option>
                     <option value="Female">Female</option>
                  </select>
               </div>
            </div>
            <div className="col-span-12 grid grid-cols-12 my-4">
               <p className=" col-span-4 text-[16px]  text-white font-bold">Links</p>
               <div className="col-span-8">
                  <input
                     type="url"
                     className="w-full p-2 py-1 border-2 border-gray-300 bg-blue-gray-100  placeholder:text-gray-600 placeholder:text-[12px] focus:outline-none rounded-sm bg-blue-gray-100px-2"
                     placeholder="https://www.example.com"
                     value={"https://www.example.com"}
                  />
               </div>
            </div>
         </div>

         {showConfirm && (
            <div className="absolute flex justify-center items-center top-0 h-full w-full">
               <div className="absolute h-screen w-full top-0 opacity-100 bg-opacity-50 bg-gray-700 z-20"></div>
               <Card className="w-[90%] px-2 py-3 absolute  z-50" ref={menuRef}>
                  <CardBody className="text-[14px] p-0 text-center">
                     If You want to save the changes click on the save button
                  </CardBody>
                  <CardFooter className="flex justify-center items-center gap-4 w-full p-0 mt-2">
                     <Button
                        className="px-2 py-2 w-14 bg-red-400 text-[10px]"
                        onClick={() => navigate(-1)}
                     >
                        cancel
                     </Button>
                     <Button
                        className="px-2 py-2 w-14 bg-teal-400 text-[10px]"
                        onClick={() => navigate(-1)}
                     >
                        Save
                     </Button>
                  </CardFooter>
               </Card>
            </div>
         )}
      </div>
   );
}
