import React, { Fragment } from "react"
import Privacy from "../../Component/settingspage/Privacy"

const PrivacyPage = () => {
   return (
      <Fragment>
         <p className="absolute top-1 text-red-700 uppercase font-bold w-full text-[10px] text-center">
            cc settings privacy / fe / v2 / july 03, 2024
         </p>
         <div className="pt-1">
            <Privacy />
         </div>
      </Fragment>
   )
}

export default PrivacyPage