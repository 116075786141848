import React from "react"
import { Button } from "@material-tailwind/react"
import { useNavigate } from "react-router-dom";
import { wishlistProducts } from "../../Datas/Datas"
import SideBar from "./SideBar"

const MyWishlist = () => {
   const navigate = useNavigate();

   return (
      <div className="px-3 pt-3">
         <SideBar />
         <p className="text-center text-[#000] font-bold text-[24px] mb-4">My Wishlist</p>
         <div className="grid grid-cols-2 lg:grid-cols-2 gap-x-[10px] mb-5">
            {wishlistProducts.map((data) => (
               <div className="mb-5" key={data.id}>
                  <img
                     src={data.imgUrl}
                     alt={data.name}
                     className="img-shadow rounded-2xl h-[9rem] w-full object-cover bg-[#fff]"
                  />
                  <div className="text-[#000] mt-2 px-1 text-start">
                     <p className="line-clamp-1 text-[13px] font-semibold">{data.name}</p>
                     <p className="line-clamp-1 text-[11px]">{data.description}</p>
                     <div className="flex justify-between items-center mt-1">
                        <p className="text-[18px] md:text-[12px] font-bold">${data.price}</p>
                        <select className="bg-[#f8000080] rounded-lg h-[20px] px-[2px] text-[10px] focus:outline-none">
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={1}>Product - 1</option>
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={2}>Product - 2</option>
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={3}>Product - 3</option>
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={4}>Product - 4</option>
                           <option className="text-[10px] bg-gray-100 hover:bg-gray-200" value={5}>Product - 5</option>
                        </select>
                     </div>
                     <div className="flex justify-between items-center mt-[5px]">
                        <Button className="bg-gray-300 text-[9px] md:text-[7px]  text-[#000] px-2 py-2 shadow-none">Remove</Button>
                        <Button className="bg-gray-300 text-[9px] md:text-[7px] text-[#000] px-2 py-2 shadow-none" onClick={() => navigate("/basket")}>Move to Basket</Button>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default MyWishlist