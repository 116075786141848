import React, { useEffect, useState } from "react";
import AccountTab from "../../Component/AccountPage/AccountTab";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import { UpdatesTab } from "../../Component/AccountPage/UpdatesTab";
import { useDispatch } from "react-redux";
import { getNotification } from "../../Redux/ApiSlice/NotificationSlice";

export default function Updates() {
   const dispatch = useDispatch();
   const [Notification, setNotification] = useState([]);
   useEffect(() => {
      dispatch(getNotification());
   }, []);

   return (
      <div className="bg-[#000] overflow-hidden h-full  relative">
         <p className="text-[#ff3b3b] text-center w-full mb-2 text-[10px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            CC ACCOUNT UPDATES PAGE-FE - V11 / JUNE 29, 2024
         </p>
         <div className="">
            <AccountProfileSection activeTab={"Updates"} />
         </div>
         <AccountTab activeTab={"Updates"} />
         <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
            <UpdatesTab />
         </div>
      </div>
   );
}
