import React, { Fragment } from "react"
import Notifications from "../../Component/settingspage/Notifications"

const NotificationsPage = () => {
   return (
      <Fragment>
         <p className="absolute top-1 text-red-700 uppercase font-bold w-full text-[10px] text-center">
            cc settings notification / fe / v2 / july 03, 2024
         </p>
         <div className="pt-1">
            <Notifications />
         </div>
      </Fragment>
   )
}

export default NotificationsPage