import React, { Fragment } from "react";
import Order from "../../Component/shoppage/Order";
import InputButtonBlack from "../../Component/shoppage/InputButtonBlack";
import DeliveredProducts from "../../Component/shoppage/DeliveredProducts";

const OrderPage = () => {
   return (
      <Fragment>
         <div className="pt-4">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               cc shop orders / FE / V4 / JUNE 25,2024
            </p>
            <Order />
            <DeliveredProducts />
            <InputButtonBlack />
         </div>
      </Fragment>
   );
};

export default OrderPage;
