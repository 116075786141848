import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const messagesChatRoomCreate = createAsyncThunk(
  "messagesChatRoomCreate",
  async (id) => {
    try {
      const response = await axiosInstance.get(`/chats/${id}/get-or-create/`);
      const result = response.data;
      return result;
    } catch (error) {
      console.error("Error:", error.response.data);
    }
  }
);
export const getMessagesChatList = createAsyncThunk(
  "getMessagesChatList",
  async (id) => {
    try {
      const response = await axiosInstance.get(`chats/chat_room_detail_list/`);
      const result = response.data;
      return result;
    } catch (error) {
      console.error("Error:", error.response.data);
    }
  }
);
export const getMessages = createAsyncThunk("getMessages", async (id) => {
  try {
    const response = await axiosInstance.get(`chats/${id}/`);
    const result = response.data;
    return result;
  } catch (error) {
    console.error("Error:", error.response.data);
  }
});

export const sendMessages = createAsyncThunk("sendMessages", async (data) => {
  let form_data = new FormData();
  form_data.append("body", data.text);
  if (data.img) {
    form_data.append("image", data.img);
  } else if (data.emoji) {
    form_data.append("emoji", data.emoji);
  }
  try {
    const response = await axiosInstance.post(
      `chats/${data.id}/send/`,
      form_data,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});

export const MessagesSlice = createSlice({
  name: "Messages",
  initialState: {
    messagesList: [],
    createChatRoom: [],
    individualMessages: [],
    loading: false,
    post_upload_loader : false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // messagesGet action promises
    builder.addCase(messagesChatRoomCreate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(messagesChatRoomCreate.fulfilled, (state, action) => {
      state.createChatRoom = action.payload;
      state.loading = false;
    });
    builder.addCase(messagesChatRoomCreate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // getMessagesChatList action promises
    builder.addCase(getMessagesChatList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMessagesChatList.fulfilled, (state, action) => {
      state.loading = false;
      state.messagesList = action.payload;
    });
    builder.addCase(getMessagesChatList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // getMessages action promises
    builder.addCase(getMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.individualMessages = action.payload;
    });
    builder.addCase(getMessages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // sendMessages action promises
    builder.addCase(sendMessages.pending, (state) => {
      state.post_upload_loader = true;
    });
    builder.addCase(sendMessages.fulfilled, (state, action) => {
      state.post_upload_loader = false;
    });
    builder.addCase(sendMessages.rejected, (state, action) => {
      state.post_upload_loader = false;
      state.error = action.payload;
    });
  },
});

export default MessagesSlice.reducer;
