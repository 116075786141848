import React from "react";
import { groupSearchComment } from "../../../Datas/Datas";

const Comments = () => {
   return (
      <div className="overflow-y-scroll">
         {groupSearchComment.map((data) => (
            <div className="border-b border-gray-600 py-1">
               <div className="grid grid-cols-12 items-center px-2">
                  <p className="col-span-12 text-white text-[14px] md:text-[12px] font-bold">{data.dayAgo} ago</p>
                  <div className="col-span-2">
                     <img
                        alt="img"
                        className="h-11 w-11 md:h-9 md:w-9 rounded-full object-cover"
                        src={data.profileUrl}
                     />
                  </div>
                  <div className="col-span-10 leading-4 -ml-2 md:-ml-1">
                     <p className="text-white text-[16px] md:text-[14px] overflow-hidden"><span className="font-bold">{data.userName}:</span> "{data.messages}"</p>
                  </div>
               </div>
            </div>
         ))}
      </div>
   )
}

export default Comments