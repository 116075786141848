import React from "react";
import ErrorImg from "../../Assets/ErrorPage/408.svg";

export default function Error408() {
  return (
    <main className="h-screen flex justify-center items-center">
      <div className="absolute top-0">
      <p className="text-[red] font-extrabold text-[16px] w-full text-center ">
            CC -408 ERROR V1 / MAR 15, 2024</p>
        <p className="text-[#000] font-bold  text-[11px] w-full text-center">
          Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
        </p>
      </div>

      <div className="flex justify-center items-center flex-col">
        <img src={ErrorImg} />
        <div className="w-4/5">
          <h3 className="text-[16px] font-bold mt-4 text-center">
            Sorry about this........your request has exceeded the maximum time
            allowed “what happened ?”{" "}
            <span className="text-[#00A3FF]">Learn more</span>
          </h3>
        </div>
        <button
          className="mt-8 bg-[#000] w-36 h-15  text-[#fff] p-1 font-bold text-base"
          style={{ boxShadow: "rgb(0, 255, 71) 6px 6px 0px 2px" }}
        >
          Back To Home
        </button>
      </div>
    </main>
  );
}
