import React from "react";
import { useNavigate } from "react-router-dom";

export default function HelpOptions({ helpPageData, isFullScreen }) {
   const navigate = useNavigate();

   return (
      <div
         className={`flex flex-wrap justify-center items-center ${isFullScreen ? "gap-14 mt-8" : "gap-4"
            } `}
      >
         {helpPageData.map((data, index) => (
            <div
               key={index}
               className={`p-4 ${isFullScreen
                  ? "w-28 h-28 md:w-[14rem] md:h-[14rem]"
                  : " w-36 h-36 md:w-28 md:h-[5rem]"
                  } rounded-lg flex flex-col justify-center items-center  hover:shadow-[1px_10px_40px_rgba(8,_112,_184,_0.7)] cursor-pointer transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-300`}
               onClick={() =>
                  navigate(
                     isFullScreen
                        ? `/help/questions/${data.id}`
                        : `/get-questions/${data.id}`
                  )
               }
            >
               <img
                  src={data.img}
                  className={`${isFullScreen ? "h-6 w-6 md:h-20 md:w-20" : "h-8 w-8 md:h-6 md:w-6"
                     }`}
               />
               <p
                  className={` ${isFullScreen ? "text-[14px] md:text-[20px]" : "text-[12px]"
                     } text-black text-center mt-2`}
               >
                  {data.title}
               </p>
            </div>
         ))}
      </div>
   );
}
