import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardFooter, Typography, Button, Checkbox } from "@material-tailwind/react";

export default function ContactUsCard({ buttonName, image, description, subcription, handleClick, isWeb, navigatePath, }) {
   const navigate = useNavigate();
   const handleNavigate = () => {
      navigate(navigatePath);
   };

   return (
      <Card
         className={`rounded-lg ${isWeb
            ? "md:h-52 flex flex-col justify-center items-center"
            : "w-full"} hover:border-[2px] hover:border-[#7e22ce] shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] hover:shadow-[5px_5px_0px_0px_rgba(109,40,217)] p-2`}
      >
         <CardBody className="p-2 flex flex-col justify-center items-center">
            <Typography color="blue-gray" className="mb-1 text-[12px] font-bold">
               <img src={image} className="h-12 w-12" />
            </Typography>
            <Typography
               color="blue-gray"
               className={`mb-1 ${isWeb ? "text-[12px]" : "text-[12px]"} text-center`}
            >
               {description}
            </Typography>
         </CardBody>
         <CardFooter className="flex justify-center items-center gap-4 md:p-1">
            {!subcription ? (
               <Button
                  className="bg-[#7e22ce] rounded-full text-[10px] py-2 px-3 col-span-2"
                  onClick={navigatePath ? handleNavigate : handleClick}
               >
                  {buttonName}
               </Button>
            ) : (
               <div className="flex flex-col">
                  <Typography>
                     <Checkbox
                        color="blue"
                        label={
                           <Typography color="blue-gray" className="text-[10px]">
                              It's <span className="text-[#7e22ce]">100% free</span> and
                              we will never send more than one email per month
                           </Typography>
                        }
                     />
                  </Typography>
                  <div className="col-span-8 grid grid-cols-6 relative  justify-end items-center h-full rounded-full border-gray-400 bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] ">
                     <input
                        placeholder="Enter your email"
                        className="h-full rounded-full outline-none p-2 col-span-4 px-3 text-[12px] placeholder:text-[8px]"
                     />
                     <Button className="bg-[#7e22ce] rounded-full text-[10px] px-2 py-1 col-span-2 mr-1">
                        Subscribe
                     </Button>
                  </div>
               </div>
            )}
         </CardFooter>
      </Card>
   );
}
