import React from "react";
import { Button } from "@material-tailwind/react";
import { PiBasketFill } from "react-icons/pi";
import {useNavigate} from "react-router-dom"

const InputButtonBlack = () => {
   const navigate = useNavigate()
   return (
      <div className="px-4 grid grid-cols-10 mb-20">
         <div className="col-span-8">
            <div className="flex justify-between mb-3">
               <Button className="lowercase bg-gray-300 text-[#000] text-[15px] md:text-[12px] md:px-4 px-5 py-1 rounded-2xl" onClick={()=>navigate("/order")}>orders</Button>
               <Button className="lowercase bg-gray-300 text-[#000] text-[15px] md:text-[12px] md:px-4 px-5 py-1 rounded-2xl">filter</Button>
               <Button className="lowercase bg-gray-300 text-[#000] text-[15px] md:text-[12px] md:px-4 px-5 py-1 rounded-2xl">sell</Button>
            </div>
            <input 
               placeholder="search product"
               className="bg-gray-300 rounded-3xl text-[#000] w-full py-1 px-4 focus:outline-none"
            />
         </div>
         <div className="col-span-2 flex justify-end items-end">
            <PiBasketFill className="text-[#000] text-3xl" />
         </div>
      </div>
   )
}

export default InputButtonBlack