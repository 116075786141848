import React from "react";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";

export function PostedTab({ User_posts }) {
   const selectVideo = useSelectVideo();
   const handleSelectVideo = async (id) => {
      selectVideo(id, User_posts);
   };

   return (
      <>
         {User_posts.length ? (
            <div className="grid grid-cols-2 gap-2 overflow-scroll no-scrollbar">
               {User_posts?.map((item) => (
                  <VideoCard
                     id={item.id}
                     body={item.body}
                     belowDecs={true}
                     threeDots={false}
                     video={item.video_url}
                     views={item.views}
                     // videosList={hashTagList}
                     likes_count={item.likes_count}
                     selectVideo={handleSelectVideo}
                     // handleDeletePost={handleDeletePost}
                     avatar_url={item.created_by.avatar_url}
                  />
               ))}
            </div>
         ) : (
            <div className="h-full">
               <p className="h-[20%] text-[12px] font-bold text-[#fff]">Today</p>
               <div className="h-[80%] flex justify-center items-center">
                  <p className="text-[10px] text-[#c0b8b8] font-semibold">
                     No Posted Yet
                  </p>
               </div>
            </div>
         )}
      </>
   );
}
