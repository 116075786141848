import React from "react";
import Header from "./Components/Webversion/Header/Header";
export default function Layout({ children }) {
  return (
    <>
      <main className="h-screen overflow-scroll no-scrollbar">
        {/* <Header /> */}
        {children}
      </main>
    </>
  );
}
