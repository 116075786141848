import React from "react";

export default function SmallMenu({ menuItems }) {
   return (
      <div class="absolute top-5 right-0 z-10 mt-2 w-[6.5rem] rounded-md bg-[#f3f3f3] shadow-2xl">
         <div class="py-1" role="none">
            {menuItems.map((data) => (
               <a
                  href="#"
                  tabindex="-1"
                  role="menuitem"
                  id="menu-item-0"
                  class="text-gray-700 block px-4 py-2 text-sm hover:bg-[#404040] hover:text-white"
               >
                  {data.name}
               </a>
            ))}
         </div>
      </div>
   );
}
