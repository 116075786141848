import React from "react";
import { CiSettings } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoNotificationsCircle } from "react-icons/io5";
import { FaLock, FaQuestionCircle } from "react-icons/fa";
import { MdAccountBalance, MdAdminPanelSettings } from "react-icons/md";
import { Typography, List, ListItem, ListItemPrefix, Drawer, Card } from "@material-tailwind/react";

const SideBar = ({ isDrawerOpen, closeDrawer }) => {
   const navigate = useNavigate();

   return (
      <>
         <Drawer
            open={isDrawerOpen}
            onClose={closeDrawer}
            className="absolute  overflow-hidden  w-[65%] h-screen"
            style={{ zIndex: 1000, height: "100vh", maxHeight: "100vh" }}
         >
            <Card
               color="transparent"
               shadow={false}
               className="w-full p-4 h-screen relative "
            >
               <p className="text-[8px] text-red-700 absolute top-0 w-full text-center font-bold">
                  CC SETTINGS SIDE BAR PAGE, FE, V2, JULY 02, 2024
               </p>
               <div className="mb-2 flex justify-center items-center p-4">
                  <Typography variant="h5" color="blue-gray">
                     Settings
                  </Typography>
               </div>
               <List className="text-[16px]">
                  <ListItem onClick={() => navigate("/settings/account")}>
                     <ListItemPrefix>
                        <MdAccountBalance className="h-6 w-6" />
                     </ListItemPrefix>
                     Accounts
                  </ListItem>
                  <ListItem onClick={() => navigate("/settings/security")}>
                     <ListItemPrefix>
                        <FaLock className="h-6 w-6" />
                     </ListItemPrefix>
                     Security
                  </ListItem>
                  <ListItem onClick={() => navigate("/settings/privacy")}>
                     <ListItemPrefix>
                        <MdAdminPanelSettings className="h-6 w-6" />
                     </ListItemPrefix>
                     Privacy
                  </ListItem>
                  <ListItem onClick={() => navigate("/settings/preferences")}>
                     <ListItemPrefix>
                        <CiSettings className="h-6 w-6" />
                     </ListItemPrefix>
                     Preferences
                  </ListItem>
                  <ListItem onClick={() => navigate("/settings/notifications")}>
                     <ListItemPrefix>
                        <IoNotificationsCircle className="h-6 w-6" />
                     </ListItemPrefix>
                     Notifications
                  </ListItem>
                  <ListItem onClick={() => navigate("/settings/whatsnew")}>
                     <ListItemPrefix>
                        <FaQuestionCircle className="h-6 w-6" />
                     </ListItemPrefix>
                     What’s New
                  </ListItem>
               </List>
            </Card>
         </Drawer>
      </>
   );
};

export default SideBar;
