import React, { useEffect, useRef, useState } from "react";
import { FaEye, FaSearch } from "react-icons/fa";
import { TbDotsVertical } from "react-icons/tb";
import { BsEmojiSmileFill } from "react-icons/bs";
import LazyLoad from 'react-lazyload';
import emptyPosts from "../../../src/Assets/YourAccountIcons/emptyPosts.png";

export function OtherUserPosts({ User_posts }) {
   const menuRef = useRef(null);
   const [postId, setPostId] = useState(null);

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setPostId(null); // Close menu if clicked outside
      }
   };

   const handleDeletePost = async (id) => {
      //   await dispatch(AccountProfilePostDelete(id));
      //   await dispatch(AccountProfilePosts(User_profile?.id));
      //   dispatch(AccountProfile());
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const captureVideoPoster = (videoElement) => {
      videoElement.currentTime = 3;
      videoElement.addEventListener('seeked', function () {
         const canvas = document.createElement('canvas');
         canvas.width = videoElement.videoWidth;
         canvas.height = videoElement.videoHeight;
         canvas.getContext('2d').drawImage(videoElement, 0, 0, canvas.width, canvas.height);
         const posterUrl = canvas.toDataURL();
         videoElement.setAttribute('poster', posterUrl);
      }, { once: true });
   };

   return (
      <>
         <div className="mx-2 relative">
            <input
               type="text"
               placeholder="Find Your Posts"
               className="w-full placeholder:text-sm font-medium outline-none pl-8 pr-2 py-[5px] rounded-md"
            />
            <FaSearch className="absolute top-1/2 left-2 transform -translate-y-1/2 w-[18px] h-[18px] text-black cursor-pointer" />
         </div>
         {User_posts?.length > 0 ? (
            <div className="grid grid-cols-3 gap-1 overflow-y-scroll no-scrollbar px-2 py-2">
               {User_posts?.map((item, index) => (
                  <div className="col-span-1" key={index}>
                     <div className="h-[18.5vh] w-[100%] relative">
                        <div className="w-full h-full relative">
                           <LazyLoad once className="h-[18.5vh]">
                              <video
                                 muted
                                 crossOrigin="anonymous"
                                 src={`${item.video_url}`}
                                 className="h-full w-full object-cover"
                                 onLoadedData={(e) => captureVideoPoster(e.target)}
                              />
                           </LazyLoad>
                           <TbDotsVertical
                              color="white"
                              onClick={() => setPostId(item.id)}
                              className="absolute right-0 top-1 font-bold cursor-pointer z-50"
                           />
                           {postId === item.id && (
                              <div
                                 ref={menuRef}
                                 className="absolute text-[#000] font-bold bg-[#D9D9D9] rounded-[3px] right-2 top-5 z-50"
                              >
                                 <p
                                    className="flex items-center text-[10px] px-[5px] py-[1px] cursor-pointer"
                                    onClick={() => handleDeletePost(item.id)}
                                 >
                                    Report
                                 </p>
                              </div>
                           )}
                        </div>
                        <div className="absolute bottom-0 w-full flex justify-between items-center">
                           <div className="bg-[#070707c7] flex items-center justify-center gap-[4px] px-[4px] w-11 h-4 overflow-hidden">
                              <FaEye className="h-[10px] w-[10px] text-[#ffffff]" />
                              <p className="text-[9px] text-[#ffffff]">3.5k</p>
                           </div>
                           <div className="bg-[#070707c7] flex items-center justify-center gap-[4px] px-[4px] w-11 h-4 overflow-hidden">
                              <BsEmojiSmileFill className="h-[8px] w-[8px] text-[#fafa37]" />
                              <p className="text-[9px] text-[#ffffff]">{item?.likes_count}</p>
                           </div>
                        </div>
                     </div>
                     <div className="flex items-center bg-[#141414] p-[2px]">
                        <img
                           alt={item.body}
                           src={item.created_by.avatar_url}
                           className="object-cover rounded-full h-[10px] w-[10px]"
                        />
                        <p className="font-medium text-[8px] text-[#fff] pl-1 line-clamp-1">
                           {item.body}
                        </p>
                     </div>
                  </div>
               ))}
            </div>
         ) : (
            <div className="flex justify-center items-center flex-col h-[50%]">
               <img src={emptyPosts} alt="" className="w-32 h-32" />
               <p className="text-[10px] text-[#c0b8b8] font-semibold">
                  No Posts yet
               </p>
            </div>
         )}
      </>
   );
}
