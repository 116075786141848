import React from "react";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";

export default function AccountTabModal({ data, setActiveTab, activeTab }) {
   return (
      <div className=" h-full w-full mb-2">
         <Tabs
            value={activeTab}
            className="absolute h-[-webkit-fill-available] w-full mt-2 z-50"
         >
            <TabsHeader className="mx-2">
               {data.map(({ label, value, icon }) => (
                  <Tab key={value} value={value}>
                     <div
                        className="flex items-center gap-2 text-[10px] md:text-[11px] font-bold"
                        onClick={() => setActiveTab(value)}
                     >
                        {React.createElement(icon, { className: "w-3 h-3" })}
                        {label}
                     </div>
                  </Tab>
               ))}
            </TabsHeader>
            <TabsBody className="h-[-webkit-fill-available]">
               <p className="text-[8px] text-red-700 text-center font-bold uppercase"> CC ACCOUNT {activeTab} PAGE, FE, V12, JUNE 27 - 2024</p>
               {data.map(({ value, desc }) => (
                  <TabPanel uppercase
                     key={value}
                     value={value}
                     className={`h-[-webkit-fill-available] h-max-[-webkit-fill-available] w-full p-0  mt-2`}
                  >
                     {desc}
                  </TabPanel>
               ))}
            </TabsBody>
         </Tabs>
      </div>
   );
}
