import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { TiPlus } from "react-icons/ti";
import { FaMinus } from "react-icons/fa";
import { TbReload } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { AccountProfileImageEdit } from "../../../Redux/ApiSlice/AccountProfileSlice";

export default function AccountProfileImageCrop({
  selectedImg,
  handleOpen,
  selected,
  handleSubmitProfileImage,
  isCreateGroup
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropedImg, setCropedImg] = useState(null);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCropedImg(croppedAreaPixels);
  };
  const [image, setImage] = useState(null);

  const increaseLimit = () => {
    if (zoom < 10) {
      // Set your maximum limit here
      setZoom(zoom + 1);
    }
  };

  const decreaseLimit = () => {
    if (zoom > 1) {
      // Set your minimum limit here
      setZoom(zoom - 1);
    }
  };
  /* image cropining function working progress */

  // const handleSubmitProfileImage = async () => {
  //   try {
  //     const croppedImage = await getCroppedImg(
  //       selectedImg,
  //       cropedImg
  //       // rotation
  //     );
  //     setImage(croppedImage);

  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  // async function convertBlobToFile(blobUrl) {
  //   // Step 1: Fetch blob data
  //   const response = await fetch(blobUrl);
  //   const blobData = await response.blob();

  //   // Step 2: Convert blob to file
  //   const file = new File([blobData], "filename"); // You can specify the filename here
  //   // Step 3: Send file to backend
  //   const formData = new FormData();
  //   formData.append("file", file); // Assuming 'file' is the key for the file in the backend

  //   // Assuming you're using fetch to send data to the backend
  // }

  return (
    <>
      <div className=" h-56 flex flex-col justify-center items-center">
        {image}
        <div className="h-full w-2/5 relative justify-center flex">
          <Cropper
            image={selectedImg}
            crop={crop}
            cropShape={""}
            zoom={zoom}
            aspect={3 / 4}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            objectFit="cover"
            onZoomChange={setZoom}
          />
        </div>
        <div className="flex justify-center items-center w-full my-4  ">
          <button
            className="text-[#fff] z-50 w-[4%] mr-3 "
            onClick={() => decreaseLimit()}
          >
            <FaMinus color="red" />
          </button>
          <input
            color="green"
            type="range"
            value={zoom}
            min={1}
            max={10}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="appearance-none  h-[0.2rem] bg-gray-200  outline-none focus:ring-2  w-[40%]"
          />

          <button
            className="text-[#fff] z-50 ml-4 w-[4%]"
            onClick={() => increaseLimit()}
          >
            <TiPlus color="green" />
          </button>
        </div>
      </div>
      <div className="w-full flex justify-center my-5">
        <TbReload
          className="w-[25px] h-[25px] text-[#fff] cursor-pointer focus:transform-1sec"
          onClick={() => setZoom(1)}
        />
      </div>
      {!isCreateGroup && (
        <div className="w-full flex justify-center h-10 gap-10 ">
          <Button
            className="bg-[hsl(0,0%,60%)] w-[25%] flex justify-center items-center  rounded-3xl text-[#000] font-bold text-[18px]  cursor-pointer "
            onClick={() => handleSubmitProfileImage(selected)}
          >
            Save
          </Button>

          <Button
            className="bg-[hsl(0,0%,60%)] w-[25%] flex justify-center items-center text-[#000] text-[18px] font-bold  rounded-3xl cursor-pointer"
            onClick={handleOpen}
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}
