import React, { Fragment } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { shopDetailsImage } from "../../../Datas/Datas";
import { IoCaretBackCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
   }
};

const DetailsImageSlider = () => {
   const navigate = useNavigate();
   return (
      <Fragment>
         <div className="px-4 pt-3">
            <IoCaretBackCircle
               className="text-black text-[24px] cursor-pointer"
               onClick={() => navigate(-1)}
            />
         </div>

         <div className="mx-4 mt-2 img-shadow relative pb-6 rounded-3xl border border-gray-300">
            <Carousel
               swipeable={true}
               draggable={true}
               ssr={true}
               infinite={true}
               autoPlay={true}
               autoPlaySpeed={5000}
               responsive={responsive}
               showDots
               renderDotsOutside={true}
               removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
               className="rounded-3xl"
            >
               {shopDetailsImage.map((data) => (
                  <img
                     key={data.id}
                     src={data.imgurl}
                     alt="img"
                     className="object-cover h-4/4 w-full"
                  />
               ))}
            </Carousel>
         </div>
      </Fragment>
   )
}

export default DetailsImageSlider