import React, { Fragment, useEffect, useState } from "react";
import SideBar from "../../Component/settingspage/SideBar";
import LandingPage from "../../Component/settingspage/LandingPage";
import { useLocation } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { IconButton } from "@material-tailwind/react";

const SettingsPage = () => {
   const location = useLocation();
   const { pathname } = location;
   const [isMobile, setMobile] = useState(false);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   useEffect(() => {
      if (pathname === "/settings") {
         setMobile(true);
      }
   }, []);

   return (
      <Fragment>
         <section className={`h-full w-full ${isDrawerOpen ? "overflow-hidden" : ""}`}>
            <p className="z-30 absolute top-1 text-red-700 uppercase font-bold w-full text-center text-[10px]">
               cc settings home / fe / v3 / July 2, 2024
            </p>

            <div className={`pt-1 ${isMobile ? "bg-black" : "bg-white md:bg-white"}`}>
               {isMobile && (
                  <div className="pt-2 pl-1 px-0 bg-black sticky top-0">
                     <IconButton variant="text" size="lg" onClick={openDrawer}>
                        {isDrawerOpen ? (
                           <XMarkIcon className="h-8 w-8 stroke-2 text-white" />
                        ) : (
                           <Bars3Icon className="h-8 w-8 stroke-2 text-white" />
                        )}
                     </IconButton>
                  </div>
               )}
               <SideBar
                  isDrawerOpen={isDrawerOpen}
                  closeDrawer={closeDrawer}
                  openDrawer={openDrawer}
               />
               <LandingPage isMobile={isMobile} />
            </div>
         </section>
      </Fragment>
   );
};

export default SettingsPage;
