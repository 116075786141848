import React from "react";
import { FaDownload } from "react-icons/fa";
import { ImSad2 } from "react-icons/im";
import { BsFillFileBarGraphFill } from "react-icons/bs";
import { BiRepost } from "react-icons/bi";
import { IoSpeedometerSharp } from "react-icons/io5";
import { MdDownloading } from "react-icons/md";

const RightSide = ({ downloadVideo }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-[23px]">
      <div>
        <MdDownloading
          className="text-[35px] md:text-[25px] cursor-pointer" 
          onClick={downloadVideo}
        />
      </div>
      <div>
        <IoSpeedometerSharp className="text-[35px] md:text-[25px]" />
      </div>
      <div>
        <ImSad2 className="text-[35px] md:text-[25px] text-yellow-800" />
      </div>
      <div>
        <BiRepost className="text-[40px]" />
      </div>
      <div>
        <BsFillFileBarGraphFill className="text-[35px] md:text-[25px]" />
      </div>
    </div>
  );
};

export default RightSide;
