import React from 'react'

export default function ProgresBar({ width }) {

    return (
        <div
            className="bg-gray-900 rounded h-6 mt-5 relative"
            role="progressbar"
            aria-valuenow={width}
            aria-valuemin={0}
            aria-valuemax={100}
        >
            <div
                className="bg-green-600 rounded h-6 text-center text-white text-sm transition"
                style={{ width: `${width}%`, transition: 'width 2s' }}
                // x-text={`${width}%`}
            >
            </div>
            <p className='absolute text-center w-full justify-center text-red-900 text-[12px] font-bold'>{`${width}%`}</p>
        </div>
    )
}
