import React from "react";
import { useNavigate } from "react-router-dom";

export default function CommingSoonPage({ ismobilepanel }) {
  const navigate = useNavigate();
  return (
    <>
      <div class="area">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>

      {/* {ismobilepanel ? (
        <div className="w-full h-full flex flex-col justify-center items-center bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
          <p className="uppercase font-extrabold text-[1.5rem] text-[#fff]">
            Coming soon
          </p>
          <p className="uppercase font-bold text-[0.5rem] text-[#fff] tracking-[0.4rem]">
            under construction
          </p>
        </div>
      ) : ( */}
        <div className="w-full h-[100vh] flex flex-col justify-center items-center bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90%">
          <p className="uppercase font-extrabold text-[3rem] md:text-[5rem] text-[#fff]">
            Coming soon
          </p>
          <p className="uppercase font-bold text-[1rem]  md:text-[2rem]  text-[#fff] tracking-[0.4rem]">
            under construction
          </p>
          {/* {!ismobilepanel && ( */}
            <div>
              {/* <button
                className="bg-gradient-to-r  via-sky-500 via-30%  text-[#fff] p-2"
               
              >
                Back
              </button> */}
              <button
                class="bg-[#000] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                onClick={() => navigate(-1)}
              >
                GO BACK
              </button>
            </div>
          {/* )} */}
        </div>
      {/* )} */}
    </>
  );
}
