import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-tailwind/react";
import { IoSearchSharp } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";

export default function ViewsList({ handleShowViews }) {
   const followerlist = useSelector((state) => state.AccountProfile);
   const { User_followersList } = followerlist;
   const users = [
      {
         id: 9,
         name: "Isabella Rodriguez",
         joined: true,
         following: false,

         userImg:
            "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 10,
         name: "James Wilson",
         following: true,
         userImg:
            "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 1,
         name: "Emily Johnson",
         following: false,
         userImg:
            "https://images.unsplash.com/photo-1534008757030-27299c4371b6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 2,
         name: "Jacob Smith",
         joined: true,
         following: true,
         userImg:
            "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 3,
         name: "Olivia Brown",
         following: true,
         userImg:
            "https://images.unsplash.com/photo-1503104834685-7205e8607eb9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 4,
         name: "Ethan Martinez",
         following: true,
         userImg:
            "https://images.unsplash.com/photo-1566492031773-4f4e44671857?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 7,
         name: "Sophia Garcia",
         following: true,
         userImg:
            "https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 5,
         name: "Ava Anderson",
         following: true,
         userImg:
            "https://images.unsplash.com/photo-1560238970-cc0ae073d3a5?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
         id: 6,
         name: "Michael Thompson",
         following: true,
         userImg:
            "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },

      {
         id: 8,
         name: "William Davis",
         following: true,
         userImg:
            "https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
   ];

   return (
      <>
         <div className="bg-black h-full pb-5 overflow-y-scroll">
            <div className="sticky top-0 bg-black z-10">
               <div className="grid grid-cols-12 px-3 pt-1 items-center">
                  <div className="col-span-12 pb-[2px]">
                     <p className="text-[#ff3b3b] text-center font-bold text-[8px] uppercase">
                        CC VIEWS Page, FE, V2, june 24, 2024
                     </p>
                  </div>
                  <div className="col-span-2">
                     <IoIosCloseCircle
                        color="red"
                        className="cursor-pointer transform transition-transform hover:scale-125 text-[1.5rem]"
                        onClick={handleShowViews}
                     />
                  </div>
                  <div className="col-span-8 text-center">
                     <p className="text-white text-[14px] font-bold">Viewers</p>
                  </div>
               </div>

               <hr className="w-full my-[6px] h-[0.5px] bg-gray-800 border-0" />
            </div>

            <div className="mx-3 mb-2 relative">
               <input
                  type="text"
                  placeholder="Search"
                  className="w-full focus:outline-none border border-gray-700 py-1 pl-8 pr-2 rounded-full"
               />
               <IoSearchSharp className="absolute top-[7px] left-[7px] text-[20px] cursor-pointer" />
            </div>

            {users.map((data) => (
               <>
                  <div
                     className="grid grid-cols-12 items-center pt-2 pb-2 px-4 hover:bg-[#1b1b1b] cursor-pointer"
                     key={data.id}
                  >
                     <div className="col-span-2">
                        <img
                           alt={data.name}
                           src={data.userImg}
                           className="h-8 w-8 rounded-full object-cover"
                        />
                     </div>
                     <div className="col-span-5 ml-[-2px]">
                        <p className="text-[12px] text-white leading-[14px]">
                           {data.name}
                        </p>
                     </div>
                     <div className="col-span-5 flex justify-end gap-2 items-center">
                        <button className="bg-white text-[black] text-[11px] px-[8px] py-[5px] rounded font-bold w-[70px] hover:bg-[#dedede]">
                           {data.following ? "Unfollow" : "Follow"}
                        </button>
                     </div>
                  </div>
                  <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-900 border-0" />
               </>
            ))}
         </div>
      </>
   );
}
