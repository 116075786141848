import React from "react";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const OrderCancel = () => {
    const navigate = useNavigate()
    return (
        <div className="px-2">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
                cc shop order cancel / fe / v1 / apr 18, 2024
            </p>
            <div className="flex flex-col justify-center items-center mt-32">
                <MdCancel className="text-[6rem] text-red-700" />
                <p className="text-center leading-5 text-[14px] my-5">Your order has been canceled. A refund will be provided to the original payment method used.</p>
                <button 
                    onClick={() => navigate("/shop")}
                    className="bg-black text-white hover:bg-[#000000]/95 px-4 py-1 rounded mt-5"
                >
                    Continue Shopping
                </button>
            </div>
        </div>
    )
}

export default OrderCancel