import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";
import LikedEmptyData from "../../Assets/YourAccountIcons/LikeEmptyData.png";

export function LikedTab({ User_likedVideos }) {
   const selectVideo = useSelectVideo();
   const { Liked_Posts } = User_likedVideos;
   const [search, setSearch] = useState("");

   const getAllPost = useSelector((state) => state.HomePageVideo);
   const { AllPosts } = getAllPost;
   const matchingPosts = AllPosts.filter((post) =>
      Liked_Posts?.some((item) => item?.id === post.id)
   );

   const [LikedVideos, setLikedVideos] = useState(null);

   const handleChangeSearch = (event) => {
      const inputValue = event.target.value;
      setSearch(inputValue);

      // Filter data based on search query
      const filtered = matchingPosts.filter((item) =>
         item.body.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (filtered.length === 0) {
         setLikedVideos(matchingPosts);
      } else {
         setLikedVideos(filtered);
      }
   };

   const handleSelectVideo = async (id) => {
      selectVideo(id, LikedVideos);
   };

   useEffect(() => {
      setLikedVideos(matchingPosts);
   }, []);

   return (
      <>
         {LikedVideos?.length !== 0 ? (
            <>
               <div className="flex h-[2rem] justify-center items-center rounded-md overflow-hidden">
                  <div className="bg-[aliceblue] flex h-[2rem] w-full justify-center items-center rounded-md overflow-hidden">
                     <div className="w-[10%] flex justify-center">
                        <FaSearch className="cursor-pointer" />
                     </div>
                     <div className="w-full h-[80%]">
                        <input
                           type="text"
                           value={search}
                           onChange={handleChangeSearch}
                           placeholder={`Find Your Liked videos `}
                           className="w-full placeholder:text-sm font-medium outline-none pr-2 text-[#000]"
                        />
                     </div>
                  </div>
               </div>
               <div className="flex pt-1 flex-wrap gap-3 overflow-scroll no-scrollbar">
                  <div className="grid grid-cols-2 w-full pt-1 gap-2 overflow-scroll no-scrollbar">
                     {LikedVideos?.map((item) => (
                        <VideoCard
                           id={item.id}
                           belowDecs={true}
                           body={item.body}
                           threeDots={false}
                           video={item.video_url}
                           views={item.views}
                           likes_count={item.likes_count}
                           selectVideo={handleSelectVideo}
                           avatar_url={item.created_by.avatar_url}
                        />
                     ))}
                  </div>
               </div>
            </>
         ) : (
            <div className="flex justify-center items-center flex-col h-[80%]">
               <img src={LikedEmptyData} alt="" className="h-32 w-32" />
               <p className="text-[10px] text-[#c0b8b8] font-semibold">
                  No liked video yet
               </p>
            </div>
         )}
      </>
   );
}
