import React, { useState } from "react";
import Settingicon from "../../Assets/MessagesIcons/Settings icon.png";
import frame from "../../Assets/MessagesIcons/Frame 130.png";
import frame1 from "../../Assets/MessagesIcons/Frame 131.png";
import frame2 from "../../Assets/MessagesIcons/Frame 132.png";
import frame3 from "../../Assets/MessagesIcons/Frame 133.png";
import frame4 from "../../Assets/MessagesIcons/Frame 134.png";
import frame5 from "../../Assets/MessagesIcons/Frame 135.png";
import smileicon from "../../Assets/MessagesIcons/Happy.png";
import Attach from "../../Assets/MessagesIcons/Attach.png";
import CurvedArrow from "../../Assets/MessagesIcons/Curved Arrow.png";
import Microphone from "../../Assets/MessagesIcons/Microphone.png";
import verticalMenu from "../../Assets/MessagesIcons/icons8-menu-vertical-50.png";
import CreatedGroupProfileSection from "./CreatedGroupProfileSection";
import CreatedGroupTab from "./CreatedGroupTab";
import { CgAttachment } from "react-icons/cg";

import { CgSmileMouthOpen } from "react-icons/cg";

export default function CreatedGroupSetting() {
   const chartdata = [
      {
         sam: "When forty winters shall besiege thy brow,",
         samicon: "frame1",
         saran: "",
         saraniocn: "frame2",
      },
      {
         sam: "",
         saran:
            "And dig deep trenches in thy beauty's field, Thy youth's proud livery, so gazed on now, Will be a tattered weed of small worth held.",
         saraniocn: "frame2",
         samicon: "frame1",
      },
      {
         sam: "",
         saran:
            "Then being asked where all thy beauty lies, Where all the treasure of thy lusty days, To say within thine own deep-sunken eyes. Were an all-eating shame and thriftless praise.",
         saraniocn: "frame2",
         samicon: "frame1",
      },
      {
         sam: "How much more praise deserved thy beauty's useIf thou couldst answer",
         saran: "",
         saraniocn: "frame2",
         samicon: "frame1",
      },
      {
         sam: "",
         saran: "'This fair child of mine ",
         saraniocn: "frame2",
         samicon: "frame1",
      },
   ];
   return (
      <section className="overflow-hidden h-full relative">
         <CreatedGroupProfileSection />
         <CreatedGroupTab tabName={"Settings"} />
         <span className="absolute top-0 flex justify-center text-[#ff3b3b] font-bold text-[10px] w-full">
            CC CREATED GROUP SETTINGS - FE - V1 - JUNE 25, 2024
         </span>
         <div className="bg-black p-4 pt-1 overflow-scroll  absolute w-full h-[-webkit-fill-available] no-scrollbar">
            <div>
               <h2 className="text-[#ffff] font-semibold ">Allow Members</h2>
               {[
                  "Join group directly",
                  "Message as admin",
                  "Create or post as admin",
                  "Share the current group",
                  "Show group members",
               ].map((item, index) => (
                  <div className="flex justify-between px-4 w-full pt-3">
                     <p className="text-[#999595] text-[11px] ">{item}</p>
                     <label class="inline-flex items-center  cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" />
                        <div class="relative w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#fff] after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                     </label>
                  </div>
               ))}
            </div>
            <div className="pt-3">
               <h2 className="text-[#ffff] font-semibold ">Group Updates</h2>
               {[
                  "Member added invitation",
                  "Updates from admin",
                  "Group updates",
                  "Group updates",
               ].map((item, index) => (
                  <div key={index}>
                     <div className="flex justify-between px-4 w-full pt-3">
                        <p className="text-[#999595] text-[11px] ">{item}</p>
                        <label class="inline-flex items-center  cursor-pointer">
                           <input type="checkbox" value="" class="sr-only peer" />
                           <div class="relative w-7 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-[#fff] after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                     </div>
                     <div className="grid grid-cols-6  px-6 pt-2 ">
                        <div className="col-span-2 flex gap-1">
                           <div class=" flex items-center ">
                              <input
                                 id="green-radio"
                                 type="radio"
                                 value=""
                                 name="colored-radio"
                                 class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300  dark:border-gray-600"
                              />
                           </div>
                           <p className="text-[#999595] text-[11px] md:text-[10px]">
                              {" "}
                              In App
                           </p>
                        </div>

                        <div className="col-span-2 flex gap-1">
                           <div class=" flex items-center ">
                              <input
                                 id="green-radio"
                                 type="radio"
                                 value=""
                                 name="colored-radio"
                                 class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300  dark:border-gray-600"
                              />
                           </div>
                           <p className="text-[#999595] text-[11px] md:text-[10px] ">
                              Email
                           </p>
                        </div>

                        <div className="col-span-2 flex gap-1">
                           <div class=" flex items-center ">
                              <input
                                 id="green-radio"
                                 type="radio"
                                 value=""
                                 name="colored-radio"
                                 class="w-4 h-4 text-green-600 bg-gray-100 border-gray-300  dark:border-gray-600"
                              />
                           </div>
                           <p className="text-[#999595] text-[11px] md:text-[10px] ">
                              {" "}
                              Push Updates
                           </p>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </section>
   );
}
