import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleLeft, FaSearch } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";
import "react-multi-carousel/lib/styles.css";
import SmallMenu from "../../Common/SmallMenu";

export default function TitleSection({ hash }) {
   const [inputvalue, setInputValue] = useState("");
   const [showMenu, setShowMenu] = useState(false);
   const navigate = useNavigate();
   const handleChange = (e) => {
      setInputValue(() => e.target.value);
   };

   const data = [{ name: "Menu1" }, { name: "Menu2" }, { name: "Menu3" }];

   return (
      <div className="pt-4 px-2">
         <div className="grid grid-cols-8 relative items-center">
            <div className="absolute flex items-center justify-center text-[#fff]">
               <FaArrowAltCircleLeft
                  className="text-white text-[18px] md:text-[18px] cursor-pointer"
                  onClick={() => navigate(-1)}
               />
            </div>
            <div className="col-span-8 flex justify-center">
               <button className="bg-[#191919] hover:bg-[#2b2b2b] rounded-lg text-[#ffffff] text-[12px] font-bold px-[8px] py-[2px] cursor-pointer">
                  #{hash}
               </button>
            </div>
            <div className="absolute flex items-center justify-center text-[#fff] right-0 z-40 cursor-pointer">
               {!showMenu ? (
                  <BsThreeDots className="text-[1.4rem]" onClick={() => setShowMenu(!showMenu)} />
               ) : (
                  <IoCloseCircle className="text-[1.4rem] text-[#ff3838]" onClick={() => setShowMenu(!showMenu)} />
               )}
               {showMenu && <SmallMenu menuItems={data} />}
            </div>
         </div>
         <div className="w-full flex justify-center items-center flex-col my-2">
            <div className="w-4/5 flex relative">
               <FaSearch className="absolute left-[6px] top-1/2 transform -translate-y-1/2 cursor-pointer" />   
               <input
                  type="text"
                  placeholder="Search here"
                  value={inputvalue}
                  onChange={handleChange}
                  className="w-full outline-none rounded-full h-full py-[6px] pl-7"
               />
            </div>
         </div>
      </div>
   );
}
