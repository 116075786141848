import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button
} from "@material-tailwind/react";
export default function AddReviewCard(props) {
  const { cancel, menuRef, isFullScreen } = props;
  const [count, setCount] = useState(0);
  const [selected, setSelected] = useState("");

  const handleChange = (event) => {
    const inputText = event.target.value;
    if (inputText.length <= 200) {
      setCount(inputText.length);
    }
  };
  const handleSelect = (e) => {
    setSelected(e.target.value);
  };
  return (
    <Card
      className={`rounded-none ${isFullScreen ? " w-full p-4" : " w-[85%]"
        } shadow-[0px_1px_10px_10px_#b794f4]`}
      ref={menuRef}
    >
      <CardBody className="p-2">
        <Typography
          color="blue-gray"
          className={`mb-1  ${isFullScreen ? "text-[16px]" : "text-[12px]"
            } font-bold`}
        >
          Add Your Review
        </Typography>
        <Typography
          color="blue-gray"
          className={`mb-1  ${isFullScreen ? "text-[14px]" : "text-[8px]"}`}
        >
          We will fix our article according to your suggestion. Please select
          from the options or write your suggestions.
        </Typography>
        <Typography className={`mb-2 px-4 ${isFullScreen ? " h-10" : " h-6"}`}>
          <select
            value={selected}
            id=""
            class="bg-gray-50 border h-full  outline-none border-gray-300 text-gray-900 text-[12px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={handleSelect}
          >
            <option value={"Very good"}>Very good</option>
            <option value={"Good"}>Good</option>
            <option value={"Average"}>Average</option>
            <option value={"Bad"}>Bad</option>
          </select>
        </Typography>
        <Typography className={`${isFullScreen ? "" : "px-4"}`}>
          <div className="bg-[#fff]  rounded-lg border border-gray-500">
            <textarea
              rows="3"
              maxLength={200}
              onChange={handleChange}
              placeholder="Type Something"
              className="w-full bg-[#fff] px-[5px] py-[5px] focus:outline-none resize-none rounded-lg placeholder:text-[12px] text-[12px] font-semibold text-gray-700"
            />
            <p className="absolute right-8 text-gray-700 text-[12px] -mt-6">
              {count}/200
            </p>
          </div>
        </Typography>
      </CardBody>
      <CardFooter className="flex justify-center items-center gap-4 md:p-1">
        <Button
          variant="outlined"
          className={`${isFullScreen ? "p-2 px-6" : "p-1"
            } text-[8px] rounded-sm shadow-[3px_3px_2px_0px_#68d391]`}
        >
          Send
        </Button>
        <Button
          variant="outlined"
          className={`rounded-sm text-[8px] bg-[red] text-[#fff] ${isFullScreen ? "p-2 px-6" : "p-1"
            } shadow-[3px_3px_2px_0px_#e53e3e]`}
          onClick={() => cancel()}
        >
          Cancel
        </Button>
      </CardFooter>
    </Card>
  );
}
