import React from "react";
import { useSelector } from "react-redux";
import { Avatar } from "@material-tailwind/react";
import { TrendingTitle } from "../../Component/HashTag/TrendingTitle";

export default function HashTagTrending() {
   return (
      <div className="h-full bg-[#000] relative overflow-hidden">
         <TrendingTitle title={"Trending"} />
         <div className="mt-2 absolute w-full h-[-webkit-fill-available] overflow-scroll no-scrollbar">
            <TrendingVideo />
         </div>
         <p className="text-[9px] text-red-500 absolute top-0 w-full flex justify-center font-bold">
            CC HASHTAG TRENDING VIDEOS, FE, V8, JUNE 20, 2024
         </p>
      </div>
   );
}

const TrendingVideo = () => {

   const getAllPost = useSelector((state) => state.HomePageVideo);
   const { AllPosts } = getAllPost;
   let count = AllPosts.length;

   return (
      <div className="grid grid-cols-12 gap-2 items-center pt-2 pb-2 px-2 px-4cursor-pointer">
         {AllPosts.map((data) => {
            count--;
            return (
               <>
                  <div className="col-span-6" key={data.id}>
                     <div className="relative">
                        <video
                           src={`${data.video_url}`}
                           className="h-[12rem] w-full object-cover rounded"
                        />
                        <div className="absolute top-1 left-1">
                           <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[4px] py-[2px] rounded-2xl">
                              <img
                                 className="h-3 w-3 rounded-full object-cover"
                                 src={data.created_by.avatar_url}
                              />
                              <p className="text-[10px] text-white">
                                 {data.author}
                                 {data.created_by.first_name}
                              </p>
                           </div>
                        </div>
                        <div className="absolute bottom-1 right-1">
                           <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[6px] py-[2px] rounded-[8px]">
                              <p className="text-[10px] text-white">1.{count}M</p>
                           </div>
                        </div>
                     </div>
                     {data.body && (
                        <div className="flex justify-start items-center gap-1 mt-1">
                           <Avatar
                              src={data.created_by.avatar_url}
                              className="h-5 w-5 rounded-full object-cover"
                           />
                           <p className="text-white text-[10px] line-clamp-1">
                              {data.body}
                           </p>
                        </div>
                     )}
                  </div>
               </>
            );
         })}
      </div>
   );
};
