import React from "react";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
   const naviagte = useNavigate()
   return (
      <div>
         <div className="h-full w-full bg-gradient-to-r flex flex-col justify-center items-center px-2">
            <h1 className="text-white font-bold text-[1.5rem] ">Reset Password</h1>
            <div className="mb-4 mt-3 w-full px-8">
               <label
                  htmlFor="username"
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
               >
                  Current Password
               </label>
               <input
                  type="text"
                  id="username"
                  placeholder="Enter Your Current Password...."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            <div className="mb-4 w-full px-8">
               <label
                  htmlFor="username"
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
               >
                  New Password
               </label>
               <input
                  type="text"
                  id="username"
                  placeholder="Enter Your New Password...."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            <button
               onClick={() => naviagte("/account/private")}
               className="bg-orange-700 hover:bg-orange-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
               Save
            </button>
         </div>
      </div>
   );
}
