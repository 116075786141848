import React, { useState } from "react";
import { Button, Select, Option, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createFolder,
  SavedTheVideoInsideFolder,
} from "../../../Redux/ApiSlice/SavedVideosSlice";

export default function SaveModal({
  handleClose,
  videoId,
  fetchData,
  saveRef,
}) {
  const dispatch = useDispatch();
  const [createFolderShow, setCreateFolderShow] = useState(false);
  const [createFolderName, setCreateFolderName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectId, setSelectId] = useState(null);
  const ProfileDetails = useSelector((state) => state.AccountProfile);
  const savedVideo = useSelector((state) => state.savedVideos);
  const { SavedFolderList } = savedVideo;
  const { User_profile } = ProfileDetails;

  const handleChange = (e) => {
    setCreateFolderName(e.target?.value);
  };
  const handleSelect = (e) => {
    if (e.target?.value) {
      setSelectedFolder(e.target?.value);
    } else {
    }
  };

  const handleCreateFolder = async (value) => {
    if (value) {
      await dispatch(createFolder({ name: value, userId: User_profile.id }));
      fetchData();
      setCreateFolderName("");
      setCreateFolderShow(false);
    } else {
      return null;
    }
  };
  const handleSubmit = () => {
    if (createFolderShow) {
      handleCreateFolder(createFolderName);
    } else {
      if (selectId)
        dispatch(SavedTheVideoInsideFolder({ folderId: selectId, videoId }));
      handleClose();
    }
  };

  return (
    <div
      className="h-40 w-72 bg  bg-white px-6 py-2 z-50 rounded-md relative"
      ref={saveRef}
    >
      <p className="text-[12px] text-red-800 font-bold absolute top-0 w-full text-start">
        CC SAVE VIDEO POPUP ,FE , V1 ,JULY 17 , 2024
      </p>
      <div className="flex justify-between mt-2">
        <p className="font-bold text-[12px] md:text-[14px]">
          {" "}
          {createFolderShow ? "Enter Folder Name" : "Select Folder"}
        </p>
        <p
          className="text-[#00A3FF] text-[12px] md:text-[14px] font-bold cursor-pointer"
          onClick={() => setCreateFolderShow(!createFolderShow)}
        >
          {createFolderShow ? (
            <span className="text-[12px] text-red-700 font-bold cursor-pointer">
              Cancel
            </span>
          ) : (
            "Create Folder"
          )}
        </p>
      </div>
      <div className="pb-0 relative mt-4">
        {createFolderShow ? (
          <Typography className="w-full mt-1">
            <input
              placeholder=""
              className="outline-none bg-[#D9D9D9] p-2 w-full rounded-md"
              onChange={handleChange}
              value={createFolderName}
            />
          </Typography>
        ) : (
          <Select
            label="Folder List "
            className="font-bold text-base"
            value={selectedFolder}
            onChange={handleSelect}
          >
            {SavedFolderList?.map((item) => (
              <Option
                className="font-bold text-base"
                key={item.id}
                onClick={() => setSelectId(item.id)}
              >
                {item.name}
              </Option>
            ))}
          </Select>
        )}
      </div>
      <div className="flex justify-around mt-4">
        <Button
          onClick={() => handleSubmit()}
          className="bg-[#D9D9D9] text-[black] p-2 w-2/5"
        >
          <span>Save</span>
        </Button>
        <Button
          className="bg-[#D9D9D9] text-[black] p-2 w-2/5"
          onClick={() => handleClose()}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </div>
  );
}
