import React from "react"
import CreatedGroupProfileSection from "./CreatedGroupProfileSection"

const CreatedGroupInfo = () => {
   return (
      <div className="bg-black h-full">
         <div className="w-full flex justify-center items-center absolute top-0">
            <p className="text-[#ff3b3b] text-center font-bold text-[8px]">
               CC GROUP OWNER INFO PAGE - FE - V4 / JUNE 25, 2024
            </p>
         </div>
         <CreatedGroupProfileSection />

         <hr className="w-full mt-[6px] my-[6px] h-[0.5px] bg-gray-800 border-0" />

         <div className="mt-1 px-4">
            <p className="text-white text-[18px] font-bold pb-1">Rules</p>
            <p className="text-white text-[13px]">1. No Swearing</p>
            <p className="text-white text-[13px]">2. Only one post per user per day</p>
            <p className="text-white text-[13px]">3. No spams</p>
            <p className="text-white text-[13px]">4. Don't pretend to be someone else</p>
         </div>

         <div className="mt-3 px-4">
            <p className="text-white text-[18px] font-bold pb-1">Disclaimers</p>
            <p className="text-white text-[13px]">1. We do not represent ferrari</p>
            <p className="text-white text-[13px]">2. We do not conduct car meet-ups</p>
         </div>

         <div className="mt-3 px-4">
            <p className="text-white text-[17px] font-bold">Date Created : APR 13, 2024</p>
         </div>
      </div>
   )
}

export default CreatedGroupInfo