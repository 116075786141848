import { Button, Dialog, DialogBody, DialogFooter } from "@material-tailwind/react";

export function SavedModal({ size, handleOpen, showtext }) {
   return (
      <>
         <Dialog open={size} handler={handleOpen} size="sm" className="rounded-none">
            <DialogBody className="text-center text-base font-semibold">
               {showtext} Folder is empty
            </DialogBody>
            <DialogFooter className="w-full flex justify-center">
               <Button
                  variant="gradient"
                  className="bg-[#000] text-[#fff]"
                  onClick={() => handleOpen(null)}
               >
                  <span>cancel</span>
               </Button>
            </DialogFooter>
         </Dialog>
      </>
   );
}
