import { useNavigate } from "react-router-dom";
import SecretSauceButton from "../SecretSauceButton/SecretSauceButton";
import { Button } from "@material-tailwind/react";

export default function ForgotPassword({ handleChange, email, handleSendOtp }) {
  const navigate = useNavigate();
  const handleClick = (value) => {
    navigate(`/${value}`);
  };
  return (
    <div className="h-full w-full block relative">
      <div className="w-full h-[85%]">
        <div className="grid grid-cols-12 bg-[#fff] justify-end items-center">
          <p className="text-[#ff3b3b]  font-extrabold text-[10px] top-0 w-full text-center absolute">
            CC FORGOTPASSWORD - FE - V3 ,JULY 06 , 2024
          </p>

          <p className="col-span-12 text-center text-[4rem]">😊</p>
          <h1 className="col-span-12 text-center text-[#00A3FF] text-[5rem] font-bold">
            CC
          </h1>
          <h3 className="col-span-12 text-center font-extrabold text-xl">
            Lost Your Password?
          </h3>
          <p className="col-span-12 text-center text-[14px] font-semibold  w-full px-4 my-4">
            Please enter your email for a verificaiton link.
          </p>
        </div>
        <div className="my-4 w-full bg-[#fff] px-4">
          <form className="w-full h-full">
            <div className="flex flex-col h-2/6">
              <label className="mb-2 text-gray-500">Email</label>
              <input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={handleChange}
                className="p-4 md:p-2 border-gray-500  border-2 border-solid outline-none rounded-md"
              />
            </div>

            <div className="grid grid-cols-4 items-center mt-6 gap-4">
              <Button
                className="p-3 md:p-2 bg-black text-[#fff] col-span-2"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Button
                className="p-3 md:p-2 bg-black text-[#fff] col-span-2"
                onClick={(e) => handleSendOtp(e, email)}
              >
                Send Link
              </Button>
            </div>
          </form>
        </div>
      </div>
      <SecretSauceButton />
      <div className=" flex justify-center items-center h-[8%] md:h-[7%] bg-[black]">
        <button
          className="rounded-[2rem] px-4 py-1 bg-[#D9D9DE]  font-bold text-base"
          onClick={() => handleClick("register")}
        >
          Register
        </button>
      </div>
    </div>
  );
}
