import React from "react";

const ProductListSection3 = () => {
    const shoeData = [
        {
            id: 1,
            price: 785,
            discount: 10,
            name: "Nike Air Max 270",
            img: "https://images.unsplash.com/photo-1606107557195-0e29a4b5b4aa?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 2,
            price: 369,
            discount: 5,
            name: "Nike Air Max 90",
            img: "https://images.unsplash.com/photo-1605348532760-6753d2c43329?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 3,
            price: 189,
            discount: 14,
            name: "Nike Air Force 1",
            img: "https://images.unsplash.com/photo-1605408499391-6368c628ef42?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 4,
            price: 482,
            discount: 10,
            name: "Nike Air Max Plus",
            img: "https://images.unsplash.com/photo-1582588678413-dbf45f4823e9?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 5,
            price: 158,
            discount: 25,
            name: "Nike Air VaporMax",
            img: "https://images.unsplash.com/photo-1628253747716-0c4f5c90fdda?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 6,
            price: 954,
            discount: 33,
            name: "Nike Air Max 97",
            img: "https://images.unsplash.com/photo-1600269452121-4f2416e55c28?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 7,
            price: 254,
            discount: 15,
            name: "Nike Air Max 720",
            img: "https://images.unsplash.com/photo-1579338908476-3a3a1d71a706?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 8,
            price: 483,
            discount: 18,
            name: "Nike Air Huarache",
            img: "https://images.unsplash.com/photo-1595950653106-6c9ebd614d3a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 9,
            price: 784,
            discount: 23,
            name: "Nike Air Max 200",
            img: "https://images.unsplash.com/photo-1597045566677-8cf032ed6634?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 10,
            price: 325,
            discount: 5,
            name: "Nike Air Max Dia",
            img: "https://images.unsplash.com/photo-1595909337002-3cdc3a590160?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        }
    ];

    return (
        <div className="px-2 mt-1 mb-2">
            <div className="grid grid-cols-2 gap-2">
                {shoeData.map((shoe) => (
                    <div className="col-span-1" key={shoe.id}>
                        <img
                            src={shoe.img}
                            alt={shoe.name}
                            className="w-full h-[10rem] object-cover rounded-tl-[1rem] rounded-tr-[3rem]"
                        />
                        <div className="flex flex-col">
                            <div className="flex items-center justify-start gap-2 overflow-hidden">
                                <p className="text-[#39db3e] text-[14px] font-bold">{shoe.discount}%</p>
                                <p className="text-[#515151] text-[14px] line-through">${shoe.price}</p>
                                <p className="text-[#ffffff] text-[14px] font-bold ml-2">
                                    ${Math.round(shoe.price - (shoe.price * shoe.discount / 100))}
                                </p>
                            </div>
                            <p className="text-[#e0e0e0] text-[14px] line-clamp-1 mt-[-4px]">{shoe.name}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ProductListSection3