import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogBody, DialogFooter, Input } from "@material-tailwind/react";

export const EditSavedFolderModal = ({ handleOpenEditModal, deleteItem, selectFolderId, EditModal, renameFolderName}) => {
   const [folderName, setFolderName] = useState("");

   const handleChangeFolderName = (e) => {
      setFolderName(e.target.value);
   };

   const handleSubmit = () => {
      renameFolderName(folderName, selectFolderId);
   };
   
   useEffect(() => {
      setFolderName(deleteItem[0]?.name);
   }, [deleteItem[0]?.name]);
   
   
   return (
      <Dialog open={EditModal} handler={handleOpenEditModal} size="sm" className="rounded-lg">
         <DialogBody className="my-2">
            <Input
               label="Rename Folder Name"
               className=""
               value={folderName}
               onChange={handleChangeFolderName}
            />
         </DialogBody>

         <DialogFooter className="w-full flex gap-5 justify-center items-center">
            <Button
               color="black"
               onClick={() => handleSubmit()}
            >
               <span>Save</span>
            </Button>
            <Button
               color="red"
               onClick={() => handleOpenEditModal(null)}
            >
               <span>Cancel</span>
            </Button>
         </DialogFooter>
      </Dialog>
   );
};
