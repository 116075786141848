import React from "react";
import { Button, Dialog, DialogHeader, DialogFooter, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export function OrderCancelConfirmationModal({ handleOpen, open }) {
   const navigate = useNavigate();

   return (
      <>
         <Dialog open={open} handler={handleOpen} className="w-[300px] rounded-none absolute" >
            <DialogHeader className="flex justify-center">
               <Typography variant="h5" color="blue-gray">
                  Are you sure to cancel ?
               </Typography>
            </DialogHeader>
            <DialogFooter className="space-x-2 flex justify-center gap-10">
               <Button variant="gradient" color="" onClick={() => navigate("/ordercancel")}>
                  Yes
               </Button>
               <Button variant="gradient" color="blue-gray" onClick={handleOpen}>
                  No
               </Button>
            </DialogFooter>
            <p className="text-[10px] text-red-700 font-bold w-full text-center">
               CC ORDER CANCEL CONFIRMATION POPUP , FE , V1 , MARCH 28 , 2024
            </p>
         </Dialog>
      </>
   );
}
