import React, { useState } from "react";
import LeftSide from "../ShareHomePage/LeftSide";
import RightSide from "../ShareHomePage/RightSide";
import { Drawer } from "@material-tailwind/react";
import toast, { ToastBar } from "react-hot-toast";

export default function Share({ closeDrawerBottom, openBottom, videoUrl }) {
   const downloadVideo = async () => {
      try {
         const response = await fetch(videoUrl);
         if (!response.ok) throw new Error("Network response was not ok");

         const blob = await response.blob();
         const url = window.URL.createObjectURL(blob);
         const a = document.createElement("a");
         a.style.display = "none";
         a.href = url;
         a.download = "video.mp4";
         document.body.appendChild(a);
         a.click();
         window.URL.revokeObjectURL(url);
      } catch (error) {
         console.error("Error downloading the video:", error);
      }
   };
   return (
      <>
      
         <Drawer
            placement="bottom"
            open={openBottom}
            onClose={closeDrawerBottom}
            size={270}
            className={`px-4  absolute w-full ${!openBottom && "hidden"}`}
            style={{ zIndex: 1 }}
         >
            <div className="">
               <h2 className="text-[red] felx justify-center text-center font-bold text-sm">
                  CC SHARE PAGE /FE V-1/JUNE 22 /2024
               </h2>
            </div>
            <div className="grid grid-cols-6">
               <div className="col-span-5 h-full border-r-[1px] border-black">
                  <LeftSide />
               </div>
               <div className="col-span-1 mt-4 pl-5">
                  <RightSide downloadVideo={downloadVideo} />
               </div>
            </div>
         </Drawer>
      </>
   );
}
