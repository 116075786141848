import { useSelector } from "react-redux";
import VideoCard from "../../Common/VideoCard";
import useSelectVideo from "../../Hooks/useSelectVideo";
import RepostEmpty from "../../Assets/YourAccountIcons/RepostEmptyData.png";

export function RepostTab() {
   const getAllPost = useSelector((state) => state.HomePageVideo);
   const { AllPosts } = getAllPost;
   const selectVideo = useSelectVideo();

   const handleSelectVideo = async (id) => {
      selectVideo(id, AllPosts);
   };

   return (
      <>
         {true ? (
            <div className="grid grid-cols-2 gap-2 overflow-scroll no-scrollbar">
               {AllPosts?.map((item) => (
                  <VideoCard
                     id={item.id}
                     body={item.body}
                     belowDecs={true}
                     threeDots={false}
                     views={item.views}
                     video={item.video_url}
                     likes_count={item.likes_count}
                     selectVideo={handleSelectVideo}
                     avatar_url={item.created_by.avatar_url}
                  />
               ))}
            </div>
         ) : (
            <div className="flex justify-center items-center flex-col h-[80%]">
               <img src={RepostEmpty} alt="img" className="h-32 w-32" />
               <p className="text-[10px] text-[#c0b8b8] font-semibold">
                  No Repost yet
               </p>
            </div>
         )}
      </>
   );
}
