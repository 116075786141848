import React, { useState } from "react";
import "../MessagesPage/MessagesPage.css";
import Settingicon from "../../../Assets/MessagesIcons/Settings icon.png";
import SearchIcon from "../../../Assets/MessagesIcons/Chatbolt Icon.png";
import WelcomeImage from "../../../Assets/MessagesIcons/messagesWelcomeImage.png";
import frame from "../../../Assets/MessagesIcons/Frame 130.png";
import frame1 from "../../../Assets/MessagesIcons/Frame 131.png";
import frame2 from "../../../Assets/MessagesIcons/Frame 132.png";
import frame3 from "../../../Assets/MessagesIcons/Frame 133.png";
import frame4 from "../../../Assets/MessagesIcons/Frame 134.png";
import frame5 from "../../../Assets/MessagesIcons/Frame 135.png";
import { useNavigate } from "react-router-dom";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { MessagesConversationTab } from "../../../Component/MobileMessagesTabs/MessagesConversationTab";

export default function MessagesPage({ children }) {
  const [clickSingleItem, setClickSingleItem] = useState("All");
  const [activeTab, setActiveTab] = useState("All");
  const [selectedperson, setSelectedPerson] = useState("");
  const navigate = useNavigate();
  const sideBar = ["All", "Groups", "Favorites", "Requests"];
  const [welcomeImg, setWelcomeImg] = useState(true);
  const datalist = [
    { id: 1, icon: frame, text: "Ariel Bradshaw" },
    { id: 2, text: "George Rutherford", icon: frame1 },

    { id: 3, icon: frame2, text: "Kate Dawson" },
    { id: 4, text: "Katrine Sharma", icon: frame3 },
    { id: 5, text: "Gigi Cho", icon: frame4 },
    { id: 6, text: "White Tiger’s Rule!", icon: frame5 },
    { id: 6, text: "White Tiger’s Rule!", icon: frame5 },
    { id: 6, text: "White Tiger’s Rule!", icon: frame5 },
    { id: 6, text: "White Tiger’s Rule!", icon: frame5 },
    { id: 6, text: "White Tiger’s Rule!", icon: frame5 },
  ];
  const handleChangeTab = (title) => {
    setActiveTab(title);
  };
  const handleNavigate = (id) => {
    setWelcomeImg(false);
    // setSelectedPerson(id);
    navigate(`/messages/${id}`)
  };
  const chartdata = [
    {
      sam: "When forty winters shall besiege thy brow,",
      samicon: frame1,
      saran: "",
      saraniocn: frame2,
    },
    {
      sam: "",
      saran:
        "And dig deep trenches in thy beauty's field, Thy youth's proud livery, so gazed on now, Will be a tattered weed of small worth held.",
      saraniocn: frame2,
      samicon: frame1,
    },
    {
      sam: "",
      saran:
        "Then being asked where all thy beauty lies, Where all the treasure of thy lusty days, To say within thine own deep-sunken eyes. Were an all-eating shame and thriftless praise.",
      saraniocn: frame2,
      samicon: frame1,
    },
    {
      sam: "How much more praise deserved thy beauty's useIf thou couldst answer",
      saran: "",
      saraniocn: frame2,
      samicon: frame1,
    },
    {
      sam: "",
      saran: "'This fair child of mine ",
      saraniocn: frame2,
      samicon: frame1,
    },
  ];

  const handleSelectItem = () => {
    switch (activeTab) {
      case "Manage Conversations":
        return <MessagesConversationTab iswebVersion={true} />;
        break;
      default:
        break;
    }
  };
  const handleSelectMenu = () => {
    setActiveTab("Manage Conversations");
    navigate("/messages/MessagesConversationTab");
  };
  return (
    <section className="webmessages-container h-[85vh]">
      <div className="webmessages-side-bar border-r-4 border-pink-500 h-full">
        <div className="webmessages-title">
          <div className="messages-setting-icon">
            <Menu>
              <MenuHandler>
                <img src={Settingicon} alt="" className="h-10 w-10" />
              </MenuHandler>
              <MenuList className=" p-0 flex flex-col items-start absolute left-50">
                <MenuItem
                  className={` ${activeTab == "Manage Conversations" && "bg-[#762978]" } hover:bg-[#762978] p-3 text-left border-b-2  rounded-none font-bold`}
                  onClick={() => handleSelectMenu()}
                >
                  Manage Conversations
                </MenuItem>
                <MenuItem className="hover:bg-[#762978]  p-3 text-left border-b-2  rounded-none font-bold">
                  Manage Sounds
                </MenuItem>
                <MenuItem className="hover:bg-[#762978] p-3 text-left border-b-2  rounded-none font-bold">
                  Status and Availablilty
                </MenuItem>
                <MenuItem className=" hover:bg-[#762978] p-3 text-left border-b-2  rounded-none font-bold">
                  Call Settings
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div>
            <h4>Messages</h4>
          </div>
        </div>
        <div className="webmessages-tab">
          {sideBar?.map((title) => (
            <a
              className={
                activeTab == title
                  ? "webmessages-activetab-text"
                  : "webmessages-tab-text"
              }
              onClick={() => handleChangeTab(title)}
            >
              <p>{title}</p>
            </a>
          ))}
        </div>
        <div className="webmessages-searchBar-container">
          <div className="webmessages-searchBar">
            <img src={SearchIcon} />
            <input
              type="text"
              name=""
              id=""
              width={25}
              height={25}
              placeholder="Find Contacts or Conversations..."
            />
          </div>
        </div>
        <div className="webmessages-chart-list h-[63vh] overflow-scroll no-scrollbar">
          {datalist.map((item) => (
            <div
              className={
                selectedperson === item.id
                  ? "webmessages-chart-item-color"
                  : "webmessages-chart-item"
              }
              onClick={() => handleNavigate(item.id)}
            >
              <div className="webmessages-chart-item-one">
                <img src={item.icon} alt="" />
                <p>{item.text}</p>
              </div>
              <div className="online-time-show">20 minutes ago</div>
            </div>
          ))}
        </div>
      </div>
      <div className="webmessages-content ">
        {children}
      </div>
    </section>
  );
}
