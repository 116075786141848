import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";

function ForgotPassword() {
   const [email, setEmail] = useState(false);
   const navigate = useNavigate();

   const handleSubmit = () => {
      setEmail(true);
   };

   return (
      <div className="bg-black shadow-2xl w-full h-full bg-gradient-to-r py-0">
         <div className="flex justify-start text-start">
            <IoArrowBackCircle 
               onClick={() => navigate(-1)} 
               className="text-[1.5rem] text-white hover:text-[#ffffffe3] cursor-pointer" 
            />
         </div>
         <div className="flex flex-col justify-center items-center mt-3">
            <h1 className="text-white font-bold text-[1.5rem]">Forgot Password</h1>
            <div className="mb-4 mt-2 w-full px-4">
               <label
                  htmlFor="username"
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
               >
                  Email
               </label>
               <input
                  type="text"
                  id="username"
                  placeholder="Enter Your valid email...."
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            <button
               onClick={() => navigate("/account/private/enter-otp")}
               className="bg-orange-700 hover:bg-orange-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" /* className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4" */
            >
               Send OTP
            </button>
         </div>
      </div>
   );
}

export default ForgotPassword;
