import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import { FaStar, FaRegStarHalfStroke } from "react-icons/fa6";
import MoreDetails from "../detailspage/MoreDetails";
import { useNavigate } from "react-router-dom";

const AboutProducts = () => {
   const [quantity, setQuantity] = useState(1);
   const [showMore, setShowMore] = useState(false);
   const navigate = useNavigate();

   const decreaseQuantity = () => {
      if (quantity > 1) {
         setQuantity(quantity - 1);
      }
   };

   const increaseQuantity = () => {
      if (quantity < 10) {
         setQuantity(quantity + 1);
      }
   };

   const toggleShowMore = () => {
      setShowMore(!showMore);
   };

   return (
      <div className="px-4 mt-5">
         <p className="text-2xl font-semibold">IPhone 15 Pro</p>
         <p className="text-2xl">$1541.55</p>
         <p className="mt-2 line-clamp-2 leading-[14px] text-[12px]">
            FORGED IN TITANIUM — iPhone 15 Pro has a strong and light
            aerospace-grade titanium design with a textured matte-glass back. It
            also features a Ceramic Shield front thats tougher than any smartphone
            glass. And its splash, water, and dust resistant.
         </p>
         
         {!showMore && (
            <p className="text-blue-300 cursor-pointer text-[12px]" onClick={toggleShowMore}>
               View More
            </p>
         )}

         <div className="flex items-center gap-2 mt-3">
            <p className="text-[12px] font-bold">4.5</p>
            <div className="flex text-green-400">
               <FaStar />
               <FaStar />
               <FaStar />
               <FaStar />
               <FaRegStarHalfStroke />
            </div>
            <p className="text-[12px] text-yellow-800 font-bold">485 Reviews</p>
         </div>

         <div className="flex justify-end mt-3">
            
            <div className="flex items-center gap-2">
               <Button
                  onClick={decreaseQuantity}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
               >
                  -
               </Button>
               <input
                  type="text"
                  value={quantity}
                  readOnly
                  className="appearance-none md:placeholder:text-[14px] bg-gray-300 border-none w-16 text-center pt-[7px] pb-[7px] px-4 text-gray-600 leading-tight focus:outline-none focus:bg-[#fff] focus:border-gray-500 rounded"
               />
               <Button
                  onClick={increaseQuantity}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
               >
                  +
               </Button>
            </div>
         </div>

         <div className="flex justify-between mt-3">
            <Button
               onClick={() => navigate("/wishlist")}
               className="text-[12px] px-3 py-2"
            >
               Add to Wishlist
            </Button>
            <Button
               onClick={() => navigate("/basket")}
               className="text-[12px] px-3 py-2"
            >
               Add to basket
            </Button>
         </div>

         <div className="mt-3 mb-5">
            <Button
               className="w-full text-[12px] p-2"
               onClick={() => navigate("/shopaddress")}
            >
               Purchase Now
            </Button>
         </div>

         {showMore && <MoreDetails />}
      </div>
   );
};

export default AboutProducts;
