import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";
import axios from "axios";

export const AccountProfile = createAsyncThunk("AccountProfile", async () => {
  try {
    const response = await axiosInstance.get("/user_profile/");
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});
export const AccountProfileNameEdit = createAsyncThunk(
  "AccountProfileNameEdit",
  async (data) => {
    const response = await axiosInstance.patch(`edit_names/`, data);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const AccountProfileDescription = createAsyncThunk(
  "AccountProfileDescription",
  async (data) => {
    const response = await axiosInstance.patch("edit_about_us/", data);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const AccountProfileImageEdit = createAsyncThunk(
  "AccountProfileImageEdit",
  async (data) => {
    let form_data = new FormData();
    form_data.append("avatar", data);
    try {
      const response = await axiosInstance.patch("edit_avatar/", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const AccountProfilePosts = createAsyncThunk(
  "AccountProfilePosts",
  async (id) => {
    const response = await axiosInstance.get(`/post/profile/${id}/public-posts/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const AccountProfilePostDelete = createAsyncThunk(
  "AccountProfilePostDelete",
  async (id) => {
    const response = await axiosInstance.delete(`post/delete/${id}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const UserAccountFollowerlist = createAsyncThunk(
  "UserAccountFollowerlist",
  async () => {
    const response = await axiosInstance.get(`/followers_list/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const UserAccountFollowinglist = createAsyncThunk(
  "UserAccountFollowinglist",
  async () => {
    const response = await axiosInstance.get(`/following_list/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const UserAccountFollow = createAsyncThunk(
  "UserAccountFollow",
  async (id) => {
    const response = await axiosInstance.post(`/follow/${id}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const OtherUserProfile = createAsyncThunk(
  "OtherUserProfile",
  async (id) => {
    const response = await axiosInstance.get(`/another-user/${id}/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);
export const UserlikedVideos = createAsyncThunk(
  "UserlikedVideos",
  async (id) => {
    const response = await axiosInstance.get(`/post/my_likes/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);



export const getPrivateVideos = createAsyncThunk(
  "getPrivateVideos",
  async (id) => {
    const response = await axiosInstance.get(`/post/profile/${id}/private-posts/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);


export const AccountProfileSlice = createSlice({
  name: "AccountProfile",
  initialState: {
    User_profile: {},
    User_posts: [],
    User_followersList: [],
    User_followingList: [],
    otherUser_profile: [],
    User_likedVideos: [],
    private_Videos: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // AccountProfile action promises
    builder.addCase(AccountProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AccountProfile.fulfilled, (state, action) => {
      state.User_profile = action.payload;
      state.loading = false;
    });
    builder.addCase(AccountProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // AccountProfileNameEdit action promises
    builder.addCase(AccountProfileNameEdit.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AccountProfileNameEdit.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(AccountProfileNameEdit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // AccountProfilePosts action promises
    builder.addCase(AccountProfilePosts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(AccountProfilePosts.fulfilled, (state, action) => {
      state.loading = false;
      state.User_posts = action.payload;
    });
    builder.addCase(AccountProfilePosts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // account page followers list action promise
    builder.addCase(UserAccountFollowerlist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UserAccountFollowerlist.fulfilled, (state, action) => {
      state.loading = false;
      state.User_followersList = action.payload;
    });
    builder.addCase(UserAccountFollowerlist.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // account page following list action promise
    builder.addCase(UserAccountFollowinglist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UserAccountFollowinglist.fulfilled, (state, action) => {
      state.loading = false;
      state.User_followingList = action.payload;
    });
    builder.addCase(UserAccountFollowinglist.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // account page UserAccountFollow action promise
    builder.addCase(UserAccountFollow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UserAccountFollow.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(UserAccountFollow.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // account page OtherUserProfile action promise
    builder.addCase(OtherUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(OtherUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.otherUser_profile = action.payload;
    });
    builder.addCase(OtherUserProfile.rejected, (state, action) => {
      state.loading = false;
      state.otherUser_profile = action.payload;
      state.error = action.payload;
    });

    // account page UserlikedVideos action promise
    builder.addCase(UserlikedVideos.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(UserlikedVideos.fulfilled, (state, action) => {
      state.loading = false;
      state.User_likedVideos = action.payload;
    });
    builder.addCase(UserlikedVideos.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // account page getPrivateVideos action promise
    builder.addCase(getPrivateVideos.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPrivateVideos.fulfilled, (state, action) => {
      state.loading = false;
      state.private_Videos = action.payload;
    });
    builder.addCase(getPrivateVideos.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default AccountProfileSlice.reducer;
