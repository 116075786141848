import React, { useState, useRef } from "react";
import { FaCommentDots } from "react-icons/fa6";
import { FaLock } from "react-icons/fa";
import { FaShareAltSquare, FaCreativeCommonsRemix } from "react-icons/fa";
import { RiAdvertisementFill } from "react-icons/ri";
import { IoSave } from "react-icons/io5";
import { MdDownloading } from "react-icons/md";
import { Stepper, Step } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HomepageVideo } from "../../Redux/ApiSlice/HomePageVideoSlice";
import { uploadVideoData } from "../../Redux/ApiSlice/UploadVideoSlice.js";
import { UploadVideoWaringModal } from "../Modals/UploadVideo/UploadVideoWaringModal.jsx";
import { Input } from "@material-tailwind/react";
import ProgresBar from "../../Common/ProgresBar.jsx";

// import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
// import { FFmpeg } from '@ffmpeg/ffmpeg'
// import { fetchFile } from '@ffmpeg/util'

const Upload = () => {
   // const progress = useSelector((state) => state.Progress);
   const [count, setCount] = useState(0);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile } = ProfileDetails;
   const uploadedVideo = useSelector((state) => state.Uploadvideo);
   const ProgressBar = useSelector((state) => state.ProgressBar);
   const { loading } = uploadedVideo;
   const [isCheckedStandard, setIsCheckedStandard] = useState(false);
   const [isCheckedHD, setIsCheckedHD] = useState(false);

   // Upload Settings
   const [isCheckedPublic, setIsCheckedPublic] = useState(false);
   const [isCheckedPrivate, setIsCheckedPrivate] = useState(false);
   const [isCheckedComment, setIsCheckedComment] = useState(false);
   const [isCheckedRemix, setIsCheckedRemix] = useState(false);
   const [isCheckedShare, setIsCheckedShare] = useState(false);
   const [isCheckedSave, setIsCheckedSave] = useState(false);
   const [isCheckedDownload, setIsCheckedDownload] = useState(false);
   const [isAdvert, setIsAdvert] = useState(false);

   const [activeStep, setActiveStep] = useState(0);
   const [isLastStep, setIsLastStep] = useState(false);
   const [isFirstStep, setIsFirstStep] = useState(false);
   const videoRef = useRef();
   const fileRef = useRef(null);

   const [longDurationVideo, setLongDurationVideo] = useState(false);
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(() => !open);

   const [videoFile, setVideoFile] = useState(null);
   const [audioFile, setAudioFile] = useState(null);
   const [outputVideo, setOutputVideo] = useState(null);

   const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
   const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

   const [uploadVideo, setUploadVideo] = useState({
      body: "",
      video: uploadedVideo.SelectedVideo.file,
      user_id: User_profile.id,
      is_private: isCheckedPrivate,
   });

   const handleSubmitVideo = async () => {
      if (uploadedVideo.SelectedVideo.liveStream) {
         navigate("/createLiveStream");
      } else {
         if (uploadVideo.video) {
            await dispatch(uploadVideoData(uploadVideo));
            await dispatch(HomepageVideo());
            navigate("/account");
         }
      }
   };

   const handleChange = (event) => {
      const inputText = event.target.value;

      if (inputText.length <= 50) {
         setUploadVideo({ ...uploadVideo, body: inputText });
         setCount(inputText.length);
      }
   };

   // Handle Add Video Voice
   const handleCheckboxStandard = () => {
      setIsCheckedStandard(!isCheckedStandard);
      if (!isCheckedStandard) {
         // compressVideo(uploadVideo.video)
      }
      setIsCheckedHD(false);
   };

   const handleCheckboxHD = () => {
      setIsCheckedHD(!isCheckedHD);
      setIsCheckedStandard(false);
   };

   const handleCheckboxPrivate = () => {
      setIsCheckedPrivate(!isCheckedPrivate);
      setIsCheckedPublic(false);

      setUploadVideo({ ...uploadVideo, is_private: !isCheckedPrivate });
   };

   const handleCheckboxComment = () => {
      setIsCheckedComment(!isCheckedComment);
   };

   const handleCheckboxRemix = () => {
      setIsCheckedRemix(!isCheckedRemix);
   };

   const handleCheckboxShare = () => {
      setIsCheckedShare(!isCheckedShare);
   };

   const handleCheckboxSave = () => {
      setIsCheckedSave(!isCheckedSave);
   };
   const handleCheckboxDownload = () => {
      setIsCheckedDownload(!isCheckedDownload);
   }
   const handleCheckboxAdvert = () => {
      setIsAdvert(!isAdvert);
   };

   const allStepsCompleted = activeStep >= 3;

   const handleSubmit = () => {
      handleSubmitVideo();
   };

   const handleNavigate = () => {
      navigate(-1);
   };

   const handleLoadedMetadata = () => {
      if (videoRef.current?.duration > 60) {
         setLongDurationVideo(true);
      } else {
         setLongDurationVideo(false);
      }
   };
   /* gallery voice change functionality  */

   const handleAudioFileChange = (event) => {
      setAudioFile(event.target.files[0]);
      replaceAudio()
   };


   const replaceAudio = async () => {
      if (!uploadedVideo.SelectedVideo.file || !audioFile) {
         console.error('Both video and audio files are required');
         return;
      }

      const formData = new FormData();
      formData.append('video', uploadedVideo.SelectedVideo.file);
      formData.append('audio', audioFile);

      try {
         const response = await fetch('YOUR_SERVER_ENDPOINT', {
            method: 'POST',
            body: formData,
         });

         if (!response.ok) {
            throw new Error('Server error');
         }

         const result = await response.blob();
         const url = URL.createObjectURL(result);
         setOutputVideo(url);
      } catch (error) {
         console.error('Error processing video and audio:', error);
      }
   };


   return (
      <div className="absolute bottom-0 w-full h-full bg-[#fff]  overflow-hidden">
         {loading && (
            <div className="absolute w-full  h-screen justify-center items-center z-50">
               <div className="absolute h-full w-full bg-gray-600 opacity-30"></div>
               <ProgresBar width={uploadedVideo.progress} />
            </div>
         )}

         {!(activeStep == 3) && (
            <div className=" h-[70%] md:h-[65%]">
               <video
                  ref={outputVideo ? outputVideo : videoRef}
                  src={uploadedVideo.SelectedVideo.fileURL}
                  controls
                  style={{ maxWidth: "100%" }}
                  controlslist="nodownload noplaybackrate"
                  type={"video/mp4"}
                  aria-hidden
                  disablePictureInPicture
                  disablePlayback
                  disableRemotePlayback
                  className="h-full w-full object-cover"
                  onLoadedMetadata={handleLoadedMetadata}
               />
            </div>
         )}
         {!allStepsCompleted && (
            <Stepper
               activeStep={activeStep}
               isLastStep={(value) => setIsLastStep(value)}
               isFirstStep={(value) => setIsFirstStep(value)}
               lineClassName="bg-[#000]/0"
               activeLineClassName="bg-[#000]/0"
               className="px-2"
            >
               <Step className="h-0 w-0" onClick={() => setActiveStep(0)}>
                  <div className="absolute -bottom-[2rem] item-center w-[5.5rem] left-0 cursor-pointer">
                     <p className={`text-[#000] font-semibold text-center text-[10px] ${activeStep === 0 ? "bg-[#03B735]" : "bg-[#d9d9d9]"} px-[10px] py-[4px]`}>
                        Video Voice
                     </p>
                  </div>
               </Step>
               <Step className="h-0 w-0" onClick={() => setActiveStep(1)}>
                  <div className="absolute -bottom-[2rem] w-[5.5rem] cursor-pointer">
                     <p className={`text-[#000] font-semibold text-center text-[10px] ${activeStep === 1 ? "bg-[#03B735]" : "bg-[#d9d9d9]"} px-[10px] py-[4px]`}>
                        Video Quality
                     </p>
                  </div>
               </Step>
               <Step className="h-0 w-0" onClick={() => setActiveStep(2)}>
                  <div className="absolute -bottom-[2rem] w-[5.5rem] right-0 cursor-pointer">
                     <p className={`text-[#000] font-semibold text-center text-[10px] ${activeStep === 2 ? "bg-[#03B735]" : "bg-[#d9d9d9]"} px-[10px] py-[4px]`}>
                        Description
                     </p>
                  </div>
               </Step>
               <Step className="h-0 w-0 absolute" onClick={handleSubmit}></Step>
            </Stepper>
         )}
         <form>
            {activeStep === 0 && (
               <div className="absolute flex justify-center items-center bg-[#d9d9d9] p-4 w-full mt-[3rem]">
                  <div className="grid grid-cols-10 gap-5">
                     <div className="col-span-5 bg-[#000] flex justify-center items-center p-4 w-full cursor-pointer rounded">
                        <p
                           className="text-[12px] text-[#fff]"
                           onClick={() => {
                              fileRef.current?.click();
                           }}
                        >
                           Gallery Voice
                        </p>
                        <input
                           type="file"
                           ref={fileRef}
                           className="hidden"
                           accept="audio/*"
                           onChange={handleAudioFileChange}
                        />
                     </div>
                     <div className="col-span-5 bg-[#000] flex justify-center items-center p-4 w-full cursor-pointer rounded">
                        <p className="text-[12px] text-[#fff]">Find Video Voice</p>
                     </div>
                  </div>
               </div>
            )}
            {activeStep === 1 && (
               <div className="absolute bg-[#d9d9d9] mt-[3rem] p-4 rounded w-[85%] left-1/2 transform -translate-x-1/2">
                  <div className="grid grid-cols-6 gap-8 mb-4">
                     <div className="col-span-4 flex flex-col">
                        <p className="text-[13px] font-bold">Standard Quality</p>
                        <p className="text-[10px] font-bold">480p</p>
                     </div>
                     <div className="col-span-2 flex justify-end">
                        <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                           <input
                              type="checkbox"
                              name="autoSaver"
                              className="sr-only"
                              checked={isCheckedStandard}
                              onChange={handleCheckboxStandard}
                           />
                           <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedStandard ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                              <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedStandard ? "translate-x-5" : ""}`}></span>
                           </span>
                        </label>
                     </div>
                  </div>
                  <div className="grid grid-cols-6 gap-8">
                     <div className="col-span-4 flex flex-col">
                        <p className="text-[13px] font-semibold">HD Quality</p>
                        <p className="text-[10px] font-semibold">720p</p>
                     </div>
                     <div className="col-span-2 flex justify-end">
                        <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                           <input
                              type="checkbox"
                              name="autoSaver"
                              className="sr-only"
                              checked={isCheckedHD}
                              onChange={handleCheckboxHD}
                           />
                           <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedHD ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                              <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedHD ? "translate-x-5" : ""}`}></span>
                           </span>
                        </label>
                     </div>
                  </div>
               </div>
            )}
            {activeStep === 2 && (
               <div className="absolute bg-[#d9d9d9] w-[85%] mt-[3rem] left-1/2 transform -translate-x-1/2">
                  <textarea
                     rows="3"
                     maxLength={50}
                     onChange={handleChange}
                     placeholder="Type Something"
                     className="w-full bg-[#d9d9d9] px-[10px] py-[10px] focus:outline-none resize-none"
                  />
                  <p className="absolute right-2 text-gray-700 bg-[#d9d9d9] z-10 text-[14px] -mt-6">
                     {count}/50
                  </p>
               </div>
            )}
            {activeStep === 3 && (
               <div className="w-full h-[-webkit-fill-available] mt-4 overflow-scroll no-scrollbar">
                  <p className="px-3 font-bold mb-5 text-[20px]">Upload Settings</p>
                  <div className="flex justify-between px-3 mb-3">
                     <div className="flex items-center gap-2">
                        <FaLock className="text-[22px]" />
                        <p className="text-[13px] font-semibold">Private</p>
                     </div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isCheckedPrivate}
                           onChange={handleCheckboxPrivate}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedPrivate ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                           <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedPrivate ? "translate-x-5" : ""}`}></span>
                        </span>
                     </label>
                  </div>
                  <div className="flex justify-between px-3 mb-3">
                     <div className="flex items-center gap-2">
                        <RiAdvertisementFill className="text-[22px]" />
                        <p className="text-[13px] font-semibold">For Advertisement</p>
                     </div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isAdvert}
                           onChange={handleCheckboxAdvert}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isAdvert ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]  "}`}>
                           <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isAdvert ? "translate-x-5" : ""}`}></span>
                        </span>
                     </label>
                  </div>
                  {isAdvert && (
                     <div className="justify-between pl-5 mb-3 flex flex-col pr-10">
                        <div className="mt-4">
                           <Input
                              variant="static"
                              label="Title"
                              placeholder="Share your Title..."
                           />
                        </div>
                        <div className="mt-4">
                           <Input
                              variant="static"
                              label="Share Link"
                              placeholder="Share your Link"
                           />
                        </div>
                     </div>
                  )}

                  <div className="flex justify-between px-3 mb-3">
                     <div className="flex items-center gap-2">
                        <FaCommentDots className="text-[22px]" />
                        <p className="text-[13px] font-semibold">Comments</p>
                     </div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isCheckedComment}
                           onChange={handleCheckboxComment}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedComment ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                           <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedComment ? "translate-x-5" : ""}`}></span>
                        </span>
                     </label>
                  </div>
                  <div className="flex justify-between px-3 mb-3">
                     <div className="flex items-center gap-2">
                        <FaCreativeCommonsRemix className="text-[22px]" />
                        <p className="text-[13px] font-semibold">
                           Allow viewer to remix
                        </p>
                     </div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isCheckedRemix}
                           onChange={handleCheckboxRemix}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedRemix ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                           <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedRemix ? "translate-x-5" : ""}`}></span>
                        </span>
                     </label>
                  </div>
                  <div className="flex justify-between px-3 mb-3">
                     <div className="flex items-center gap-2">
                        <FaShareAltSquare className="text-[22px]" />
                        <p className="text-[13px] font-semibold">
                           Allow viewer to share video
                        </p>
                     </div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isCheckedShare}
                           onChange={handleCheckboxShare}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedShare ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                           <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedShare ? "translate-x-5" : ""}`}></span>
                        </span>
                     </label>
                  </div>
                  <div className="flex justify-between px-3 mb-3">
                     <div className="flex items-center gap-2">
                        <IoSave className="text-[22px]" />
                        <p className="text-[13px] font-semibold">
                           Allow viewer to save video
                        </p>
                     </div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isCheckedSave}
                           onChange={handleCheckboxSave}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedSave ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                           <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedSave ? "translate-x-5" : ""}`}></span>
                        </span>
                     </label>
                  </div>
                  <div className="flex justify-between px-3 mb-3">
                     <div className="flex items-center gap-2">
                        <MdDownloading className="text-[22px]" />
                        <p className="text-[13px] font-semibold">
                           Allow viewer to download video
                        </p>
                     </div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isCheckedDownload}
                           onChange={handleCheckboxDownload}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isCheckedDownload ? "bg-[#3ccc2f]" : "bg-[#b4b4b5]"}`}>
                           <span className={`dot h-[13px] w-[13px] rounded-full bg-[#fff] duration-200 ${isCheckedDownload ? "translate-x-5" : ""}`}></span>
                        </span>
                     </label>
                  </div>
               </div>
            )}
         </form>
         <div className="absolute bottom-0 w-full flex justify-between px-5 bg-[#000] h-[3rem] items-center">
            {!allStepsCompleted && (
               <button
                  className="rounded-full bg-[#151515] hover:bg-[#272727] text-[12px] font-bold px-10 h-7 text-[#fff]"
                  onClick={handleNext}
               >
                  Next
               </button>
            )}
            {activeStep === 3 && (
               <button
                  type="submit"
                  className="rounded-full text-[12px] px-10 font-bold h-7 bg-[#151515] hover:bg-[#272727] text-[#fff]"
                  onClick={longDurationVideo ? handleOpen : handleSubmit}
               >
                  Upload
                  <UploadVideoWaringModal
                     handleOpen={handleOpen}
                     open={open}
                     message={"Your video is longer than 1 min."}
                  />
               </button>
            )}
            <button
               className="rounded-full text-[12px] px-10 h-7 bg-[#ffffff] font-bold hover:bg-[#d3d3d3] text-[#000]"
               onClick={activeStep === 0 ? handleNavigate : handlePrev}
            >
               Cancel
            </button>
         </div>
         <div className="absolute bottom-12 w-full ">
         </div>
         <p className="text-[red] text-[12px] top-0 absolute text-center w-full">
            CC UPLOAD VIDEO PAGE, FE, V3, JULY 23, 2024
         </p>
      </div>
   );
};

export default Upload;
