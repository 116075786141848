import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { createPin } from "../../../Redux/ApiSlice/AccountPrivateSlice";
import { useDispatch, useSelector } from "react-redux";

export default function CreatePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState({ pin: "" });
  const  createPinStatus  = useSelector((state) => state.AccountPrivate);
  console.log(createPinStatus, "createPinStatus");
  const handleChange = (e) => {
    setPassword({ [e.target.name]: e.target.value });
  };

  const notify = (msg) => toast.error(msg);
  const SuccessNotify = () => toast.success("Succesfully Created the password");

  const submitPassword = async () => {
    const result = await dispatch(createPin(password));
  };

  useEffect(() => {
    return () => setPassword({ pin: "" });
  }, []);

  return (
    <div className=" relative h-full w-full flex justify-center items-center">
      <div className="absolute top-2 left-2">
        <IoCaretBackCircleSharp
          onClick={() => navigate(-1)}
          className="text-white hover:text-[#ffffffe3] cursor-pointer text-[1.5rem]"
        />
      </div>
      <form className="flex flex-col justify-center items-center">
        <img
          className="h-20 w-20"
          alt="password-icon"
          src="https://img.icons8.com/?size=160&id=91348&format=png"
        />
        <div class="w-full px-3 mt-2">
          <label
            for="grid-last-name"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
          >
            Enter Password
          </label>
          <input
            max={4}
            type="password"
            value={password.pin}
            id="grid-last-name"
            name="pin"
            onChange={handleChange}
            placeholder="Create 4 digit password"
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-md py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 placeholder:text-[14px]"
          />
        </div>
        <Button
          onClick={submitPassword}
          className="mt-3 bg-orange-800 hover:bg-orange-900 rounded-3xl"
        >
          <span>Confirm</span>
        </Button>
      </form>

      <Toaster position="top-right" />
    </div>
  );
}
