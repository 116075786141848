import React, { useEffect, useRef, useState } from "react";
import { TbDotsVertical } from "react-icons/tb";
import { SavedModal } from "../Modals/AccountPageModal/SavedTab";
import { useDispatch, useSelector } from "react-redux";
import ThreeDotsSpinner from "../../Common/ThreeDotsSpinner.jsx";
import { useNavigate } from "react-router-dom";
import FolderImage from "../../Assets/common/SavedFolder.png"
import NoSavedVideo from "../../Assets/YourAccountIcons/NoSavedVideo.png";
import { DeleteSavedFolderModal } from "../Modals/AccountPageModal/DeleteSavedFolderModal";
import { CreateSavedFolderModal } from "../Modals/AccountPageModal/CreateSavedFolderModal";
import { EditSavedFolderModal } from "../Modals/AccountPageModal/EditSavedFolderModal.jsx";
import { createFolder, deleteSavedFolders, getSavedFolders, renameSavedFolders } from "../../Redux/ApiSlice/SavedVideosSlice.js";

export function SavedTab() {
   const menuRef = useRef(null);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const savedVideo = useSelector((state) => state.savedVideos);
   const { loading, SavedFolderList } = savedVideo;
   const { User_profile } = ProfileDetails;
   const [savedId, setSavedId] = useState(null);
   const [selectFolderId, setSelectFolderId] = useState(null);
   const [deleteModal, setDeleteModal] = useState(false);
   const [EditModal, setEditModal] = useState(false);
   const [openCreateModal, setOpenCreateModal] = useState(false);
   const [folderName, setFolderName] = useState("");
   const [show, setShow] = useState(false);
   const [showtext, setsShowText] = useState("");

   const handleCreateModal = () => setOpenCreateModal(!openCreateModal);

   const handleOpenDeleteModal = (id) => {
      setDeleteModal(!deleteModal);
      setSelectFolderId(id);
   };

   const handleOpenEditModal = (id) => {
      setEditModal(!EditModal);
      setSelectFolderId(id);
   };

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setSavedId(null); // Close menu if clicked outside
      }
   };

   const handleOpen = (val) => {
      setShow(!show);
      setsShowText(val);
   };

   const handleCreateFolder = async (value) => {
      if (value) {
         await dispatch(createFolder({ name: value, userId: User_profile.id }));
         fetchData();
         handleCreateModal();
         setFolderName("");
      } else {
         return null;
      }
   };

   const fetchData = async () => {
      await dispatch(getSavedFolders(User_profile.id));
   };

   const deleteCreatedFolder = async (id) => {
      await dispatch(deleteSavedFolders(id));
      await dispatch(getSavedFolders(User_profile.id));
      handleOpenDeleteModal(null);
   };

   const renameFolderName = async (folderName) => {
      await dispatch(
         renameSavedFolders({ name: folderName, folderId: selectFolderId })
      );
      dispatch(getSavedFolders(User_profile.id));

      handleOpenEditModal(null);
   };

   const handleNavigate = (value) => {
      if (value) navigate(`/account/saved/folder/${value}`);
   };

   const selectedItem = SavedFolderList?.filter(
      (item) => item.id == selectFolderId
   );
   
   useEffect(() => {
      fetchData();
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [dispatch]);

   return (
      <>
         {loading && <ThreeDotsSpinner />}

         <div className="h-full overflow-hidden">
            <div className="flex justify-between">
               <p
                  onClick={handleCreateModal}
                  className="text-[12px] text-[#00A3FF] font-semibold cursor-pointer"
               >
                  Create Folder
               </p>
               <p className="text-[#383838] text-[10px]">
                  {SavedFolderList.length} Folders
               </p>
            </div>

            {SavedFolderList.length ? (
               <div className="h-[90%] md:h-[86%] overflow-scroll no-scrollbar">
                  <div className="grid grid-cols-6 gap-x-4">
                     {SavedFolderList?.map((item) => (
                        <div className="col-span-2 h-[7rem]" key={item.id}>
                           <div className="h-[5rem] relative ">
                              <img
                                 className="object-cover h-[5rem] cursor-pointer"
                                 src={FolderImage}
                                 onClick={() => handleNavigate(item.id)}
                              />
                              <TbDotsVertical
                                 className="absolute -right-[6px] top-2 font-bold cursor-pointer z-30"
                                 color="white"
                                 onClick={() => setSavedId(item.id)}
                              />
                              {savedId === item.id && (
                                 <div
                                    ref={menuRef}
                                    className="absolute text-[#000] font-bold bg-[#bebebe] px-2 py-1  text-[15px] right-2 top-6 z-50"
                                 >
                                    <p
                                       className="flex items-center text-[10px] text-black hover:text-red-800 cursor-pointer"
                                       onClick={() => handleOpenDeleteModal(item.id)}
                                    >
                                       Delete
                                    </p>
                                    <p
                                       className="flex items-center text-[10px] text-black hover:text-red-800 cursor-pointer"
                                       onClick={() => handleOpenEditModal(item.id)}
                                    >
                                       Rename
                                    </p>
                                 </div>
                              )}
                           </div>
                           <p className="text-[#fff] text-[10px] mt-[-5px] font-bold line-clamp-1">
                              {item.name}
                           </p>
                        </div>
                     ))}
                  </div>
               </div>
            ) : (
               <div className="flex justify-center items-center flex-col h-[80%] ">
                  <img src={NoSavedVideo} alt="" className="w-32 h-32" />
                  <p className="text-[10px] text-[#c0b8b8] font-semibold">
                     No saved video yet
                  </p>
               </div>
            )}

            <DeleteSavedFolderModal
               open={deleteModal}
               handleOpen={handleOpenDeleteModal}
               deleteItem={selectedItem}
               selectFolderId={selectFolderId}
               deleteCreatedFolder={deleteCreatedFolder}
            />

            <EditSavedFolderModal
               open={deleteModal}
               deleteItem={selectedItem}
               selectFolderId={selectFolderId}
               EditModal={EditModal}
               handleOpenEditModal={handleOpenEditModal}
               renameFolderName={renameFolderName}
            />

            <CreateSavedFolderModal
               open={openCreateModal}
               handleOpen={handleCreateModal}
               handleCreateFolder={handleCreateFolder}
               setFolderName={setFolderName}
               folderName={folderName}
            />

            <SavedModal 
               size={show} 
               handleOpen={handleOpen} 
               showtext={showtext}
            />
         </div>
      </>
   );
}
