import React, { useRef, useState } from "react";
import {
   Button,
   Dialog,
   DialogHeader,
   DialogFooter,
   Typography,
} from "@material-tailwind/react";
// import Cropper from "react-easy-crop";
import AccountProfileImageCrop from "./AccountProfileImageCrop";

const AccountProfileEditModal = ({
   theme,
   open,
   handleOpen,
   selectedImg,
   setSelectedImg,
   User_profile,
   handleSubmitProfileImage,
   dispatch,
   replacedURL,
}) => {
   // const [crop, setCrop] = useState({ x: 0, y: 0 });
   // const [zoom, setZoom] = useState(1);
   // const onCropComplete = (croppedArea, croppedAreaPixels) => {
   // };
   const [selected, setSelected] = useState(null);

   const fileRef = useRef(null);
   const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (!file) return;
      const fileUrl = URL.createObjectURL(file);

      // const tempImgs = JSON.parse(JSON.stringify());
      // tempImgs[selectedImg].src = fileUrl;
      setSelectedImg(fileUrl);
      setSelected(file);
   };

   return (
      <Dialog
         theme={
            window.innerWidth < 500
               ? theme.dialog.styles.sizes.sm
               : theme.dialog.styles.sizes.md
         }
         open={open}
         handler={handleOpen}
         className={`${selectedImg ? "h-[50%]" : ""} absolute top-[20%] border-0`}
      >
         {!selectedImg ? (
            <DialogHeader>
               <Typography className=" w-full rounded-md flex justify-center">
                  <img
                     alt="IMG"
                     src={`${User_profile.avatar}`}
                     className="h-[10rem] w-[10rem] rounded-[50%] object-cover"
                  />
               </Typography>
            </DialogHeader>
         ) : (
            <AccountProfileImageCrop
               selectedImg={selectedImg}
               handleOpen={handleOpen}
               selected={selected}
               handleSubmitProfileImage={handleSubmitProfileImage}
               dispatch={dispatch}
            />
         )}
         {!selectedImg && (
            <DialogFooter className="justify-around flex h-full">
               <Button
                  variant="text"
                  color="red"
                  className="bg-black w-[30%]  text-[#fff] p-2 cursor-pointer hover:bg-[#141414]"
                  onClick={() => {
                     fileRef.current?.click();
                  }}
               >
                  Upload
               </Button>

               <Button
                  variant="text"
                  color="red"
                  onClick={handleOpen}
                  className="bg-black w-[30%] p-2 text-[#fff] cursor-pointer hover:bg-[#e93f3f]"
               >
                  Cancel
               </Button>
            </DialogFooter>
         )}
         <div className="flex justify-center flex-col mt-4 items-center w-full">
            <h2 className="text-[red] font-bold text-sm text-center">
               CC CREATE VIDEO, FE, V1, JUNE 26, 2024
            </h2>
            <p className="text-[12px] font-bold text-[red]">
               Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
            </p>
         </div>
         <input
            type="file"
            ref={fileRef}
            className="hidden"
            accept="image/*"
            onChange={handleFileChange}
         />
      </Dialog>
   );
};

export default AccountProfileEditModal;
