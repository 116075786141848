import React from "react";

export default function SecretSauceButton() {
  return (
    <div className="souce-button h-[8%] relative bg-black flex justify-evenly items-center  border-b-2 border-t-2 ">
      <p className="absolute text-[red] text-[9px] top-0 font-bold">
        Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
      </p>
      <div className="flex justify-between px-3 items-center w-full h-2/4">
        <button className="h-full w-[40%] rounded-sm  text-[8px] md:text-[7px] bg-[yellow] font-extrabold px-1">SECRET SAUCE BUTTON 1</button>
        <button className="h-full w-[40%] rounded-sm  text-[8px]  md:text-[7px] bg-[yellow] font-extrabold px-1">SECRET SAUCE BUTTON 2</button>
      </div>
    </div>
  );
}
