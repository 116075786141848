import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Drawer,
  Card,
  Switch,
} from "@material-tailwind/react";
import {
  ShoppingBagIcon,
  UserCircleIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getPublicPrivateToggle,
  postPublicPrivateToggle,
} from "../../Redux/ApiSlice/UserStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { HiUserGroup } from "react-icons/hi";
import {
  MdNotificationsPaused,
  MdReport,
  MdRoomPreferences,
} from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import { IoSettings } from "react-icons/io5";
import { HiMiniClipboard } from "react-icons/hi2";

const AccountSidebar = () => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [toggleChecked, setToggleChecked] = useState({ is_private: false });
  const User_Status = useSelector((state) => state.UserPrivate_Status);
  const { User_PrivateStatus } = User_Status;
  const { user_data } = User_PrivateStatus;

  const navigate = useNavigate();
  const location = useLocation();

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const keepLogin = localStorage.getItem("keepMeLogin");
  const handleLogOut = () => {
    if (keepLogin) {
      localStorage.removeItem("security_login");
      localStorage.removeItem("accessToken");
    } else {
      localStorage.clear();
    }
    navigate("/");
  };

  const handleChangeToggle = async (e) => {
    setToggleChecked({ is_private: e.target.checked });
    await dispatch(postPublicPrivateToggle(toggleChecked));
    dispatch(getPublicPrivateToggle());
  };

  useEffect(() => {
    setToggleChecked({ is_private: user_data?.is_private });
  }, [dispatch]);
  return (
    <>
      <button onClick={openDrawer}>
        {isDrawerOpen ? (
          <XMarkIcon
            className={`h-6 w-6 stroke-2 ${location.pathname === "/wishlist" ? "text-[#000]" : "text-[#fff]"
              }`}
          />
        ) : (
          <Bars3Icon
            className={`h-6 w-6 stroke-2 ${location.pathname === "/wishlist" ? "text-[#000]" : "text-[#fff]"
              }`}
          />
        )}
      </button>
      <Drawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        placement="right"
        className="absolute overflow-hidden w-4/5"
        style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
      >
        <Card
          color="transparent"
          shadow={false}
          style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
        >
          <p className="text-center text-red-800 text-[12px] font-bold">
            CC ACCOUNT SIDEBAR PAGE , FE,V1 , JULY 02 , 2024
          </p>
          <List>
            <ListItem>
              <ListItemPrefix>
                <IoSettings className="h-5 w-5" />
              </ListItemPrefix>
              Settings
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <IoMdSave className="h-5 w-5" />
              </ListItemPrefix>
              Saved
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <RiGitRepositoryPrivateLine className="h-5 w-5" />
              </ListItemPrefix>
              Private
              <ListItemSuffix>
                <Switch
                  checked={toggleChecked.is_private}
                  onChange={handleChangeToggle}
                />
              </ListItemSuffix>
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <UserCircleIcon className="h-5 w-5" />
              </ListItemPrefix>
              Account
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <MdNotificationsPaused className="h-5 w-5" />
              </ListItemPrefix>
              Notification
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <MdRoomPreferences className="h-5 w-5" />
              </ListItemPrefix>
              Content Preferences
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <HiUserGroup className="h-5 w-5" />
              </ListItemPrefix>
              Group
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <ShoppingBagIcon className="h-5 w-5" />
              </ListItemPrefix>
              Shop
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <HiMiniClipboard className="h-5 w-5" />
              </ListItemPrefix>
              Tearms & Conditions
            </ListItem>
            <ListItem>
              <ListItemPrefix>
                <MdReport className="h-5 w-5" />
              </ListItemPrefix>
              Report
            </ListItem>
            <ListItem onClick={handleLogOut}>
              <ListItemPrefix>
                <PowerIcon className="h-5 w-5" />
              </ListItemPrefix>
              Log Out
            </ListItem>
          </List>
        </Card>
      </Drawer>
    </>
  );
};

export default AccountSidebar;
