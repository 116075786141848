import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { IconButton, Drawer, Card } from "@material-tailwind/react";

const SearchSideBar = () => {
   const [open, setOpen] = useState(0);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const navigate = useNavigate();

   const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
   };

   const openDrawer = () => setIsDrawerOpen(true);
   const closeDrawer = () => setIsDrawerOpen(false);

   return (
      <>
         <div className="flex items-center w-full justify-between px-2 -mt-1">
            <button>
               <IoArrowBackCircleSharp
                  color="white"
                  onClick={() => navigate(-1)}
                  className="h-[1.4rem] w-[1.4rem] z-30"
               />
            </button>
            <IconButton variant="text" size="sm" onClick={openDrawer}>
               {isDrawerOpen ? (
                  <XMarkIcon className="h-6 w-6 stroke-2 text-[#fff] " />
               ) : (
                  <Bars3Icon className="h-6 w-6 stroke-2 text-[#fff] " />
               )}
            </IconButton>
         </div>
         <div className="bg-gray-800 w-full h-[0.5px]"></div>
         <Drawer
            open={isDrawerOpen}
            onClose={closeDrawer}
            placement="right"
            className="absolute overflow-y-scroll w-[70%]"
         >
            <Card
               color="transparent"
               shadow={false}
               className="overflow-y-scroll no-scrollbar rounded-none"
            >
               <div className="pt-8">
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 01</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 02</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 03</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 04</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 05</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 06</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 07</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 08</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 09</p>
                  </div>
                  <div className="px-3 py-2 flex justify-start items-center gap-[6px] transition ease-in-out border-b border-gray-300 hover:scale-105 duration-300 hover:bg-[#d9d9d9] hover:text-black">
                     <IoIosArrowForward className="cursor-pointer" />
                     <p className="cursor-pointer">Menu 10</p>
                  </div>
               </div>
            </Card>

            <p className="text-[red] text-[9px] font-bold absolute top-1 w-full text-center">
               CC SEARCH SIDEBAR, FE, V3, JUNE 08, 2024
            </p>
         </Drawer>
      </>
   );
};

export default SearchSideBar;
