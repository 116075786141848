import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const followsuggestions = createAsyncThunk(
  "followsuggestions",
  async (id) => {
    const response = await axiosInstance.get(`/follow_suggestions/`);
    try {
      const result = response.data;
      return result;
    } catch (error) {
      return error;
    }
  }
);

export const FollowsuggestionsSlice = createSlice({
  name: "FollowsuggestionsSlice",
  initialState: {
    follow_suggestions: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // account page followsuggestions action promise
    builder.addCase(followsuggestions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(followsuggestions.fulfilled, (state, action) => {
      state.loading = false;
      state.User_likedVideos = action.payload;
    });
    builder.addCase(followsuggestions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});



export default FollowsuggestionsSlice.reducer;
