import React, { useState } from "react";
import { dummyComment } from "../../Datas/Datas";
import { useNavigate } from "react-router-dom"

const InviteMembers = () => {
   const navigate = useNavigate()
   const [selectAllChecked, setSelectAllChecked] = useState(false);
   const [individualChecked, setIndividualChecked] = useState({});

   const handleSelectAllChange = (event) => {
      const { checked } = event.target;
      setSelectAllChecked(checked);
      const updatedIndividualChecked = {};
      dummyComment.forEach((data) => {
         updatedIndividualChecked[data.id] = checked;
      });
      setIndividualChecked(updatedIndividualChecked);
   };

   const handleIndividualChange = (event, id) => {
      const { checked } = event.target;
      setIndividualChecked((prevState) => ({
         ...prevState,
         [id]: checked,
      }));
   };

   return (
      <div className="px-3">
         <p className="text-white text-center text-[18px] font-semibold">
            Invite Members
         </p>
         <form className="mt-2">
            <input
               type="text"
               placeholder="Search members"
               className="focus:outline-none bg-[#D9D9D9] rounded-2xl py-2 px-[14px] md:py-1 md:px-[12px] w-full"
            />
            <div className="flex justify-end items-center mt-[2px] gap-1">
               <p className="text-[#00A3FF] text-[12px]">Select all</p>
               <div class="inline-flex items-center">
                  <label
                     class="relative flex items-center p-3 rounded-full cursor-pointer"
                     htmlFor="check"
                  >
                     <input
                        type="checkbox"
                        onChange={handleSelectAllChange}
                        checked={selectAllChecked}
                        class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-[#fff] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-100 before:opacity-0 before:transition-opacity checked:border-white checked:bg-white checked:before:bg-white"
                        id="check"
                     />
                     <span class="absolute text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-3.5 w-3.5"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           stroke="currentColor"
                           strokeWidth="1"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                           ></path>
                        </svg>
                     </span>
                  </label>
               </div>
            </div>
            {dummyComment.map((data) => (
               <div className="mt-1" key={data.id}>
                  <div className="flex justify-between items-center">
                     <div className="flex items-center gap-3">
                        <img
                           src={data.profileurl}
                           alt="img"
                           className="h-8 w-8 rounded-full object-cover"
                        />
                        <p className="text-[#fff] text-[13px]">{data.name}</p>
                     </div>
                     <div class="inline-flex items-center">
                        <label
                           class="relative flex items-center p-3 rounded-full cursor-pointer"
                           htmlFor={`check-${data.id}`}
                        >
                           <input
                              type="checkbox"
                              class="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-white transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-100 before:opacity-0 before:transition-opacity checked:border-white checked:bg-white checked:before:bg-white"
                              id={`check-${data.id}`}
                              checked={individualChecked[data.id] || false}
                              onChange={(e) => handleIndividualChange(e, data.id)}
                           />
                           <span class="absolute text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 class="h-3.5 w-3.5"
                                 viewBox="0 0 20 20"
                                 fill="currentColor"
                                 stroke="currentColor"
                                 strokeWidth="1"
                              >
                                 <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                 ></path>
                              </svg>
                           </span>
                        </label>
                     </div>
                  </div>
               </div>
            ))}

            <div className="flex justify-between mt-16 pb-16 md:mt-6 md:pb-4">
               <button
                  className="bg-[#D9D9D9] text-black rounded-full text-[14px] hover:font-bold py-[5px] w-[30%] cursor-pointer"
                  onClick={() => navigate("/account/updates")}
               >
                  Skip
               </button>
               <button className="bg-[#D9D9D9] text-black rounded-full text-[14px] hover:font-bold py-[5px] w-[30%] cursor-pointer">
                  Invite
               </button>
            </div>
         </form>
      </div>
   );
};

export default InviteMembers;
