import React from "react";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleLeft, FaSearch } from "react-icons/fa";

export const TrendingTitle = ({ title, searchColor }) => {
   const navigate = useNavigate();
   const responsive = {
      desktop: {
         breakpoint: { max: 3000, min: 1024 },
         items: 3.5,
         slidesToSlide: 1,
      },
      tablet: {
         breakpoint: { max: 1024, min: 464 },
         items: 2,
         slidesToSlide: 1,
      },
      mobile: {
         breakpoint: { max: 464, min: 0 },
         items: 2.5,
         slidesToSlide: 1,
      },
   };

   return (
      <div className="pt-4">
         <div className="grid grid-cols-8 relative px-2">
            <div className="col-span-12 flex items-center justify-start text-[#fff]">
               <FaArrowAltCircleLeft
                  onClick={() => navigate(-1)}
                  className="text-white text-[18px] cursor-pointer"
               />
            </div>
         </div>
         <div className="w-full flex justify-center flex-col mt-2 px-2">
            <div className="w-full grid grid-cols-12 gap-2 ">
               <button className="col-span-4 w-full h-auto bg-[#191919] hover:bg-[#2b2b2b] text-white rounded-full text-[12px] font-bold cursor-pointer">
                  #{title}
               </button>
               <div className="col-span-8">
                  <div className="relative">
                     <FaSearch className="absolute left-[6px] top-1/2 transform -translate-y-1/2 cursor-pointer" />
                     <input
                        type="text"
                        placeholder="Search here"
                        className="w-full outline-none rounded-full h-full py-[5px] pl-7 pr-2"
                     />
                  </div>
               </div>
            </div>

            <Carousel
               swipeable={true}
               draggable={true}
               showDots={false}
               responsive={responsive}
               ssr={true}
               infinite={false}
               arrows={false}
               renderButtonGroupOutside={true}
               renderDotsOutside={false}
               className="w-full mt-2"
            >
               <div
                  className="h-6 mr-1 text-[10px] text-center bg-[#191919] hover:bg-[#2b2b2b] text-white rounded-full font-light flex items-center justify-center p-1 cursor-pointer"
                  onClick={() => navigate("/hashtag/store")}
               >
                  Store
               </div>
               <div
                  className="h-6 mr-1 text-[10px] text-center bg-[#191919] hover:bg-[#2b2b2b] text-white rounded-full font-light flex items-center justify-center p-1 cursor-pointer"
                  onClick={() => navigate("/hashtag/trending")}
               >
                  Trending
               </div>
               <div
                  className="h-6 mr-1 text-[10px] text-center bg-[#191919] hover:bg-[#2b2b2b] text-white rounded-full font-light flex items-center justify-center p-1 cursor-pointer"
                  onClick={() => navigate("/hashtag/otheruser")}
               >
                  Other Users
               </div>
               <div
                  className="h-6 mr-1 text-[10px] text-center bg-[#191919] hover:bg-[#2b2b2b] text-white rounded-full font-light flex items-center justify-center p-1 cursor-pointer"
                  onClick={() => navigate("/hashtag/popular")}
               >
                  Popular
               </div>
               <div
                  className="h-6 mr-1 text-[10px] text-center bg-[#191919] hover:bg-[#2b2b2b] text-white rounded-full font-light flex items-center justify-center p-1 cursor-pointer"
                  onClick={() => navigate("/hashtag/recent")}
               >
                  Recent
               </div>
            </Carousel>
         </div>
         <div className="mt-[6px] h-[0.3px] bg-[#3a3a3a] w-full" />
      </div>
   );
};
