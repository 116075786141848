import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from "@material-tailwind/react";

export const CreateSavedFolderModal = ({ handleOpen, open, handleCreateFolder, folderName, setFolderName }) => {
   const handleChange = (e) => {
      setFolderName(e.target.value);
   };

   return (
      <Dialog
         open={open}
         handler={handleOpen}
         className="rounded-none"
         size="sm"
         style={{ width: "100px" }}
      >
         <DialogHeader className="flex justify-center items-center text-xl font-bold ">
            Create Folder
         </DialogHeader>
         
         <DialogBody className="flex flex-col items-start justify-start">
            <Typography className="text-center text-base font-semibold">
               Folder Name
            </Typography>
            <Typography className="w-full mt-1">
               <input
                  placeholder=""
                  className="rounded-none outline-none bg-[#D9D9D9] p-2 w-full"
                  onChange={handleChange}
                  value={folderName}
               />
            </Typography>
         </DialogBody>

         <DialogFooter className="w-full flex justify-center">
            <Button
               variant="text"
               onClick={() => handleCreateFolder(folderName)}
               className="mr-8 bg-black text-[#fff] "
            >
               <span>Save</span>
            </Button>
            <Button
               variant="gradient"
               className=" bg-black text-[#fff] "
               onClick={() => handleOpen(null)}
            >
               <span>Cancel</span>
            </Button>
         </DialogFooter>
      </Dialog>
   );
};
