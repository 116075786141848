import React, { Fragment } from "react"
import WhatsNew from "../../Component/settingspage/WhatsNew"

const WhatsNewPage = () => {
   return (
      <Fragment>
         <p className="absolute top-1 text-red-700 uppercase font-bold w-full text-[10px] text-center">
            cc settings what's new / fe / v2 / july 03, 2024
         </p>
         <div className="pt-1">
            <WhatsNew />
         </div>
      </Fragment>
   )
}

export default WhatsNewPage