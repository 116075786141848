import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { orderedProducts } from "../../Datas/Datas";
import { OrderCancelConfirmationModal } from "../Modals/ShopPageModal/OrderCancelConfirmationModal";

const Order = () => {
   const navigate = useNavigate();
   const [showCancelModal, SetShowCancelModal] = useState(false);
   const handleShowModal = () => SetShowCancelModal(!showCancelModal);
   
   return (
      <div className="px-2">
         <p className="text-center text-[#000] font-bold text-[24px] mb-3">
            Orders
         </p>
         <div className="flex justify-between items-center mb-3">
            <p className="text-[12px] font-semibold">Purchased on Feb 21, 2024</p>
            <div className="text-[#000] ">
               <Button
                  className="bg-green-300 text-[10px] text-[#000] px-2 py-1 shadow-none"
                  onClick={() => navigate("/orderdetails")}
               >
                  Track All Orders
               </Button>
            </div>
         </div>
         <div className="grid grid-cols-2 gap-[6px]">
            {orderedProducts.map((data) => (
               <div className="mb-5" key={data.id}>
                  <img
                     src={data.imgUrl}
                     alt={data.name}
                     onClick={() => navigate("/orderdetails")}
                     className="img-shadow rounded-2xl h-[10rem] w-full object-cover bg-[#fff]"
                  />
                  <div className="text-[#000] mt-2 px-1 text-start">
                     <p className="line-clamp-1 text-[13px] font-semibold">
                        {data.name}
                     </p>
                     <p className="line-clamp-2 leading-3 text-[12px]">{data.description}</p>
                     <p className="text-[10px] text-gray-700 font-bold py-[2px]">
                        <span className="font-bold text-black">Ordered Date: </span> 
                        {data.orderDate}
                     </p>

                     <p className="text-[10px] font-bold text-yellow-800">{data.reviews} Total Reviews</p>

                     <p className="text-[10px] leading-3 font-bold text-green-700">
                        <p className="font-bold text-black">Expected Delivery:</p> 
                        {data.expectedDeliverDate}
                     </p>
                     
                     <p className="line-clamp-1 text-[10px] py-[2px] font-bold">Ordered Quantity: {data.qty}</p>
                     
                     <p className="text-[10px] leading-3 font-bold">Manufacturer: <p className="font-normal">{data.manufacturer}</p></p>
                     
                     <div className="flex justify-between gap-4 items-center mt-[5px]">
                        <Button
                           className="bg-gray-300 text-[9px] text-[#000] px-2 py-1 shadow-none"
                           onClick={() => navigate("/orderdetails")}
                        >
                           Track
                        </Button>
                        <Button
                           className="bg-gray-300 w-full text-[9px] text-[#000] px-1 py-1 shadow-none"
                           onClick={() => handleShowModal()}
                        >
                           Cancel
                        </Button>
                     </div>
                  </div>
               </div>
            ))}
         </div>

         <OrderCancelConfirmationModal
            open={showCancelModal}
            handleOpen={handleShowModal}
         />
      </div>
   );
};

export default Order;
