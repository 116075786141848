import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const getNotification = createAsyncThunk(
  "getNotification",
  async (id) => {
    try {
      const response = await axiosInstance.get(`/notifications/`);
      const result = response.data;
      return result;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const postRead = createAsyncThunk("postRead", async (id) => {
  try {
    const response = await axiosInstance.post(`/notifications/${id}`);
    const result = response.data;
    return result;
  } catch (error) {
    return error.response.data;
  }
});

export const NotificationSlice = createSlice({
  name: "Notification",
  initialState: {
    notificationList: [],
    message: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // getNotificationlist action promises
    builder.addCase(getNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotification.fulfilled, (state, action) => {
      state.notificationList = action.payload;
      state.loading = false;
    });
    builder.addCase(getNotification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(postRead.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postRead.fulfilled, (state, action) => {
      state.message = action.payload;
      state.loading = false;
    });
    builder.addCase(postRead.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default NotificationSlice.reducer;
