import React, { useState } from "react";
import { Button } from "@material-tailwind/react";

export default function OtherUserPostReport() {
   const [count, setCount] = useState(0);

   const handleChange = (event) => {
      const inputText = event.target.value;
      if (inputText.length <= 150) {
         setCount(inputText.length);
      }
   };

   return (
      <div className="bg-[#000] overflow-hidden h-full px-3 pt-2">
         <p className="w-full text-center text-[8px] -mt-[5px] text-red-900 font-bold">
            CC OTHER USER GROUP POST REPORT PAGE / FE / V2 / JULY 06, 2024
         </p>
         <div className="grid grid-cols-12 pt-1 pb-2">
            <div className="col-span-12 mb-3">
               <h2 className="font-bold text-white text-[24px] md:text-[20px] text-center underline underline-offset-[5px]">
                  Report Page
               </h2>
            </div>
            <div className="col-span-2 mb-2">
               <p className="text-white text-[18px] md:text-[13px]">User:</p>
            </div>
            <div className="col-span-9 mb-2">
               <div className="flex gap-3">
                  <p className="text-white text-[18px] md:text-[13px] font-bold">John Smith</p>
               </div>
            </div>
            <div className="col-span-2">
               <p className="text-white text-[18px] md:text-[13px]">Post:</p>
            </div>
            <div className="col-span-9">
               <img
                  alt="postimg"
                  className="w-full object-cover mt-2 rounded-[10px]"
                  src="https://images.unsplash.com/photo-1632441730372-d8509de481d1?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
               />
            </div>
         </div>
         <div className="grid grid-cols-12 pt-3 pb-2">
            <div className="col-span-12 mb-[5px]">
               <p className="text-white text-[14px] font-bold underline underline-offset-[5px]">
                  Reason For Report
               </p>
            </div>
            <div className="col-span-6 py-2 flex items-center gap-2">
               <div class="inline-flex items-center">
                  <label
                     class="relative flex items-center p-0 rounded-full cursor-pointer"
                     htmlFor="vertical-list-react"
                  >
                     <input
                        gap-3
                        id="vertical-list-react"
                        type="checkbox"
                        class="before:content[''] peer relative h-[20px] md:h-[17px] w-[20px] md:w-[17px] cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-[#9d00ff] checked:before:bg-[#9d00ff]  hover:before:opacity-0"
                     />
                     <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-[15px] md:h-[10px] w-[15px] md:w-[10px]"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           stroke="currentColor"
                           stroke-width="1"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                           ></path>
                        </svg>
                     </span>
                  </label>
               </div>

               <label className="text-white text-[15px] md:text-[13px]">Break Rules</label>
            </div>
            <div className="col-span-6 py-[6px] flex items-center gap-2">
               <div class="inline-flex items-center">
                  <label
                     class="relative flex items-center p-0 rounded-full cursor-pointer"
                     htmlFor="vertical-list-react"
                  >
                     <input
                        gap-3
                        id="vertical-list-react"
                        type="checkbox"
                        class="before:content[''] peer relative h-[20px] md:h-[17px] w-[20px] md:w-[17px] cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-[#9d00ff] checked:before:bg-[#9d00ff]  hover:before:opacity-0"
                     />
                     <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-[15px] md:h-[10px] w-[15px] md:w-[10px]"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           stroke="currentColor"
                           stroke-width="1"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                           ></path>
                        </svg>
                     </span>
                  </label>
               </div>
               <label className="text-white text-[15px] md:text-[13px]">Harassment</label>
            </div>
            <div className="col-span-6 py-[6px] flex items-center gap-2">
               <div class="inline-flex items-center">
                  <label
                     class="relative flex items-center p-0 rounded-full cursor-pointer"
                     htmlFor="vertical-list-react"
                  >
                     <input
                        gap-3
                        id="vertical-list-react"
                        type="checkbox"
                        class="before:content[''] peer relative h-[20px] md:h-[17px] w-[20px] md:w-[17px] cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-[#9d00ff] checked:before:bg-[#9d00ff]  hover:before:opacity-0"
                     />
                     <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-[15px] md:h-[10px] w-[15px] md:w-[10px]"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           stroke="currentColor"
                           stroke-width="1"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                           ></path>
                        </svg>
                     </span>
                  </label>
               </div>
               <label className="text-white text-[15px] md:text-[13px]">Hate</label>
            </div>
            <div className="col-span-6 py-[6px] flex items-center gap-2">
               <div class="inline-flex items-center">
                  <label
                     class="relative flex items-center p-0 rounded-full cursor-pointer"
                     htmlFor="vertical-list-react"
                  >
                     <input
                        gap-3
                        id="vertical-list-react"
                        type="checkbox"
                        class="before:content[''] peer relative h-[20px] md:h-[17px] w-[20px] md:w-[17px] cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-[#9d00ff] checked:before:bg-[#9d00ff]  hover:before:opacity-0"
                     />
                     <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-[15px] md:h-[10px] w-[15px] md:w-[10px]"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           stroke="currentColor"
                           stroke-width="1"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                           ></path>
                        </svg>
                     </span>
                  </label>
               </div>
               <label className="text-white text-[15px] md:text-[13px]">Violence</label>
            </div>
            <div className="col-span-6 py-[6px] flex items-center gap-2">
               <div class="inline-flex items-center">
                  <label
                     class="relative flex items-center p-0 rounded-full cursor-pointer"
                     htmlFor="vertical-list-react"
                  >
                     <input
                        gap-3
                        id="vertical-list-react"
                        type="checkbox"
                        class="before:content[''] peer relative h-[20px] md:h-[17px] w-[20px] md:w-[17px] cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-[#9d00ff] checked:before:bg-[#9d00ff]  hover:before:opacity-0"
                     />
                     <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-[15px] md:h-[10px] w-[15px] md:w-[10px]"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           stroke="currentColor"
                           stroke-width="1"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                           ></path>
                        </svg>
                     </span>
                  </label>
               </div>
               <label className="text-white text-[15px] md:text-[13px]">Abuse</label>
            </div>
            <div className="col-span-6 py-[6px] flex items-center gap-2">
               <div class="inline-flex items-center">
                  <label
                     class="relative flex items-center p-0 rounded-full cursor-pointer"
                     htmlFor="vertical-list-react"
                  >
                     <input
                        gap-3
                        id="vertical-list-react"
                        type="checkbox"
                        class="before:content[''] peer relative h-[20px] md:h-[17px] w-[20px] md:w-[17px] cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-[#9d00ff] checked:before:bg-[#9d00ff]  hover:before:opacity-0"
                     />
                     <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           class="h-[15px] md:h-[10px] w-[15px] md:w-[10px]"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           stroke="currentColor"
                           stroke-width="1"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                           ></path>
                        </svg>
                     </span>
                  </label>
               </div>
               <label className="text-white text-[15px] md:text-[13px]">Spam</label>
            </div>

            <div className="relative col-span-12 md:mt-4 mt-6 lg:mt-4 xl:mt-4 h-[100px] bg-[#242424] text-white rounded-[10px]">
               <textarea
                  className="w-full h-[80px] bg-[#242424] text-white p-2 rounded-[10px] focus:outline-none resize-none "
                  placeholder="Write your reason here"
                  maxLength={150}
                  rows="4"
                  onChange={handleChange}
               ></textarea>
               <p className="absolute bottom-1 right-2 text-gray-500 text-[14px] -mt-6">
                  {count}/150
               </p>
            </div>
            <div className="col-span-12 flex justify-center items-center mt-2">
               <button className="py-3 md:py-2 px-5 md:px-3 text-[18px] bg-red-600 text-white rounded">
                  Submit
               </button>
            </div>
         </div>
      </div>
   );
}
