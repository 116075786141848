import React, { useState } from "react";
import { CiStreamOn } from "react-icons/ci";
import { GiThreeFriends } from "react-icons/gi";
import { FaBasketShopping } from "react-icons/fa6";
import VideoCardIconList from "./VideoCardIconList";
import Post from "../../../Component/videopage/Post";
import { MdMessage, MdOutlineFeed } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SecretSauceButton from "../SecretSauceButton/SecretSauceButton";
import { VideoCardFooterIcons } from "../../../Datas/Datas";
import ProfileAccountModal from "../../../Component/Modals/HomePageModal/ProfileAccountModal";
// import { FaLaptopHouse } from "react-icons/fa";

export default function MobileLayout({ children }) {
  const [openBottom, setOpenBottom] = useState(false);
  const openDrawerBottom = () => setOpenBottom(true);
  const closeDrawerBottom = () => setOpenBottom(false);
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const { id } = param;
  const [openAccountBottom, setOpenAccountBottom] = useState(false);

  const openDrawerAccountModal = () => setOpenAccountBottom(true);
  const CloseDrawerAccountModal = () => setOpenAccountBottom(false);

  const handleCheckPath = () => {
    switch (location.pathname) {
      case "/home":
        return true;
      default:
        return false;
    }
  };
  const reloadPage = () => {
    navigate("/home");
  };

  const handleNavigate = (value) => {
    const replaceValue = value.replaceAll(" ", "");
    if (value === "Messages") {
      navigate(`/mobileMessages`);
    } else if (replaceValue === "YourFeed") {
      return reloadPage();
    } else if (replaceValue === "Home") {
      return reloadPage();
    } else if (replaceValue === "YourFriends") {
      return reloadPage();
    } else if (replaceValue === "Createvideo") {
      return openDrawerBottom();
    } else if (replaceValue === "YourCC") {
      return navigate(`/commingsoon`);
    } else if (replaceValue === "Helmet1") {
      return navigate(`/commingsoon`);
    } else if (replaceValue === "Account") {
      return navigate(`/Account`);
      //  openDrawerAccountModal();
    } else {
      navigate(`/${replaceValue}`);
    }
  };

  const handleCheckPathName = () => {
    switch (location.pathname) {
      case "/entrypage":
        return false;
      case "/uploadvideo":
        return false;
      case "/login":
        return false;
      case "/signup":
        return false;
      case "/register":
        return false;
      case "/resetpassword":
        return false;
      case "/changepassword":
        return false;
      case "/":
        return false;
      case "/help":
        return false;
      case `/get-questions/${id}`:
        return false;
      case "/readmore-answer":
        return false;
      case "/enterotp":
        return false;
      default:
        return true;
    }
  };

  const HeaderIcons = [
    {
      icon: (
        <FaBasketShopping
          color={`${
            location.pathname === "/mobileMessages" ||
            location.pathname === "/searchresultpage"
              ? "white"
              : "black"
          }`}
          className="h-[20px] w-[20px]"
        />
      ),
      placeholder: "Shop",
    },
    {
      icon: (
        <MdMessage
          color={`${
            location.pathname === "/mobileMessages" ||
            location.pathname === "/searchresultpage"
              ? "white"
              : "black"
          }`}
          className="h-[20px] w-[20px]"
        />
      ),
      placeholder: "Messages",
    },
    {
      icon: (
        <MdOutlineFeed
          color={`${
            location.pathname === "/mobileMessages" ||
            location.pathname === "/searchresultpage"
              ? "white"
              : "black"
          }`}
          className="h-[20px] w-[20px]"
        />
      ),
      placeholder: "Your Feed",
    },

    {
      icon: (
        <GiThreeFriends
          color={`${
            location.pathname === "/mobileMessages" ||
            location.pathname === "/searchresultpage"
              ? "white"
              : "black"
          }`}
          className="h-[20px] w-[20px]"
        />
      ),
      placeholder: "Your Friends",
    },
    {
      icon: <CiStreamOn color="green" className="h-[20px] w-[20px]" />,
      placeholder: "Live Stream",
    },
  ];

  return (
    <div className="video-card-container relative overflow-hidden">
      {handleCheckPath() ? (
        <div className="video-card-header top-8 md:top-4">
          <div className="h-[3%] absolute z-50 w-full flex justify-between px-2">
            {HeaderIcons.map((item) => (
              <div
                className="flex flex-col justify-center items-center"
                onClick={() => handleNavigate(`${item.placeholder}`)}
              >
                <div className="cursor-pointer">{item.icon} </div>
                <p
                  className={`${
                    location.pathname === "/mobileMessages" ||
                    location.pathname === "/searchresultpage"
                      ? "text-[#fff]"
                      : "text-[#000]"
                  } cursor-pointer text-[7px] mt-[2px]`}
                >
                  {item.placeholder}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className={`video-section ${
          handleCheckPathName() ? " h-[85%] md:h-[86%]" : "h-[100%]"
        }  overflow-y-scroll overflow-hidden overflow-x-hidden no-scrollbar relative`}
      >
        {children}
      </div>
      <Post
        openBottom={openBottom}
        openDrawerBottom={openDrawerBottom}
        closeDrawerBottom={closeDrawerBottom}
      />
      {handleCheckPathName() && (
        <>
          <SecretSauceButton />
          <div className="h-[8%] md:h-auto">
            <VideoCardIconList
              VideoCardHeaderIcons={VideoCardFooterIcons}
              isfooter={true}
              handleNavigate={handleNavigate}
            />
          </div>
        </>
      )}
      <ProfileAccountModal
        openBottom={openAccountBottom}
        closeDrawerBottom={CloseDrawerAccountModal}
      />
    </div>
  );
}
