import React, { useEffect, useRef } from "react";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import Carousel from "react-multi-carousel";
import HomeVideoView from "./HomeVideoView";
import { useNavigate } from "react-router-dom";

const VideoCarousel = ({
  videos,
  setPreButton,
  setNextButton,
  spilThashTags,
  summary,
  fetchData,
  id,
}) => {
  const carouselRef = useRef(null);
  const navigate = useNavigate();
  const navigateToVideo = (newIndex) => {
    const videoId = videos[newIndex]?.id;
    if (videoId) {
      navigate(`/watchvideo/${videoId}`);
    }
  };

  useEffect(() => {
    const selectedIndex = videos.findIndex((video) => video.id === id);
    if (selectedIndex !== -1 && carouselRef.current) {
      carouselRef.current.goToSlide(selectedIndex);
    }
  }, [id, videos]);

  const customPreviousButton = () => {
    const handlePrevious = () => {
      if (carouselRef.current) {
        if (id) {
          const currentIndex = carouselRef.current.state.currentSlide;
          const newIndex =
            currentIndex - 1 >= 0 ? currentIndex - 1 : videos.length - 1; // Loop back to the last video if at the beginning
          navigateToVideo(newIndex);
        }
        carouselRef.current.previous();
      }
    };

    return (
      <button
        onClick={handlePrevious}
        className="w-20 top-2/4  h-16 bg-blue-gray-200 hover:bg-[#b3acac] rounded-full  items-center text-center justify-center flex"
      >
        <BiSolidLeftArrow />
      </button>
    );
  };

  const customNextButton = () => {
    const handleNext = () => {
      if (carouselRef.current) {
        if (id) {
          const currentIndex = carouselRef.current.state.currentSlide;
          const newIndex = (currentIndex + 1) % videos.length; // Loop back to the first video if at the end
          navigateToVideo(newIndex);
        }
        carouselRef.current.next();
      }
    };

    return (
      <>
        <button
          className=" w-20 top-2/4  h-16 right-0 bg-blue-gray-200 hover:bg-[#b3acac] rounded-full  items-center text-center justify-center flex"
          onClick={handleNext}
        >
          <BiSolidRightArrow />
        </button>
      </>
    );
  };

  useEffect(() => {
    setPreButton(customPreviousButton);
    setNextButton(customNextButton);
  }, []);
  const data = videos[0];

  return (
    <>
      <Carousel
        ref={carouselRef}
        additionalTransfrom={0}
        arrows={false}
        autoPlay={false}
        centerMode={false}
        className="h-full"
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={true}
        renderDotsOutside={false}
        customButtonGroup={<customNextButton />}
        responsive={{
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {videos?.map((data) => {
          return (
            <HomeVideoView
              id={data?.id}
              src={data?.video_url}
              summary={summary}
              comments={data?.comments}
              spilThashTags={spilThashTags}
              created_by={data?.created_by}
              dec={data?.body}
              like_count={data?.likes_count}
              comments_count={data?.comments_count}
              fetchData={fetchData}
            />
          );
        })}
      </Carousel>
    </>
  );
};

export default VideoCarousel;
