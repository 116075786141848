import React from "react";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { IoFilter } from "react-icons/io5";

const InputButton = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="px-4 grid grid-cols-10 mt-4 mb-8  md:mb-4">
        <div className="col-span-8">
          <input
            placeholder="search product"
            className="bg-[#fff] rounded-3xl text-[#000] w-full py-1 px-4 focus:outline-none"
          />
        </div>
        <div className="col-span-2 flex justify-end ">
          <IoFilter className="text-[#fff] text-2xl" />
        </div>
      </div>
      <div className="grid grid-cols-4 mt-1 px-4 gap-2">
        <Button className="lowercase col-span-1 bg-[#fff] text-[#000] text-[12px] px-5 md:px-3 py-2 rounded-2xl">
          Popular
        </Button>
        <Button className="lowercase col-span-1 bg-[#fff] text-[#000] text-[12px] px-5 md:px-3 py-2 rounded-2xl">
          Trending
        </Button>
        <Button className="lowercase col-span-1 bg-[#fff] text-[#000] text-[12px] px-5 md:px-3 py-2 rounded-2xl">
          Discount
        </Button>
        <Button
          className="lowercase col-span-1 bg-[#fff] text-[#000] text-[12px] px-5 md:px-3 py-2 rounded-2xl"
          onClick={() => navigate("/order")}
        >
          Order
        </Button>
      </div>
    </div>
  );
};

export default InputButton;
