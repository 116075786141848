import React from "react";
import { groupSearchUser } from "../../../Datas/Datas";

const User = () => {
   return (
      <div className="overflow-y-scroll">
         {groupSearchUser.map((data) => (
            <div className="grid grid-cols-12 items-center border-b border-gray-800 py-2 overflow-y-scroll" key={data.id}>
               <div className="col-span-7 flex justify-start items-center gap-1 border-r border-gray-800 pl-2">
                  <div className="grid grid-cols-12 items-center w-full">
                     <div className="col-span-3">
                        <img
                           alt="img"
                           src={data.userImg}
                           className="h-11 w-11 md:h-8 md:w-8 rounded-full object-cover"
                        />
                     </div>
                     <div className="col-span-9 -ml-2 md:-ml-1">
                        <p className="text-white text-[16px] md:text-[14px] ml-2 md:ml-0">{data.userName}</p>
                     </div>
                  </div>
               </div>
               <div className="col-span-5 pr-2">
                  <p className="text-white text-[15px] md:text-[13px] ml-2">
                     <span className="font-bold">Joined:</span> {data.joined}
                  </p>
               </div>
            </div>
         ))}
      </div>

   )
}

export default User