import { Navigate, Outlet } from "react-router-dom";

export default function PublicRoute({ children }) {
  const isAuthenticated =  localStorage.getItem("accessToken");
  const security_login =   localStorage.getItem("security_login");
  return isAuthenticated && security_login ? (
    <Navigate to={"/home"} />
  ) : security_login ? (
    children || <Outlet />
  ) : (
    <Navigate to={"/"} />
  );
}
