import React from "react";
import ImageSlider from "../../Component/shoppage/detailspage/ImageSlider";
import AboutProducts from "../../Component/shoppage/detailspage/AboutProducts";
import SimilarProduct from "../../Component/shoppage/SimilarProducts";
import RecentProducts from "../../Component/shoppage/RecentProducts";
import SugestedProductsWhiteBackground from "../../Component/shoppage/SugestedProductsWhiteBackground";
import InputButtonBlack from "../../Component/shoppage/InputButtonBlack";

const ShopDetails = () => {
   return (
      <div className="h-full w-full overflow-hidden">
         <div className="h-full overflow-scroll no-scrollbar relative">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               cc shop-details / fe / v3 / JUNE 25, 2024
            </p>
            <ImageSlider />
            <AboutProducts />
            <SimilarProduct />
            <RecentProducts />
            <SugestedProductsWhiteBackground />
            <InputButtonBlack />
         </div>
      </div>
   );
};

export default ShopDetails;
