import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiMiniBars3 } from "react-icons/hi2";
import { IoArrowBackCircle } from "react-icons/io5";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Media from "./SearchComponent/Media";
import Comments from "./SearchComponent/Comments";
import User from "./SearchComponent/User";
import SliderMenu from "./SearchComponent/SliderMenu";

const OtherGroupSearch = () => {
   const navigate = useNavigate();
   const [show, setShow] = useState("media");

   return (
      <div className="bg-black h-full">
         <div className="sticky top-0 bg-black">
            <div className="grid grid-cols-12 px-3 pt-1 items-center">
               <p className="col-span-12 text-[#ff3b3b] text-center font-bold text-[10px] md:text-[8px]">
                  CC OTHERS GROUP SEARCHPAGE - FE - V2 - APR 25, 2024
               </p>
               <div className="col-span-2">
                  <IoArrowBackCircle className="text-white text-[30px] md:text-[24px] cursor-pointer" onClick={() => navigate(-1)} />
               </div>
               <div className="col-span-8 text-center">
                  <p className="text-white text-[22px] md:text-[17px]">Ferrari Group</p>
               </div>
               <div className="col-span-2">
                  <div className="flex float-end">
                     <SliderMenu />
                  </div>
               </div>
            </div>

            <hr className="w-full mt-[4px] md:mt-[6px] my-[4px] md:my-[6px] h-[0.5px] bg-gray-800 border-0" />

            <div className="relative px-5">
               <MagnifyingGlassIcon className="h-6 w-6 md:h-5 md:w-5 absolute top-[8px] left-[30px] md:left-[25px] cursor-pointer" />
               <input
                  type="text"
                  placeholder="Search"
                  className="bg-white border text-black border-gray-700 focus:outline-none w-full pl-10 md:pl-8 pr-2 py-[0.5rem] md:py-[0.3rem] rounded-full"
               />
            </div>

            <div className="flex justify-between items-center px-5 pt-2">
               <button
                  onClick={() => setShow("media")}
                  className={`${show === "media" ? "bg-[#45ff3f]" : "bg-white hover:bg-[#45ff3f]"} text-black rounded-full text-[18px] md:text-[14px] px-6 md:px-4 py-[3px]`}
               >
                  Media
               </button>
               <button
                  onClick={() => setShow("comments")}
                  className={`${show === "comments" ? "bg-[#45ff3f]" : "bg-white hover:bg-[#45ff3f]"} text-black rounded-full text-[18px] md:text-[14px] px-6 md:px-4 py-[3px]`}
               >
                  Comments
               </button>
               <button
                  onClick={() => setShow("user")}
                  className={`${show === "user" ? "bg-[#45ff3f]" : "bg-white hover:bg-[#45ff3f]"} text-black rounded-full text-[18px] md:text-[14px] px-6 md:px-4 py-[3px]`}
               >
                  User
               </button>
            </div>

            <hr className="w-full mt-[6px] my-[6px] h-[0.5px] bg-gray-800 border-0" />
         </div>

         <div className="px-0 h-fit bg-black">
            {show === "media" && <Media />}
            {show === "comments" && <Comments />}
            {show === "user" && <User />}
         </div>
      </div>
   )
}

export default OtherGroupSearch