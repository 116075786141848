import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const selectVideoAll = createAsyncThunk("selectVideo", async (data) => {
  try {
    const response = data;
    return response;
  } catch (error) {
    return error;
  }
});

export const WatchVideo = createSlice({
  name: "WatchVideo",
  initialState: {
    AllPosts: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // AccountProfile action promises
    builder.addCase(selectVideoAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(selectVideoAll.fulfilled, (state, action) => {
      state.AllPosts = action.payload;
      state.loading = false;
    });
    builder.addCase(selectVideoAll.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default WatchVideo.reducer;
