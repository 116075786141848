import React from "react";
import { groupSearchMedia } from "../../../Datas/Datas";

const Media = () => {
   return (
      <div className="overflow-y-scroll">
         <div className="grid grid-cols-3">
            {groupSearchMedia.map((data) => (
               <div className="col-span-1 p-1">
                  <div className="border border-gray-800 p-1">
                     <p className="text-white leading-[13px] text-[13px] pb-1">{data.mediaMsg}</p>
                     <img
                        alt="img"
                        className="h-16 w-full rounded object-cover"
                        src={data.mediaImg}
                     />
                  </div>
                  <div className="py-1 flex gap-1 items-center">
                     <img
                        className="h-5 w-5 rounded-full object-cover"
                        src={data.userImg}
                     />
                     <p className="text-white leading-[13px] text-[12px]">{data.userName}</p>
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}

export default Media