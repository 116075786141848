import React, { useCallback, useEffect, useRef, useState } from "react";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  HomepageVideo,
  HomepageVideoCreateComment,
  HomepageVideoDeleteComment,
  HomepageVideoGetComment,
  HomepageVideoLike,
  HomepageVideoReplayComment,
  HomepageVideoUpdateComment,
} from "../../Redux/ApiSlice/HomePageVideoSlice";
import { BsEmojiSmileFill } from "react-icons/bs";
import SaveModal from "../../Component/Modals/HomePageModal/saveModal";
import emptyComments from "../../Assets/HomePageIcons/comments.svg";
import Comments from "../../Component/Home/Comments";
import { videoFootageSideIcon } from "../../Datas/Datas";
import {
  UserAccountFollow,
  UserAccountFollowinglist,
  UserlikedVideos,
} from "../../Redux/ApiSlice/AccountProfileSlice";
import Share from "./Share";
import { TiUserAddOutline } from "react-icons/ti";
import { BiCheckDouble } from "react-icons/bi";
import { Button } from "@material-tailwind/react";
import { useOutsideClick } from "../../Hooks/useOutsideClick";

const HomeVideoView = ({
  id,
  src,
  created_by,
  comments,
  dec,
  like_count,
  fetchData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [likeEmoji, setLikeLikeEmoji] = useState(false);
  const [openBottom, setOpenBottom] = useState(false);
  const openDrawerBottom = () => setOpenBottom(true);
  const closeDrawerBottom = () => setOpenBottom(false);
  const [openBottomComments, setOpenBottomComments] = useState(false);
  const [openSaveDrawer, setOpenSaveDrawer] = useState(false);
  const [createCommet, setCreateComment] = useState({ key: id, body: "" });

  const [menuCommentId, setMenuCommentId] = useState(null);
  const menuRef = useRef(null);
  const saveRef = useRef(null);
  const [EditComment, setEditComment] = useState(false);
  const [replay, setReplay] = useState(false);
  const videoRef = useRef(null);
  const [repleyitemId, setRepleyItemId] = useState(null);

  // Show More Desc For Video
  const [showMore, setShowMore] = useState(false);
  const textRef = useRef(null);

  const text = dec;
  const hashtags = text?.split(/\s+#/).slice(1); // Split by one or more spaces followed by #
  // Remove '#' and everything after it
  let description = text?.replace(/#\S+/g, "");
  const openDrawerBottomComments = () => {
    dispatch(HomepageVideoGetComment(id));
    setOpenBottomComments(true);
  };

  const ProfileDetails = useSelector((state) => state.AccountProfile);
  const { User_profile, User_likedVideos, User_followingList } = ProfileDetails;
  const { Liked_Posts } = User_likedVideos;

  const closeDrawerBottomCommments = () => setOpenBottomComments(false);

  const handleOpenSavedModal = () => setOpenSaveDrawer(!openSaveDrawer);
  useOutsideClick(saveRef, () => handleOpenSavedModal());

  const handleOpenreplay = (value) => {
    setRepleyItemId(value);
  };

  let originalURL = User_profile.avatar;

  const toggleMenu = (commentId) => {
    if (menuCommentId === commentId) {
      setMenuCommentId(null); // Close menu if already open
    } else {
      setMenuCommentId(commentId); // Open menu for this comment
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuCommentId(null); // Close menu if clicked outside
    }
  };

  const handleLikeVideo = useCallback(async () => {
    await dispatch(HomepageVideoLike(id));
    dispatch(UserlikedVideos());
    setLikeLikeEmoji(!likeEmoji);
  }, [likeEmoji, id, dispatch]);

  const handleSetPage = (value) => {
    switch (value) {
      case "Groups":
        return navigate("/othergroup/list");
      case "Comment":
        return openDrawerBottomComments();
      case "Share":
        return openDrawerBottom();
      case "Like":
        return handleLikeVideo();
      case "Save":
        return handleOpenSavedModal();
      default:
        break;
    }
  };
  const handleChangeComment = (e) => {
    setCreateComment((prev) => ({ ...prev, body: e.target.value }));
  };

  const handleSubmitComment = async () => {
    if (EditComment) {
      await dispatch(HomepageVideoUpdateComment(createCommet));
      dispatch(HomepageVideo());
      setEditComment((prev) => !prev);
    } else if (replay) {
      await dispatch(HomepageVideoReplayComment(createCommet));
      dispatch(HomepageVideo());
      setReplay((prev) => !prev);
    } else {
      if (createCommet.body.length > 0) {
        await dispatch(HomepageVideoCreateComment(createCommet));
        dispatch(HomepageVideo());
      }
    }
    setCreateComment({ key: id, body: "" });
  };
  const handleChangeEditComment = (CommentId, value) => {
    setCreateComment(() => ({ key: CommentId, body: value }));
    setEditComment((prev) => !prev);
    setMenuCommentId(null);
  };

  const handleChangeDeleteComment = async (CommentId) => {
    await dispatch(HomepageVideoDeleteComment(CommentId));
    setMenuCommentId(null);
    dispatch(HomepageVideo());
  };
  const handleChangeRepleyComment = (CommentId) => {
    setCreateComment(() => ({ key: CommentId, body: "" }));
    setReplay(true);
    setMenuCommentId(null);
  };

  const handleFollowFun = async (id) => {
    await dispatch(UserAccountFollow(id));
    await dispatch(UserAccountFollowinglist());
  };

  const handleNavigate = (id) => {
    if (User_profile?.id !== id) {
      navigate(`/otheruseraccount/${id}`);
    } else {
      navigate("/account");
    }
  };

  const playVideo = (video) => {
    return new Promise((resolve, reject) => {
      if (video.paused) {
        video
          .play()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(); // Already playing, resolve immediately
      }
    });
  };

  const pauseVideo = (video) => {
    return new Promise((resolve, reject) => {
      if (!video.paused) {
        video.pause();
        resolve();
      } else {
        resolve(); // Already paused, resolve immediately
      }
    });
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const videoElement = document.getElementById("video");
  playVideo(videoElement)
    .then(() => {})
    .catch((error) => {
      console.error("Error occurred while trying to play video:", error);
    });

  useEffect(() => {
    const video = document.getElementById(`video-${id}`);
    const postMainElement = document.getElementById(`post-main-${id}`);
    if (video && postMainElement) {
      let observer = new IntersectionObserver(
        (entries) => {
          if (entries && entries[0]) {
            if (entries[0].isIntersecting) {
              try {
                playVideo(video)
                  .then(() => {
                    console.log("Video started playing successfully");
                    setMenuCommentId(null);
                    toggleMenu();
                  })
                  .catch((error) => {
                    console.error(
                      "Error occurred while trying to play video:",
                      error
                    );
                  });
              } catch (error) {
                console.error(
                  "Error occurred while trying to play video:",
                  error
                );
              }
            } else {
              try {
                pauseVideo(video)
                  .then(() => {
                    console.log("Video paused successfully");
                    closeDrawerBottomCommments();
                    closeDrawerBottom();
                  })
                  .catch((error) => {
                    console.error(
                      "Error occurred while trying to pause video:",
                      error
                    );
                  });
              } catch (error) {
                console.error(
                  "Error occurred while trying to pause video:",
                  error
                );
              }
            }
          }
        },
        { threshold: [0.6] }
      );
      observer.observe(postMainElement);
      return () => {
        observer.disconnect(); // Cleanup observer on component unmount
      };
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    if (Liked_Posts?.some((data) => data.id === id)) {
      setLikeLikeEmoji(!likeEmoji);
    }
    if (likeEmoji) {
      handleLikeVideo();
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (textRef.current && !textRef.current.contains(event.target)) {
        setShowMore(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleVideoEnd = () => video.play();
      video.addEventListener("ended", handleVideoEnd);

      return () => video.removeEventListener("ended", handleVideoEnd);
    }
  }, [videoRef]);

  return (
    <div
      id={`post-main-${id}`}
      className="h-[85vh] md:h-[100%] md:w-full"
      aos-init
      data-aos="fade-up"
    >

      {/* SAVE MODAL FOR SAVE THE VIDEO INTO OUR SAVED FOLDER */}
      {openSaveDrawer && (
        <div className="h-full w-full  relative ">
          <div className="absolute top-0 h-full w-full flex items-center justify-center ">
            <div className="absolute bg-gray-500 h-full w-full z-40 opacity-25"></div>
            <SaveModal
              saveRef={saveRef}
              open={openSaveDrawer}
              handleClose={handleOpenSavedModal}
              videoId={id}
              fetchData={fetchData}
            />
          </div>
        </div>
      )}

      <video
        ref={videoRef}
        controls
        id={`video-${id}`}
        style={{ maxWidth: "100%" }}
        controlslist="nodownload noplaybackrate"
        type={"video/mp4"}
        aria-hidden
        disablePictureInPicture
        disablePlayback
        disableRemotePlayback
        className=""
      >
        <source
          src={`${src}`}
          type="video/mp4"
        />
      </video>
      {/* share pop */}
      <Share
        openBottom={openBottom}
        closeDrawerBottom={closeDrawerBottom}
        videoUrl={`${src}`}
      />
      {/* comments pop */}
      <Comments
        openBottomComments={openBottomComments}
        closeDrawerBottomCommments={closeDrawerBottomCommments}
        comments={comments}
        handleChangeComment={handleChangeComment}
        menuCommentId={menuCommentId}
        emptyComments={emptyComments}
        menuRef={menuRef}
        handleChangeEditComment={handleChangeEditComment}
        toggleMenu={toggleMenu}
        handleChangeDeleteComment={handleChangeDeleteComment}
        setMenuCommentId={setMenuCommentId}
        handleSubmitComment={handleSubmitComment}
        createCommet={createCommet}
        handleChangeRepleyComment={handleChangeRepleyComment}
        handleOpenreplay={handleOpenreplay}
        repleyitemId={repleyitemId}
        originalURL={originalURL}
        replay={replay}
        setReplay={setReplay}
      />
      <div className="absolute bottom-2 flex justify-between px-2  w-full">
        {videoFootageSideIcon?.map((item) => (
          <div className="text-[red]  items-end flex justify-center w-10 ">
            <div className={`flex fle-start flex-col items-center`}>
              <div className="">
                {item?.icon && item?.text === "Like" ? (
                  <BsEmojiSmileFill
                    style={{
                      width: "20px",
                      height: "20px",
                      color: likeEmoji ? "green" : "yellow",
                    }}
                    color="yellow"
                    onClick={() => handleSetPage(item?.text)}
                  />
                ) : (
                  <img
                    onClick={() => handleSetPage(item?.text)}
                    src={item?.icon}
                    width={20}
                    height={20}
                    alt="image"
                    className="cursor-pointer"
                  />
                )}
              </div>
              <p
                onClick={() => handleSetPage(item?.text)}
                className="text-[#ffff] text-[10px] font-semibold  "
              >
                {item?.text === "Like" && (
                  <span className="pr-[2px]">{like_count}</span>
                )}
                {item?.text !== "Like" && item?.text}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="left-side-account-icon mb-2">
        <div className="left-account-icon-content z-20">
          <div className="home-account-icon relative">
            <p className="line-clamp-1 absolute bottom-[-10px]">
              {created_by?.first_name}
            </p>
            <div>
              <img
                src={`${created_by?.avatar_url}`}
                alt="image"
                className="object-cover w-7 h-7 rounded-md"
                onClick={() => handleNavigate(created_by?.id)}
              />
            </div>
          </div>
          {created_by?.id !== User_profile.id && (
            <div className="absolute -top-[20%] left-[50%] h-[25px] w-[25px] cursor-pointer">
              <Button
                className={`${
                  User_followingList?.some(
                    (data) => data.following_id === created_by?.id
                  )
                    ? "bg-[#5f3cee]  "
                    : "bg-[#9333ea]"
                } w-[20px] h-[20px] rounded-full flex justify-center items-center p-0`}
              >
                {User_followingList?.some(
                  (data) => data.following_id === created_by?.id
                ) ? (
                  <TiUserAddOutline
                    onClick={() => handleFollowFun(created_by?.id)}
                    className="w-[18px] h-[18px] text-white"
                  />
                ) : (
                  <BiCheckDouble
                    className="w-[18px] h-[18px] text-white"
                    onClick={() => handleFollowFun(created_by?.id)}
                  />
                )}
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className="fixed bottom-[3.2rem] left-3">
        <p
          className="text-white font-bold text-[1.1rem] cursor-pointer"
          onClick={() => handleNavigate(created_by?.id)}
        >
          {created_by?.first_name} {created_by?.last_name}
        </p>
        <p
          ref={textRef}
          className={`text-sm pt-1 text-white w-[80%] ${
            showMore ? "" : "line-clamp-1"
          }`}
        >
          {description}
        </p>
        <>
          {hashtags?.map((item) => (
            <a
              className="underline text-sm pt-1 text-white w-[80%] cursor-pointer "
              onClick={() => navigate(`/hashtag/#${item}`)}
            >
              #{item}
            </a>
          ))}
        </>

        {dec?.length > 30 && (
          <div>
            {!showMore && (
              <span
                onClick={handleShowMore}
                className="text-sm cursor-pointer font-bold text-cyan-500 hover:text-cyan-400"
              >
                More
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeVideoView;
