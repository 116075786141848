import React, { useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import { MdBlockFlipped } from "react-icons/md";
import { IoSearchSharp } from "react-icons/io5";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaCrown } from "react-icons/fa6";
import { AiFillMessage } from "react-icons/ai";

const users = [
   { id: 1, name: "Emily Johnson", isModerators: true, userImg: "https://images.unsplash.com/photo-1534008757030-27299c4371b6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 2, name: "Jacob Smith", isModerators: false, userImg: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 3, name: "Olivia Brown", isModerators: true, userImg: "https://images.unsplash.com/photo-1503104834685-7205e8607eb9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 4, name: "Ethan Martinez", isModerators: false, userImg: "https://images.unsplash.com/photo-1566492031773-4f4e44671857?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 5, name: "Ava Anderson", isModerators: false, userImg: "https://images.unsplash.com/photo-1560238970-cc0ae073d3a5?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 6, name: "Michael Thompson", isModerators: false, userImg: "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 7, name: "Sophia Garcia", isModerators: true, userImg: "https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 8, name: "William Davis", isModerators: true, userImg: "https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 9, name: "Isabella Rodriguez", isModerators: true, userImg: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
   { id: 10, name: "James Wilson", isModerators: false, userImg: "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
]

const OtherGroupMember = () => {
   const [showMenu, setShowMenu] = useState(false);
   const navigate = useNavigate();

   return (
      <div className="bg-black h-full pb-5 overflow-y-scroll">
         <div className="sticky top-0 bg-black z-10">
            <div className="grid grid-cols-12 px-3 pt-1 items-center">
               <div className="col-span-12 pb-[2px]">
                  <p className="text-[#ff3b3b] text-center font-bold text-[12px] md:text-[8px]">
                     CC OTHERS GROUP MEMBER - FE - V2 - APR 27, 2024
                  </p>
               </div>
               <div className="col-span-2">
                  <IoArrowBackCircle className="text-white text-[30px] md:text-[24px] cursor-pointer" onClick={() => navigate(-1)} />
               </div>
               <div className="col-span-8 text-center">
                  <p className="text-white text-[18px] md:text-[14px]">Ferrari Group Members</p>
               </div>
               <div className="col-span-2 flex justify-end">
                  {showMenu ?
                     <p className="text-white hover:text-red-600 text-[12px] font-bold cursor-pointer" onClick={() => setShowMenu(false)}>Close</p>
                     :
                     <HiDotsVertical className="text-white text-[30px] md:text-[24px] cursor-pointer" onClick={() => setShowMenu(!showMenu)} />
                  }
               </div>
            </div>

            {showMenu &&
               <div className="absolute top-14 md:top-10 right-2 bg-[#d9d9d9] px-3 py-1 z-50 rounded">
                  <p className="text-black text-[13px] cursor-pointer">Menu 01</p>
                  <p className="text-black text-[13px] cursor-pointer">Menu 02</p>
               </div>
            }

            <hr className="w-full my-[6px] h-[0.5px] bg-gray-800 border-0" />
         </div>

         <div className="mx-3 relative">
            <input
               type="text"
               placeholder="Search"
               className="w-full focus:outline-none border border-gray-700 py-1 pl-8 pr-2 rounded-full"
            />
            <IoSearchSharp className="absolute top-[7px] left-[7px] text-[20px] cursor-pointer" />
         </div>

         {users.map((data) => (
            <>
               <div className="grid grid-cols-12 px-2 items-center pt-2 pb-2" key={data.id}>
                  <div className="col-span-1 flex justify-center items-center">
                     {data.isModerators && <FaCrown className="text-[25px] md:text-[20px] text-yellow-800" />}
                  </div>
                  <div className="col-span-2 ml-[4px]">
                     <img
                        alt={data.name}
                        src={data.userImg}
                        className="h-10 w-10 md:h-8 md:w-8 rounded-full object-cover"
                     />
                  </div>
                  <div className="col-span-5 ml-[-4px]">
                     <p className="text-[16px] md:text-[13px] text-white leading-[14px]">{data.name}</p>
                  </div>
                  <div className="col-span-4 flex justify-end gap-[5px] items-center">
                     {data.isModerators ? null :
                        <button className="h-7 w-7 md:h-6 md:w-6 bg-[#ffffff] text-black hover:text-green-600 flex justify-center items-center rounded-full">
                           <MdBlockFlipped className="text-[15px] md:text-[13px]" />
                        </button>
                     }
                     <button className="h-7 w-7 md:h-6 md:w-6 bg-[#ffffff] text-black hover:text-green-600 flex justify-center items-center rounded-full">
                        <AiFillMessage className="text-[15px] md:text-[13px]" />
                     </button>
                     <button className="h-7 w-7 md:h-6 md:w-6 bg-[#ffffff] text-black hover:text-green-600 flex justify-center items-center rounded-full">
                        <FaUserPlus className="text-[15px] md:text-[13px]" />
                     </button>
                  </div>
               </div>
               <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-900 border-0" />
            </>
         ))}
      </div>
   )
}

export default OtherGroupMember