import React from "react";
import { Avatar } from "@material-tailwind/react";
import { FiCheck } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

export default function ReguestTab({ MessagesList }) {
   return (
      <>
         {MessagesList.map((item) => (
            <div className="grid grid-cols-12 px-2 py-1 border-b-[1px] border-black bg-white relative hover:bg-gray-300 cursor-pointer">
               <div className="grid grid-cols-7 col-span-7">
                  <div className="col-span-2 flex items-center justify-start">
                     <Avatar
                        alt={item.name}
                        src={item.img}
                        className="h-9 w-9 shadow-2xl object-cover"
                     />
                  </div>
                  <div className="col-span-5 flex items-center">
                     <p className="text-black font-semibold text-[14px]">{item.name}</p>
                  </div>
               </div>
               <div className="col-span-5 flex justify-end items-center gap-[5px]">
                  <button
                     type="button"
                     className="bg-black hover:bg-[#08e200ba] text-white p-[5px] rounded-md"
                  >
                     <FiCheck className="h-[14px] w-[14px]" />
                  </button>
                  <button
                     type="button"
                     className="bg-black hover:bg-[#ff0000c5] text-white p-[5px] rounded-md"
                  >
                     <AiOutlineClose className="h-[14px] w-[14px]" />
                  </button>
               </div>
            </div>
         ))}
      </>
   );
}
