import React, { Fragment } from "react";
import InviteMembers from "../../Component/grouppage/InviteMembers";

const InviteMemberPage = () => {
   return (
      <Fragment>
         <p className="absolute text-red-700 uppercase font-bold text-[10px] w-full text-center">
            cc group - invite members / fe / v1 / june 20, 2024
         </p>
         <div className="pt-4 bg-black">
            <InviteMembers />
         </div>
      </Fragment>
   );
};

export default InviteMemberPage;
