import React, { useCallback, useEffect, useState } from "react";
import ProfileSection from "../../Component/OtherUserAccount/ProfileSection";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FollowersList from "../../Component/OtherUserAccount/Followerslist";
import FollowingList from "../../Component/OtherUserAccount/FollowingList";
import { OtherUserPosts } from "./OtherUserPosts";
import {
  OtherUserProfile,
  UserAccountFollow,
  UserAccountFollowerlist,
} from "../../Redux/ApiSlice/AccountProfileSlice";
import { messagesChatRoomCreate } from "../../Redux/ApiSlice/MessagesSlice";
import ShareModal from "../../Component/Modals/ShareModal";

export default function OtherUserAccount() {
  const ProfileDetails = useSelector((state) => state.AccountProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { otherUser_profile, User_profile } = ProfileDetails;
  const { followers, following, posts, is_private, is_following } =
    otherUser_profile;
  const { id } = useParams();
  const [count, setCount] = useState("allPosts");
  const [openShare, setOpenShare] = useState(false);
  const [otherUserFollowingYou, setOtherUserFollowingYou] = useState(false);
  const Messages = useSelector((state) => state.messages);
  const { createChatRoom } = Messages;

  const handleFetch = useCallback(async () => {
    await dispatch(OtherUserProfile(id));
    if (following?.some((item) => item.following_id === User_profile.id)) {
      setOtherUserFollowingYou(true);
    }
  }, [User_profile.id]);

  const handleShare = () => setOpenShare(!openShare);

  const handleFollowFun = async () => {
    await dispatch(UserAccountFollow(id));
    await dispatch(UserAccountFollowerlist());
    await dispatch(OtherUserProfile(id));
  };

  const handleStartChat = async () => {
    await dispatch(messagesChatRoomCreate(id));
    // setTimeout(() => {
    navigate(`/mobileMessages/userChat/${createChatRoom.id}`);
    // }, 2000);
  };

  useEffect(() => {
    handleFetch();
  }, [dispatch, OtherUserProfile, handleFetch]);


  return (
    <>
      <section className="bg-[#000] h-full w-full">
        <ProfileSection
          userId={id}
          setCount={setCount}
          count={count}
          otherUser_profile={otherUser_profile}
          otherUserFollowingYou={otherUserFollowingYou}
          handleFollowFun={handleFollowFun}
          handleStartChat={handleStartChat}
          handleShare={handleShare}
        />
        <div className="p-0 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available] mt-2">
          {((is_private && is_following && otherUserFollowingYou) ||
            !is_private) && (
            <>
              {count === "Following" && <FollowingList datalist={following} />}
              {count === "Followers" && (
                <FollowersList datalist={followers} isOtherAccount={true} />
              )}
              {(count === "Posted" || count === "allPosts") && (
                <OtherUserPosts User_posts={posts} />
              )}
            </>
          )}
        </div>
      </section>
      <ShareModal openBottom={openShare} closeDrawerBottom={handleShare} />
    </>
  );
}
