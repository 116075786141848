import React from "react";
import {
   Button,
   Dialog,
   DialogHeader,
   DialogFooter,
} from "@material-tailwind/react";

export function LockConfirmationModal({ open, handleOpen, groupLock }) {
   return (
      <>
         <Dialog
            className="custom-dialog-width h-[20%] flex flex-col justify-center items-center relative"
            size="sm"
            open={open}
            handler={handleOpen}
            animate={{
               mount: { scale: 1, y: 0 },
               unmount: { scale: 0.9, y: -100 },
            }}
         >
            <p className="absolute top-0 text-red-800 text-center text-[12px] font-bold">
               CC USER CREATED GROUP PAGE HEADER LOCK ICON / FE / V2 / JULY 08, 2024
            </p>
            <DialogHeader className="text-center flex justify-center items-center p-2 text-black">
               {groupLock ? "Unlock your Group?" : " Lock your Group?"}
            </DialogHeader>
            <DialogFooter className="flex justify-center items-center gap-4 p-2">
               <Button
                  variant="text"
                  color="red"
                  onClick={() => handleOpen(false)}
                  className="mr-1 hover:bg-[#f80000c8] bg-[#F80000] text-white font-bold"
               >
                  <span>No</span>
               </Button>
               <Button
                  variant="text"
                  color="green"
                  onClick={() => handleOpen(true)}
                  className="hover:bg-[#31c629c8] bg-[#31c629] text-white font-bold"
               >
                  <span>Yes</span>
               </Button>
            </DialogFooter>
         </Dialog>
      </>
   );
}
