import React from 'react'
import AccountProfileSection from '../../Component/AccountPage/AccountProfileSection'
import AccountTab from '../../Component/AccountPage/AccountTab'
import { RepostTab } from '../../Component/AccountPage/RepostTab'

export default function Repost() {
   return (
      <div className="bg-[#000] overflow-hidden h-full relative ">
         <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            CC ACCOUNT REPOST PAGE-FE - V11 / JUNE 29, 2024
         </p>
         <div className="">
            <AccountProfileSection />
         </div>
         <AccountTab activeTab={"Repost"} />
         <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
            <RepostTab />
         </div>
      </div>
   )
}
