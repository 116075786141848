import React, { Fragment, useState } from "react";
// import storeimage from "../Assets/LoginPageIcon/Download Buttons.svg";
// import character from "../Assets/LoginPageIcon/2b5d15c4cc9b39045aa5ca1c45716c90.png";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { lostPassword } from "../Redux/ApiSlice/LostPasswordSlice";
import ForgotPassword from "../Components/MobileVersion/ForgotPassword/ForgotPassword";
// import Footer from "../Component/Footer/Footer";
// import HeaderTab from "../Component/Login/LoginHeader";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { REACT_APP_MAIN_URL } from "../API/MainUrl";

const LostPassword = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
  const [email, setEmail] = useState(null);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendOtp = async (data) => {
    toast.loading("Loading...");
    try {
      const formData = new FormData();
      formData.append("email", data);
      const response = await axios.post(
        `${REACT_APP_MAIN_URL}api/forgot-password/`,
        formData
      );
      const result = response.data;
      if (result.success === true) {
        toast.dismiss();
        await toast.success(result.message);
        formik.resetForm();
      }
      return result;
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
      return error;
    }
  };

  const validateSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      if (values.email) {
        handleSendOtp(values.email);
      }
    },
  });

  return (
    <Fragment>
      {/* important code do not remove */}
      {/* <div className="hidden sm:hidden md:block overflow-hidden bg-[#74cbfc] h-[100vh]">
            <p className="absolute uppercase text-[#ff3b3b] right-5 font-semibold">
               CC Lost Password / V1 / FE / Mar 05, 2024
            </p>

            <HeaderTab />
            <div className="flex justify-center items-center mt-[4rem]">
               <div className="w-[45%] h-[80%] bg-[#fff] rounded-2xl relative flex justify-center items-center shadow-2xl">
                  <img
                     src={character}
                     alt="img"
                     className="absolute h-[25rem] -left-[11.5rem] filter:box"
                  />
                  <div className="p-4 w-[70%]">
                     <p className="text-center text-[2.5rem] font-bold mt-2 tracking-wide">
                     Reset Password
                     </p>
                     <p className="text-center text-[0.9rem] font-semibold mb-3 mt-2">
                        Please enter your email for verification
                     </p>
                     <form className="px-14" onSubmit={formik.handleSubmit}>
                        <div className="flex flex-col mb-3">
                           <lable>Email</lable>
                           <input
                              type="email"
                              name="email"
                              placeholder="Enter your email address"
                              className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              onBlur={formik.handleBlur}
                           />
                           {formik.touched.email && formik.errors.email && (
                              <p className="text-[#ff3b3b] md:text-[15px] font-medium">
                                 {formik.errors.email}
                              </p>
                           )}
                        </div>
                        <div className="flex justify-start items-center mt-3 mb-5">
                           <p
                              className="font-medium cursor-pointer text-[#00A3FA]"
                              onClick={() => navigate("/contacts-us")}
                           >
                              I've lost my email
                           </p>
                        </div>
                        <div className="flex justify-evenly mb-10">
                           <p
                              className="bg-[#00A3FF] text-[#fff] cursor-pointer text-[1rem] font-semibold w-[30%] text-center shadow-lg py-[7px] rounded-lg"
                              onClick={() => navigate(-1)}
                           >
                              Back
                           </p>
                           <button
                              type="submit"
                              className="bg-[#00A3FF] text-[#fff] text-[1rem] font-semibold w-[30%] shadow-lg py-[7px] rounded-lg"
                              // onClick={(e) => handleSendOtp(e, email)}
                           >
                              Send
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
            <Footer />
         </div> */}
      {/* <div className="block overflow-hidden full"> */}
        <ForgotPassword
          handleSendOtp={handleSendOtp}
          handleChange={handleChange}
          email={email}
        />
      {/* </div> */}
      <Toaster position="top-right" />
    </Fragment>
  );
};

export default LostPassword;
