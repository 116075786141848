import React, { useCallback, useEffect, useState } from "react";
import HomePagePannel from "../Components/Panels/HomePagePanel";
import { VideoCardFooterIcons } from "../Datas/Datas";
import VideoCardIconList from "../Components/MobileVersion/MobilePanel/VideoCardIconList";
import SecretSauceButton from "../Components/MobileVersion/SecretSauceButton/SecretSauceButton";
import { useDispatch, useSelector } from "react-redux";
import { HomepageVideo } from "../Redux/ApiSlice/HomePageVideoSlice";
import { AccountProfile, UserlikedVideos } from "../Redux/ApiSlice/AccountProfileSlice";
import Post from "../Component/videopage/Post";
import { FaBasketShopping } from "react-icons/fa6";
import { MdOutlineFeed } from "react-icons/md";
import { CiStreamOn } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import { GiThreeFriends } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { IoSyncCircleOutline } from "react-icons/io5";
import ProfileAccountModal from "../Component/Modals/HomePageModal/ProfileAccountModal";

export default function HomeMainPage() {
   // const params = useParams();
   // const { id } = params;
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const getAllPost = useSelector((state) => state.HomePageVideo);
   const { AllPosts } = getAllPost;
   const [setLikeLikeEmoji] = useState(false);

   const hashTag = "#crazy #money #plane #toy";
   const spilThashTags = hashTag.split("#");
   const summary = " Guess how much this dude spent on this?";
   const [nextButton, setNextButton] = useState(null);
   const [preButton, setPreButton] = useState(null);

   const fetchData = useCallback(async () => {
      await dispatch(UserlikedVideos());
      await dispatch(AccountProfile());
      dispatch(HomepageVideo());
   }, [dispatch]);

   useEffect(() => {
      fetchData();
   }, [fetchData, dispatch]);

   const [openBottom, setOpenBottom] = useState(false);
   const openDrawerBottom = () => setOpenBottom(true);
   const closeDrawerBottom = () => setOpenBottom(false);
   const [openAccountBottom, setOpenAccountBottom] = useState(false);
   const openDrawerAccountModal = () => setOpenAccountBottom(!openAccountBottom);

   const reloadPage = () => {
      // For Reload the page
      window.location.reload();
   };

   const handleNavigate = (value) => {
      const replaceValue = value.replaceAll(" ", "");
      if (value === "Messages") {
         navigate(`/mobileMessages`);
      } else if (replaceValue === "YourFeed") {
         return reloadPage();
      } else if (replaceValue === "YourFriends") {
         return reloadPage();
      }
      else if (replaceValue === "Home") {
         return navigate("/home");
      }
      else if (replaceValue === "Createvideo") {
         return openDrawerBottom();
      } else if (replaceValue === "YourCC") {
         return navigate(`/commingsoon`);
      } else if (replaceValue === "Helmet1") {
         return navigate(`/commingsoon`);
      } else if (replaceValue === "Account") {
         return openDrawerAccountModal();
      } else {
         navigate(`/${replaceValue}`);
      }
   };

   const HeaderIcons = [
      {
         icon: <FaBasketShopping color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Shop",
      },
      {
         icon: <MdOutlineFeed color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Your Feed",
      },
      {
         icon: <GiThreeFriends color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Your Friends",
      },
      {
         icon: <CiStreamOn color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Live Stream",
      },
      {
         icon: <IoIosSearch color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Search",
      },
      {
         icon: <IoSyncCircleOutline color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Repost",
      },
   ];

   return (
      <>
         <div className="hidden md:flex  h-full text-center items-center md:w-[10%] mr-4">
            {preButton}
         </div>

         <div className="video-card-container overflow-hidden ">
            <div className="pt-[2px] absolute z-50 w-full px-3 py-[2px] bg-gradient-to-b from-black bg-opacity-40">

               <div className="w-full text-center pb-[3px]">
                  <p className="text-[#ff3b3b] text-[10px] font-bold mb-[2px]">
                     CC HOME / V40 / JULY 12, 2024
                  </p>
               </div>

               <div className="flex justify-between items-center">
                  {HeaderIcons.map((item) => (
                     <div
                        className="flex flex-col items-center pb-[2px]"
                        onClick={() => handleNavigate(`${item.placeholder}`)}
                     >
                        <div className="cursor-pointer opacity-100">{item.icon}</div>
                        <p className="text-white cursor-pointer text-[7px] mt-[1px]">
                           {item.placeholder}
                        </p>
                     </div>
                  ))}
               </div>
            </div>

            <div className="h-[85%] md:h-[86%] rounded-none relative">
               <HomePagePannel
                  AllPosts={AllPosts}
                  preButton={preButton}
                  summary={summary}
                  setLikeLikeEmoji={setLikeLikeEmoji}
                  spilThashTags={spilThashTags}
                  setNextButton={setNextButton}
                  setPreButton={setPreButton}
               />
            </div>

            <SecretSauceButton />

            <div className="video-card-footer">
               <VideoCardIconList
                  VideoCardHeaderIcons={VideoCardFooterIcons}
                  isfooter={true}
                  openDrawerBottom={openDrawerBottom}
                  handleNavigate={handleNavigate}
               />
            </div>

            <Post
               openBottom={openBottom}
               openDrawerBottom={openDrawerBottom}
               closeDrawerBottom={closeDrawerBottom}
            />

            <ProfileAccountModal
               openBottom={openAccountBottom}
               openDrawerBottom={openDrawerAccountModal}
               closeDrawerBottom={openDrawerAccountModal}
            />
         </div>

         <div className="hidden  md:flex  h-full text-center items-center md:w-[10%] ml-4">
            {nextButton}
         </div>
      </>
   );
}
