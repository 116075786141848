import React from "react";
import { homeVideoFootage } from "../../Datas/Datas";

export const HashtagRecentviews = () => {
   return (
      <div className="grid grid-cols-12 gap-2 px-2 overflow-y-scroll no-scrollbar">
         {homeVideoFootage.map((item) => (
            <div className="col-span-6">
               <div className="h-[26vh] w-full relative rounded-md">
                  <video
                     autoPlay
                     src={`${item.footage}`}
                     className="h-full w-full object-cover rounded-md"
                  />
               </div>
               <div className="col-span-12 flex justify-between items-center">
                  <p className="text-white text-[10px] cursor-pointer overflow-hidden w-[50%] line-clamp-1">
                     @{item.name}
                  </p>
                  <p className="text-[#303030] text-[10px] cursor-pointer">
                     {item.time} ago
                  </p>
               </div>
            </div>
         ))}
      </div>
   );
};
