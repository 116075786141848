import { FaSearch } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1,
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
      slidesToSlide: 1,
   },
};

export default function SearchSection({ SearchItem, handleSearchBar, handleshowresult, navigate }) {
   return (
      <>
         <div className=" w-full flex justify-center items-center mt-1">
            <div className="relative w-[80%]">
               <input 
                  className="outline-none rounded-full p-1 pl-8 pr-3 w-[100%]" 
                  onChange={handleSearchBar} 
                  onFocus={handleshowresult} 
                  placeholder="Search..."
               />
               {SearchItem.length == 0 && <FaSearch color="black" className="absolute left-2 top-2" />}
            </div>
         </div>
         <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
         >
            {["Shop", "Trending", "Hashtag", "Users"].map((data) => (
               <div
                  key={data}
                  className="flex py-2 justify-center items-center"
               >
                  <button
                     onClick={() => navigate(`/${data}`)}
                     className="bg-[#3a3a3a] hover:bg-[#1d1d1d] text-[#fff] text-[11px] text-center rounded-md w-[90%] p-2"
                  >
                     {data}
                  </button>
               </div>
            ))}
         </Carousel>
      </>
   );
}
