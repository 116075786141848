import {
   Tabs,
   TabsHeader,
   TabsBody,
   Tab,
   TabPanel,
   Button,
   Dialog,
   DialogHeader,
   DialogBody,
   DialogFooter,
   Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
   AccountProfile,
   AccountProfileDescription,
} from "../../../Redux/ApiSlice/AccountProfileSlice";

const AccountDescriptionModal = ({ theme, open, handleOpen, accountDec, setAccountDec, handleSubmitDec }) => {
   // const [editDec, setEditDec] = useState();

   const handleChange = (e) => {
      // e.preventDefult();
      setAccountDec((prev) => ({ ...prev, [e.target.name]: e.target.value }));
   };
   // const handleSubmit = () => {
   //   // dispatch(AccountProfileDescription(editDec));
   //   handleOpen();
   //   dispatch(AccountProfile());
   // };

   return (
      <Dialog
         theme={
            window.innerWidth < 500
               ? theme.dialog.styles.sizes.sm
               : theme.dialog.styles.sizes.md
         }
         open={open}
         handler={handleOpen}
         className=" h-50% absolute top-[8%]"
      >
         <DialogHeader>
            <Typography className=" w-full rounded-md flex justify-center">
               <textarea
                  type="text"
                  value={accountDec.about_us}
                  name="about_us"
                  class="border rounded-md p-2 focus:outline-none w-full focus:border-gray-800 border-gray-400"
                  maxLength={1000}
                  onChange={handleChange}
               />
            </Typography>
         </DialogHeader>

         <DialogFooter className="justify-around flex h-full">
            <Button
               variant="text"
               color="red"
               onClick={() => handleSubmitDec(accountDec)}
               className="bg-black w-[30%]  text-[#fff] p-2 cursor-pointer hover:bg-[#141414]"
            >
               Save
            </Button>
            <Button
               variant="text"
               color="red"
               onClick={handleOpen}
               className="bg-black w-[30%] p-2 text-[#fff] cursor-pointer hover:bg-[#e93f3f]"
            >
               Cancel
            </Button>
         </DialogFooter>
         <div className="flex flex-col justify-center items-center w-full">
            <span className="h-full text-[#ff3b3b] font-bold text-[10px] flex items-center">
               CC EDIT ACCOUNT DESCRIPTION - FE - V2 - FEB 24, 2024
            </span>
            <p className="text-base font-bold text-[#ff3b3b] text-[12px]">
               Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
            </p>
         </div>
      </Dialog>
   );
};

export default AccountDescriptionModal;
