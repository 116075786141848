import React from "react";
import { ThreeDots } from "react-loader-spinner";

export default function ThreeDotsSpinner() {
  return (
    <div className="absolute h-full w-full flex justify-center items-center z-40">
      <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#5e378b"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}
