import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCrown } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { IoIosInformationCircle } from "react-icons/io";
import { MdOutlinePostAdd } from "react-icons/md";
import { FaUsersLine } from "react-icons/fa6";
import { AiFillWechat } from "react-icons/ai";
import { IoIosTrendingUp } from "react-icons/io";
import { RxOpenInNewWindow } from "react-icons/rx";
import { MdOutlineVerticalAlignTop } from "react-icons/md";
import { AiOutlineComment } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";

const CreatedGroupTab = () => {
   const navigate = useNavigate();
   const [showFilter, setShowFilter] = useState(true);

   const handleFilter = () => {
      setShowFilter(!showFilter);
   }

   return (
      <Fragment>
         {showFilter ?
            <div className="h-[15%] md:h-[15%] bg-[#232323] px-2 py-1 overflow-y-scroll">
               <div div className="grid grid-cols-12 mt-[1px]" >
                  <div className="col-span-4 py-[3px] md:py-[1px] hover:bg-[#656565] rounded">
                     <div className="flex flex-col justify-center items-center">
                        <IoFilter className="text-white text-[24px] md:text-[17px] cursor-pointer" onClick={handleFilter} />
                        <p className="text-white text-[15px] md:text-[12px] cursor-pointer" onClick={handleFilter}>Filter Media</p>
                     </div>
                  </div>
                  <div className="col-span-4 py-[3px] md:py-[1px] hover:bg-[#656565] rounded">
                     <div className="flex flex-col justify-center items-center" onClick={() => navigate("/createdgroup/post")}>
                        <MdOutlinePostAdd className="text-white text-[24px] md:text-[17px] cursor-pointer" />
                        <p className="text-white text-[15px] md:text-[12px] cursor-pointer">Post</p>
                     </div>
                  </div>
                  <div className="col-span-4 py-[3px] md:py-[1px] hover:bg-[#656565] rounded">
                     <div className="flex flex-col justify-center items-center" onClick={() => navigate("/createdgroup/info")}>
                        <IoIosInformationCircle className="text-white text-[24px] md:text-[17px] cursor-pointer" />
                        <p className="text-white text-[15px] md:text-[12px] cursor-pointer">More Info</p>
                     </div>
                  </div>
               </div>

               <div className="grid grid-cols-12 mt-[1px]">
                  <div className="col-span-4 py-[3px] md:py-[1px] hover:bg-[#656565] rounded">
                     <div className="flex flex-col justify-center items-center" onClick={() => navigate("/createdgroup/moderators")}>
                        <FaCrown className="text-white text-[24px] md:text-[17px] cursor-pointer" />
                        <p className="text-white text-[15px] md:text-[12px] cursor-pointer">Moderators</p>
                     </div>
                  </div>
                  <div className="col-span-4 py-[3px] md:py-[1px] hover:bg-[#656565] rounded">
                     <div className="flex flex-col justify-center items-center" onClick={() => navigate("/createdgroup/member")}>
                        <FaUsersLine className="text-white text-[24px] md:text-[17px] cursor-pointer" />
                        <p className="text-white text-[15px] md:text-[12px] cursor-pointer">Members</p>
                     </div>
                  </div>
                  <div className="col-span-4 py-[3px] md:py-[1px] hover:bg-[#656565] rounded">
                     <div className="flex flex-col justify-center items-center" onClick={() => navigate("/createdgroup/chatroom")}>
                        <AiFillWechat className="text-white text-[24px] md:text-[17px] cursor-pointer" />
                        <p className="text-white text-[15px] md:text-[12px] cursor-pointer">Chatroom</p>
                     </div>
                  </div>
               </div>
            </div>
            :
            <div className="h-[15%] bg-[#232323] overflow-y-scroll">
               <div className="grid grid-cols-12">
                  <div className="col-span-12 flex justify-between items-center px-1">
                     <p className="text-white text-[18px] md:text-[14px] py-[1px] md:py-[2px]">Filter By :</p>
                     <div className="flex items-center justify-center gap-1" onClick={handleFilter}>
                        <IoIosCloseCircle className="text-[#2fcfe0] hover:text-[#5fefff] text-[16px] md:text-[13px] cursor-pointer" />
                        <p className="text-[#2fcfe0] hover:text-[#5fefff] font-bold text-[16px] md:text-[12px] cursor-pointer">Close Filter</p>
                     </div>
                  </div>
                  <div className="col-span-12">
                     <div className="grid grid-cols-12">
                        <div className="col-span-6 hover:bg-[#656565] bg-[#FF6600] text-[#ffffff] rounded ml-[4px] mr-[2px] transition ease-in-out duration-300">
                           <div className="flex justify-center items-center gap-1">
                              <IoIosTrendingUp className="text-[22px] md:text-[17px] cursor-pointer" />
                              <p className="text-[16px] md:text-[12px] py-[6px] md:py-1 cursor-pointer">Trending</p>
                           </div>
                        </div>
                        <div className="col-span-6 hover:bg-[#656565] bg-[#FF6600] text-[#ffffff] rounded ml-[2px] mr-[4px] transition ease-in-out duration-300">
                           <div className="flex justify-center items-center gap-1">
                              <RxOpenInNewWindow className="text-[22px] md:text-[17px] cursor-pointer" />
                              <p className="text-[16px] md:text-[12px] py-[6px] md:py-1 cursor-pointer">New</p>
                           </div>
                        </div>
                        <div className="col-span-6 hover:bg-[#656565] bg-[#FF6600] text-[#ffffff] rounded mt-1 ml-[4px] mr-[2px] transition ease-in-out duration-300">
                           <div className="flex justify-center items-center gap-1">
                              <MdOutlineVerticalAlignTop className="text-[22px] md:text-[17px] cursor-pointer" />
                              <p className="text-[16px] md:text-[12px] py-[6px] md:py-1 cursor-pointer">Top</p>
                           </div>
                        </div>
                        <div className="col-span-6 hover:bg-[#656565] bg-[#FF6600] text-[#ffffff] rounded mt-1 ml-[2px] mr-[4px] transition ease-in-out duration-300">
                           <div className="flex justify-center items-center gap-1">
                              <AiOutlineComment className="text-[22px] md:text-[17px] cursor-pointer" />
                              <p className="text-[16px] md:text-[12px] py-[6px] md:py-1 cursor-pointer">Most Commented</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         }
      </Fragment>
   )
}

export default CreatedGroupTab