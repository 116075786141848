import React, { useEffect, useRef, useState } from "react";
import ContactUsCard from "../../Component/ContactUs.jsx/ContactUsCard";
import FeedBackFromCard from "../../Component/ContactUs.jsx/FeedBackFromCard";
import helpHandImage from "../../../src/Assets/HelpPage/help 1.svg";
import newsReport from "../../../src/Assets/HelpPage/news-report 1.svg";
import MessageImg from "../../Assets/HelpPage/questions 1.svg";
import contentMarketing from "../../Assets/HelpPage/content-marketing 1.svg";
import { useNavigate } from "react-router-dom";

export default function ContactUsMain({ isWeb }) {
   const navigate = useNavigate();
   const [showFeedBack, setShowFeedBack] = useState(false);
   const cardRef = useRef(null);

   useEffect(() => {
      const handleClickOutside = (event) => {
         if (cardRef.current && !cardRef.current.contains(event.target)) {
            setShowFeedBack(false);
         }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   return (
      <section className="">
         <div className="flex flex-col justify-between items-start sticky top-0 z-40 bg-[#f9f9f9] w-full px-3 py-2 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px]">
            <p className="text-[red] text-[10px] w-full text-center font-bold">
               CC CONTACT US / FE / V2 / JULY 06, 2024
            </p>
            <p
               onClick={() => navigate("/home")}
               className={`${isWeb ? "text-[50px]" : "text-[22px]"} text-[#7e22ce] font-extrabold`}
            >
               CC
            </p>
            <div className="w-full">
               <p className="text-[22px] text-[#7e22ce] font-extrabold text-center uppercase">
                  Contact Us
               </p>
            </div>
         </div>

         <div className={`justify-center items-center gap-4 w-full ${isWeb ? "md:px-40 grid grid-cols-12" : "px-3"}`}>
            <div className={`${isWeb ? "col-span-12 md:col-span-6  w-full p-2" : "w-full"} mt-6 p-2 px-3`}>
               <ContactUsCard
                  isWeb={isWeb}
                  buttonName={"Visit FAQs"}
                  image={helpHandImage}
                  description={"Have any questions? We're here to assist you. We’ve put together some commonly asked questions to give you more information about CC and how to use it."}
                  navigatePath="/commingsoon"
               />
            </div>
            <div className={`${isWeb ? "col-span-12 md:col-span-6  w-full p-2" : "w-full"} mt-6 p-2 px-3`}>
               <ContactUsCard
                  isWeb={isWeb}
                  description={"Are you working on a 6644 story? If you have any queries, we have answers. On our media page, you will find the media enquiry form as well as our media kit."}
                  buttonName={"Visit Media"}
                  image={newsReport}
                  navigatePath="/media"
               />
            </div>
            <div className={`mt-6 p-2 px-3 relative ${isWeb ? "col-span-12 md:col-span-6 w-full p-3" : "w-full"}`}>
               <ContactUsCard
                  isWeb={isWeb}
                  buttonName={"Give Feedback"}
                  description={"Do you have more questions or any tips to share? Please send us your comments or report any problems you experienced. We read all feedback and address them as soon as possible."}
                  image={MessageImg}
                  handleClick={() => setShowFeedBack(!showFeedBack)}
               />
               {showFeedBack && (
                  <div className="absolute top-0 z-50" ref={cardRef}>
                     <FeedBackFromCard
                        handleCancel={() => setShowFeedBack(!showFeedBack)}
                     />
                  </div>
               )}
            </div>
            <div className={`${isWeb ? "col-span-12 md:col-span-6 w-full p-2" : "w-full"} mt-6 p-2 px-3`}>
               <ContactUsCard
                  isWeb={isWeb}
                  buttonName={"Give Feedback"}
                  description={"Want to receive a monthly email in your inbox with awesome tips on how to use CC resources and more?"}
                  image={contentMarketing}
                  subcription={true}
               />
            </div>
         </div>
      </section>
   );
}
