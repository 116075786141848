import { useNavigate } from "react-router-dom";
import { FaArrowAltCircleLeft, FaPlusCircle, FaSearch } from "react-icons/fa";

export const StoreTitle = () => {
   const navigate = useNavigate();

   return (
      <div className="pt-6 pl-2 pr-2">
         <div className="grid grid-cols-12 items-center">
            <div className="col-span-2 flex justify-start">
               <FaArrowAltCircleLeft
                  onClick={() => navigate(-1)}
                  className="text-white text-[18px] cursor-pointer"
               />
            </div>
            <div className="col-span-8 flex justify-center">
               <button className="bg-[#191919] hover:bg-[#2b2b2b] text-white rounded-lg text-[12px] font-extrabold px-[10px] py-[2px] cursor-pointer">
                  #Nike shoes
               </button>
            </div>
            <div className="col-span-2 flex justify-end">
               <FaPlusCircle
                  className="text-white text-[18px] cursor-pointer"
               />
            </div>
         </div>
         <div className="mt-2 relative">
            <FaSearch className="absolute left-[6px] top-1/2 transform -translate-y-1/2 cursor-pointer" />
            <input
               type="text"
               placeholder="Search for products"
               className="w-full outline-none rounded-full h-full py-[5px] pl-7 pr-3"
            />
         </div>
      </div>
   );
};
