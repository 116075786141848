import React from "react";
import { FollowingTab } from "../AccountPage/FollowingTab";

export default function FollowingList({datalist}) {
  return (
    <>
      <FollowingTab  datalist={datalist} />
    </>
  );
}
