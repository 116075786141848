import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import VideoCard from "../../Common/VideoCard";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Button } from "@material-tailwind/react";
import { RiCloseCircleFill } from "react-icons/ri";
import { MdCancelPresentation } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import useSelectVideo from "../../Hooks/useSelectVideo";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import PassWordButton from "./PrivateTab/PassWordButton";
import { getPrivateVideos } from "../../Redux/ApiSlice/AccountProfileSlice";

export function PrivateTab() {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const selectVideo = useSelectVideo();
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile, private_Videos } = ProfileDetails;

   const [password, setPassword] = useState("");
   const [inputValue, setInputValue] = useState("");
   const [showPrivate, setShowPrivate] = useState(false);
   const [showPassword, setShowPassword] = useState(false);
   const [showPopup, setShowPopup] = useState(false);
   const menuRef = useRef(null);
   const privatePassword = localStorage.getItem("Privatepassword");

   const handleButtonClick = (value) => {
      if (inputValue.length < 4) {
         setInputValue(inputValue + value);
      }
   };

   const handleInputChange = (event) => {
      const value = event.target.value;
      if (value.length <= 4) {
         setInputValue(value);
         setPassword(value);
      }
   };

   const handleClear = () => {
      setInputValue(inputValue.slice(0, -1));
   };

   const handleShowPassword = () => setShowPassword(!showPassword);

   const handleDelete = () => {
      localStorage.removeItem("Privatepassword");
      navigate("/account/private/empty");
   };

   useEffect(() => {
      if (inputValue.length === 4 && inputValue == privatePassword) {
         setShowPrivate(true);
      }
      return () => setShowPrivate(false);
   }, [inputValue]);

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setShowPopup(null); // Close menu if clicked outside
      }
   };

   const handleSelectVideo = async (id) => {
      selectVideo(id, private_Videos);
   };

   useEffect(() => {
      if (inputValue == privatePassword) {
         dispatch(getPrivateVideos(User_profile.id));
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [inputValue]);

   return (
      <div className="h-full flex flex-col">
         {!showPrivate && (
            <>
               <div className="w-full flex justify-center items-center mb-4 mt-4 relative ">
                  <div className="relative">
                     {showPassword ? (
                        <FiEye
                           color="black"
                           className="absolute  right-2 top-[25%] cursor-pointer"
                           onClick={() => handleShowPassword()}
                        />
                     ) : (
                        <FiEyeOff
                           color="black"
                           onClick={() => handleShowPassword()}
                           className="absolute  right-2 top-[25%] cursor-pointer"
                        />
                     )}
                     <input
                        type={showPassword ? "text" : "password"}
                        className="w-32 h-9 border border-gray-300 text-center outline-none rounded-tl rounded-bl"
                        value={inputValue}
                        onChange={handleInputChange}
                     />
                  </div>
                  {!showPopup ? (
                     <BiDotsHorizontalRounded
                        className="absolute right-0 top-[6px] text-[1.5rem] cursor-pointer"
                        color="white"
                        onClick={() => setShowPopup(true)}
                     />
                  ) : (
                     <RiCloseCircleFill className="text-red-700  absolute right-0 top-0 text-[1.5rem] cursor-pointer flex items-center" />
                  )}
                  {showPopup && (
                     <div
                        ref={menuRef}
                        className="absolute h-auto bg-white w-[7rem] shadow-[rgba(0,_0,_120,_0.4)_0px_30px_90px] text-[15px] top-7 right-0 z-20 rounded"
                     >
                        <p
                           onClick={() => navigate("/account/private/forgotpassword")}
                           className="flex items-center gap-2 mb-[3px] cursor-pointer text-black font-bold px-2 py-2 hover:text-white hover:bg-blue-gray-700  text-[10px] "
                        >
                           Forgot Password
                        </p>
                        <p
                           onClick={() => navigate("/account/private/resetpassword")}
                           className="flex items-center gap-2 mb-[3px] cursor-pointer text-black font-bold px-2 py-2 hover:text-white hover:bg-blue-gray-700 text-[10px]"
                        >
                           Reset Password
                        </p>
                        <p
                           onClick={() => handleDelete()}
                           className="flex items-center gap-2 cursor-pointer text-black font-bold px-2 py-2 hover:text-white hover:bg-blue-gray-700 text-[10px]"
                        >
                           Delete Private
                        </p>
                     </div>
                  )}
                  <div>
                     <Button
                        className="rounded-none bg-red-600 p-[18px] rounded-tr rounded-br relative"
                        onClick={handleClear}
                     >
                        <MdCancelPresentation 
                           className="absolute text-[18px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" 
                        />
                     </Button>
                  </div>
               </div>
               <div className="flex flex-wrap justify-center items-center">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((num) => (
                     <div key={num} className="p-2">
                        <PassWordButton value={num} onClick={handleButtonClick} />
                     </div>
                  ))}
               </div>
            </>
         )}
         {showPrivate && private_Videos.length && (
            <>
               <div className="w-full flex justify-center items-center mt-2">
                  <div className="flex h-[2rem] w-[90%] bg-[aliceblue]  justify-center items-center rounded-md overflow-hidden">
                     <div className="w-[10%] flex">
                        <FaSearch className="pl-2 w-[20px] h-[20px]" />
                     </div>
                     <div className="w-[100%] h-[80%]">
                        <input
                           className="w-full placeholder:text-sm font-medium outline-none "
                           type="text"
                           placeholder={`Find Your Private `}
                        />
                     </div>
                  </div>
               </div>
               <div className="grid grid-cols-2 pt-1 gap-3 overflow-scroll no-scrollbar  px-2">
                  {private_Videos?.map((item) => (
                     <VideoCard
                        belowDecs={true}
                        threeDots={false}
                        id={item.id}
                        video={item.video_url}
                        likes_count={item.likes_count}
                        avatar_url={item.created_by.avatar_url}
                        body={item.body}
                        views={item.views}
                        selectVideo={handleSelectVideo}
                     />
                  ))}
               </div>
            </>
         )}
         {showPrivate && !private_Videos.length && (
            <div className="h-full flex justify-center items-center">
               <h1 className="text-gray-500 font-bold text-[12px]">
                  No Private Videos
               </h1>
            </div>
         )}
      </div>
   );
}
