import { useNavigate } from "react-router-dom";
import SecretSauceButton from "../SecretSauceButton/SecretSauceButton";

export default function EnterOtp() {
  const navigate = useNavigate();
  const handleClick = (value) => {
    navigate(`/${value}`);
  };

  return (
    <main className="w-full h-screen flex justify-center items-center md:hidden sm:block">
      <div className=" h-screen w-full relative ">
        <div className="flex justify-end bg-[#fff] flex-col h-[48%] items-center  border-black">
          <div className="flex justify-center font-semibold text-sm  bg-[#fff] items-center ">
            <p className="text-[#ff3b3b]  absolute top-0 w-full text-center font-extrabold text-[14px]">
              {" "}
              CC LOST MY PASSWORD OTP - FE - V3 - APRIL 03, 2024
            </p>
          </div>
          <p className="text-[5rem]">😊</p>
          <h1 className="text-[#00A3FF] text-[6rem]  font-bold">CC</h1>
          <h3 className=" font-extrabold text-xl">Enter OTP</h3>
          <p className=" text-xl font-semibold text-center w-full m-4 px-[4%]">
            We sent a one-time passcode to{" "}
            <span className="text-[#00A3FF]">marydavis@gmail.com</span>. Please
            enter your code below
          </p>
        </div>
        <div className="overflow-auto w-full h-[35%] bg-[#fff] px-4 pt-4 ">
          <div className=" h-full p-3 flex flex-col items-center">
            <form className="w-full">
              <label className="text-base font-bold mb-2">
                {" "}
                marydavis@gmail.com
              </label>
              <div className=" h-[4rem] flex flex-row gap-3 items-center">
                <input
                  className=" w-2/12 bg-gray-100  h-3/4 sm:h-1/4"
                  type="text"
                  placeholder=""
                  maxLength={1}
                />
                <input
                  className=" w-2/12 bg-gray-100  h-3/4"
                  type="text"
                  placeholder=""
                  maxLength={1}
                />
                <input
                  className="w-2/12 bg-gray-100  h-3/4"
                  type="text"
                  placeholder=""
                  maxLength={1}
                />
                <input
                  className="w-2/12 bg-gray-100  h-3/4"
                  type="text"
                  placeholder=""
                  maxLength={1}
                />
                <input
                  className="w-2/12 bg-gray-100  h-3/4"
                  type="text"
                  placeholder=""
                  maxLength={1}
                />
                <input
                  className="w-2/12 bg-gray-100  h-3/4"
                  type="text"
                  placeholder=""
                  maxLength={1}
                />
              </div>
              <div className="flex justify-between mt-2 mb-2">
                <p className="text-[#00A3FF] text-[12px] font-medium">
                  Resend OTP
                </p>
                <p
                  className="text-[#00A3FF] text-[12px] font-medium z-20"
                  onClick={() => navigate("/changepassword")}
                >
                  Re-Entry My Email
                </p>
              </div>
            </form>
            <div className="flex justify-center items-center">
              <button
                className="bg-black text-[#fff] px-5 py-2 rounded-md font-bold"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
        </div>

        <SecretSauceButton />
        <div className=" flex justify-center items-center h-[10%] bg-[black]">
          <button
            className="rounded-[2rem] h-2/5 w-2/5 bg-[#D9D9DE]  font-bold text-base"
            onClick={() => handleClick("register")}
          >
            Register
          </button>
        </div>
      </div>
    </main>
  );
}
