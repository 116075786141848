import React, { useEffect, useRef, useState } from "react";
import { BsFillMicMuteFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { MdHideSource } from "react-icons/md";
import { TbDeviceMobileOff, TbDotsVertical } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postRead } from "../../Redux/ApiSlice/NotificationSlice";

export function UpdatesTab({ setEmptypage, grouppage }) {
   const UpdatesData = [
      {
         title: "Today",
         listofData: [
            {
               id: 0,
               text: "Jack Devis “commented” on your post “Really it is cool video” I enjoyed it ......",
               time: "10:12 am",
               icon: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            },
            {
               id: 1,
               text: "Sarah Smith “liked” your photo",
               time: "11:45 am",
               icon: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            },
            {
               id: 2,
               text: "David Brown “shared” your article ..... ✌️✌️✌️",
               time: "12:30 pm",
               icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAnsU6UcDxvQhnoEVZq7jS12J_Ebp7EfOzow&usqp=CAU",
            },
         ],
      },
      {
         title: "Yesterday",
         listofData: [
            {
               id: 3,
               text: "Neena Simon “replied” to your message “I think we should meet in this week ..............”  ",
               time: "06:12 am",
               icon: "https://images.unsplash.com/photo-1493050261174-9e48e90f6773?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            },
            {
               id: 4,
               text: "John Doe “tagged” you in a photo ....................................",
               time: "07:30 am",
               icon: "https://images.unsplash.com/photo-1640960543409-dbe56ccc30e2?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            },
            {
               id: 5,
               text: "Emma Watson “reacted” to your story ............................",
               time: "08:45 am",
               icon: "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            },
         ],
      },
   ];

   const NotificationData = useSelector((state) => state.Notification);
   const accountProfile = useSelector((state) => state.AccountProfile);
   const { loading, notificationList } = NotificationData;
   const { User_profile } = accountProfile;
   const [menuCommentId, setMenuCommentId] = useState(null);
   const menuRef = useRef(null);
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [searchTerm, setSearchTerm] = useState("");

   const handleChange = (event) => {
      setSearchTerm(event.target.value);
   };

   const results = !searchTerm
      ? notificationList
      : notificationList.filter((item) =>
         item?.body.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );

   const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
         setMenuCommentId(null); // Close menu if clicked outside
      }
   };

   const toggleMenu = (commentId) => {
      if (menuCommentId === commentId) {
         setMenuCommentId(null); // Close menu if already open
      } else {
         setMenuCommentId(commentId); // Open menu for this comment
      }
   };

   const handleNavigate = (id, messageId) => {
      if (User_profile.id == id) {
         dispatch(postRead(messageId));
         return null;
      } else {
         dispatch(postRead(messageId));
         return navigate(`/otheruseraccount/${id}`);
      }
   };

   useEffect(() => {
      if (grouppage === false) setEmptypage("");

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   return (
      <>
         {notificationList.length && (
            <div className="flex h-[2rem] justify-center items-center rounded-md overflow-hidden">
               <div className="bg-[aliceblue] flex h-[2rem] w-full justify-center items-center rounded-md overflow-hidden">
                  <div className="w-[10%] flex justify-center">
                     <FaSearch className="cursor-pointer" />
                  </div>
                  <div className="w-full h-[80%]">
                     <input
                        type="text"
                        className="w-full placeholder:text-sm font-medium outline-none pr-2 text-[#000]"
                        onChange={handleChange}
                        placeholder={`Find Updates`}
                     />
                  </div>
               </div>
            </div>
         )}
         {results.length ? (
            <div className="no-scrollbar ">
               <div className="overflow-scroll no-scrollbar">
                  {results?.map((item) => (
                     <div className="pt-2">
                        <div className="grid grid-cols-12 items-center">
                           <div className="col-span-2">
                              <img
                                 alt="img"
                                 src={item.created_by?.avatar_url}
                                 onClick={() => handleNavigate(item.created_by.id, item.id)}
                                 className="rounded-full h-8 w-8 object-cover cursor-pointer"
                              />
                           </div>
                           <div className="col-span-7 ml-[-10px]">
                              <p
                                 onClick={() => handleNavigate(item.created_by.id, item.id)}
                                 className="font-bold text-[10px] text-[#fff] leading-3 cursor-pointer"
                              >
                                 {item.body}
                              </p>
                           </div>
                           <div className="col-span-2 flex items-center justify-center">
                              <p className="font-bold text-[8px] text-[#00A3FF]">
                                 {item?.time_since_created}
                              </p>
                           </div>
                           <div className="col-span-1 mr-[-6px] flex items-center justify-end">
                              <TbDotsVertical
                                 color="white"
                                 className="cursor-pointer"
                                 onClick={() => toggleMenu(item?.id)}
                              />
                           </div>
                           {menuCommentId === item?.id && (
                              <div
                                 ref={menuRef}
                                 className="absolute z-30 bg-[#e7e7e7] w-[8rem] p-1 rounded right-3 -mt-[2.5rem] overflow-scroll no-scrollbar"
                              >
                                 <p className="flex items-center gap-2 mb-[3px] text-[#000] hover:text-[#bf3131] text-[12px] font-bold cursor-pointer">
                                    <BsFillMicMuteFill className="text-[15px]" /> Mute
                                 </p>
                                 <p className="flex items-center gap-2 mb-[3px] text-[#000] hover:text-[#bf3131] text-[12px] font-bold cursor-pointer">
                                    <MdHideSource className="text-[15px]" /> Hide updates
                                 </p>
                                 <p className="flex items-center gap-2 mb-[3px] text-[#000] hover:text-[#bf3131] text-[12px] font-bold cursor-pointer">
                                    <TbDeviceMobileOff className="text-[15px]" /> Turn off Updates
                                 </p>
                              </div>
                           )}
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         ) : (
            <div className="h-full">
               <p className="h-[20%] text-[12px] font-bold text-[#fff] mt-1">Today</p>
               <div className="h-[80%] flex justify-center items-center">
                  <p className="text-[10px] text-[#c0b8b8] -mt-16 font-semibold">
                     No Updates Yet
                  </p>
               </div>
            </div>
         )}
      </>
   );
}
