import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REACT_APP_MAIN_URL } from "../../API/MainUrl";

export const lostPassword = createAsyncThunk("lostPassword", async (data) => {
  try {
    const formData = new FormData();
    formData.append("email", data.email);
    const response = await axios.post(`${REACT_APP_MAIN_URL}api/forgot-password/`, formData);
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});

export const lostPasswordSlice = createSlice({
  name: "lostPassword",
  initialState: {
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // lostPassword action promises
    builder.addCase(lostPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(lostPassword.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(lostPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default lostPasswordSlice.reducers;
