import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const createPin = createAsyncThunk("createPin", async (data) => {
  try {
    const response = await axiosInstance.post(`/post/set-pin/`, data);
    const result = response.data;
    return result;
  } catch (error) {
    return error.response.data;
  }
});

export const AccountPrivate = createSlice({
  name: "AccountPrivate",
  initialState: {
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // createPin action promises
    builder.addCase(createPin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPin.fulfilled, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(createPin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default AccountPrivate.reducer;
