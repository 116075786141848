import React from 'react'

export default function UploadStream() {
  return (
    <div>


        
    </div>
  )
}
