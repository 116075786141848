import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";

export const SearchData = createAsyncThunk("SearchData", async (data) => {
  const response = await axiosInstance.post(`/search/`, data);
  try {
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
});

export const SearchSlice = createSlice({
  name: "SearchSlice",
  initialState: {
    SearchResult: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // HomepageVideo action promises
    builder.addCase(SearchData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(SearchData.fulfilled, (state, action) => {
      state.SearchResult = action.payload;
      state.loading = false;
    });
    builder.addCase(SearchData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default SearchSlice.reducer;
