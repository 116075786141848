import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { sugestedProducts } from "../../Datas/Datas";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1,
  },
};

const JustInProduct = () => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="px-4 mt-2">
      <div className="flex justify-between items-center">
        <p
          className="text-[#fff] font-bold text-[20px] md:text-[16px] underline cursor-pointer"
          onClick={() => setShowMore(!showMore)}
        >
          Just In
        </p>
        <div className="text-[#fff] ">
          <FaArrowAltCircleRight className="hover:text-gray-500 text-[20px]" />
        </div>
      </div>
      <div className="mt-3 -mx-[5px]">
        {!showMore && (
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          >
            {sugestedProducts.map((data) => (
              <div className="px-[5px]" key={data.id}>
                <img
                  src={data.imgUrl}
                  alt={data.name}
                  onClick={() => navigate("/shopdetails")}
                  className="img-shadow rounded-2xl object-cover bg-[#fff] cursor-pointer"
                />
                <div className="text-[#fff] mt-1 px-1 text-start">
                  <p className="line-clamp-1 text-[11px]  cursor-pointer">
                    {data.name}
                  </p>
                  <p className="line-clamp-1 text-[11px]  cursor-pointer">
                    {data.description}
                  </p>
                  <p
                    className="text-[20px] md:text-[14px] font-bold cursor-pointer"
                    onClick={() => navigate("/shopdetails")}
                  >
                    ${data.price}
                  </p>
                </div>
              </div>
            ))}
          </Carousel>
        )}
        {showMore && (
          <div className="grid grid-cols-3">
            {sugestedProducts.map((data) => (
              <div className="px-[5px] col-span-1" key={data.id}>
                <img
                  src={data.imgUrl}
                  alt={data.name}
                  onClick={() => navigate("/shopdetails")}
                  className="img-shadow rounded-2xl object-cover bg-[#fff] cursor-pointer"
                />
                <div className="text-[#fff] mt-1 px-1 text-start">
                  <p className="line-clamp-1 text-[11px]  cursor-pointer">
                    {data.name}
                  </p>
                  <p className="line-clamp-1 text-[11px]  cursor-pointer">
                    {data.description}
                  </p>
                  <p
                    className="text-[20px] md:text-[14px] font-bold cursor-pointer"
                    onClick={() => navigate("/shopdetails")}
                  >
                    ${data.price}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default JustInProduct;
