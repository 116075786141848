import React from "react";
import { FollowersTab } from "../AccountPage/FollowersTab";

export default function FollowersList({datalist , isOtherAccount}) {
  return (
    <>
      <FollowersTab datalist={datalist}  isOtherAccount={isOtherAccount}/>
    </>
  );
}
