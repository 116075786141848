import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Button } from "@material-tailwind/react";
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function MutualFriendsList() {
    const navigate = useNavigate();
    const messagesChatData = useSelector((state) => state.messages);
    const { individualMessages } = messagesChatData;
    const { users } = individualMessages;
    const MutualFriends = [
        {
            id: 9,
            name: "Isabella Rodriguez",
            joined: true,
            following: true,

            userImg:
                "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 10,
            name: "James Wilson",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 1,
            name: "Emily Johnson",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1534008757030-27299c4371b6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 2,
            name: "Jacob Smith",
            joined: true,
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 3,
            name: "Olivia Brown",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1503104834685-7205e8607eb9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 4,
            name: "Ethan Martinez",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1566492031773-4f4e44671857?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 7,
            name: "Sophia Garcia",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 5,
            name: "Ava Anderson",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1560238970-cc0ae073d3a5?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 6,
            name: "Michael Thompson",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },

        {
            id: 8,
            name: "William Davis",
            following: true,
            userImg:
                "https://images.unsplash.com/photo-1489980557514-251d61e3eeb6?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
    ];
    return (
        <div className="relative h-full w-full overflow-hidden bg-black">
            <p className="absolute top-0 text-center text-[8px] text-red-900 w-full font-bold">
                CC MESSAGES CHAT USER FRIENDS PAGE , FE,V1 , JULY 02 , 2024
            </p>
            <div className="grid grid-cols-12 p-4">
                <div className="col-span-2">
                    <IoCaretBackCircleSharp
                        className="text-[22px] text-white cursor-pointer"
                        onClick={() => navigate(-1)}
                    />
                </div>
                <div className="col-span-8 text-white font-bold text-center">
                    Mutual Friends
                </div>
                <div className="col-span-2"></div>
                <div className="col-span-12 flex flex-col justify-between">
                    <div className="flex flex-col justify-center items-center">
                        <p className="mt-1 text-white font-bold mb-2">With</p>
                        <Avatar
                            src={users[0]?.avatar_url}
                            alt="avatar"
                            className="h-24 w-24 object-cover"
                        />
                        <p className="mt-1 text-white font-bold">
                            {users[0]?.first_name} {users[0]?.last_name}
                        </p>
                    </div>
                </div>
            </div>
            <hr className=" text-gray-600 h-1" />
            <div className="h-[-webkit-fill-available] w-full overflow-y-scroll absolute bg-black">
                {MutualFriends.map((data) => (
                    <>
                        <div
                            className="grid grid-cols-12 items-center pt-2 pb-2 px-4 hover:bg-[#1b1b1b] cursor-pointer"
                            key={data.id}
                        >
                            <div className="col-span-2">
                                <img
                                    alt={data.name}
                                    src={data.userImg}
                                    className="h-8 w-8 rounded-full object-cover"
                                />
                            </div>
                            <div className="col-span-5 ml-[-2px]">
                                <p className="text-[12px] text-white leading-[14px]">
                                    {data.name}
                                </p>
                            </div>
                            <div className="col-span-5 flex justify-end gap-2 items-center">
                                <Button className="bg-white text-[black] text-[11px] px-[8px] py-[5px] rounded font-bold w-[70px] hover:bg-[#dedede]">
                                    Unfollow
                                </Button>
                            </div>
                        </div>
                        <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-900 border-0" />
                    </>
                ))}
            </div>
        </div>
    );
}
