import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogBody, DialogFooter } from "@material-tailwind/react";

export const DeleteSavedFolderModal = ({ handleOpen, open, deleteItem, deleteCreatedFolder, selectFolderId }) => {
   const [folderName, setFolderName] = useState("");

   const handleSubmit = () => {
      deleteCreatedFolder(selectFolderId);
   };

   useEffect(() => {
      setFolderName(deleteItem[0]?.name);
   }, [deleteItem]);


   return (
      <Dialog open={open} handler={handleOpen} size="sm" className="rounded-lg">
         <DialogBody className="text-center text-base font-semibold">
            Are you sure that you want to Delete
            <p>"{`${folderName}`}"</p>
         </DialogBody>

         <DialogFooter className="w-full flex gap-5 justify-center items-center">
            <Button
               color="black"
               onClick={() => handleSubmit()}
            >
               <span>YES</span>
            </Button>
            <Button
               color="red"
               onClick={() => handleOpen(null)}
            >
               <span>NO</span>
            </Button>
         </DialogFooter>
      </Dialog>
   );
};
