import React, { Fragment } from "react"
import CreateGroup from "../../Component/grouppage/CreateGroup"

const CreateGroupPage = () => {
   return (
      <Fragment>
         <p className="absolute w-full text-red-700 uppercase text-[10px] text-center">
            cc create group / fe / v1 / june 19, 2024
         </p>

         <div className="pt-3 bg-black">
            <CreateGroup />
         </div>
      </Fragment>
   )
}

export default CreateGroupPage