import React from "react";
import CreatePassword from "./Private/CreatePassword";
import AccountTab from "../../Component/AccountPage/AccountTab";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";

const PrivateCreate = () => {
   return (
      <div className="bg-[#000] overflow-hidden h-full relative ">
         <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            CC ACCOUNT PRIVATE PAGE-FE - V11 / JULY 1, 2024
         </p>
         <div className="">
            <AccountProfileSection />
         </div>
         <AccountTab activeTab={"Private"} />
         <div className="overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
            <CreatePassword />
         </div>
      </div>
   );
};
export default PrivateCreate;
