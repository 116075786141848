// useSelectVideo.js
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectVideoAll } from '../Redux/ApiSlice/WatchVideoSlice';

const useSelectVideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectVideo = async (id, videos) => {
    await dispatch(selectVideoAll(videos));
    navigate(`/watchvideo/${id}`);
  };

  return selectVideo;
};

export default useSelectVideo;