
import { useDispatch } from "react-redux";
import { Avatar } from "@material-tailwind/react";
import { FaCheckCircle, FaSearch } from "react-icons/fa";
import emptyFollowers from "../../Assets/YourAccountIcons/emptyFollowers.png";
import { UserAccountFollow, UserAccountFollowerlist, UserAccountFollowinglist } from "../../Redux/ApiSlice/AccountProfileSlice";

export const FollowersTab = ({ datalist }) => {
   const dispatch = useDispatch();
   const handleFollowFun = async (userId) => {
      await dispatch(UserAccountFollow(userId));
      await dispatch(UserAccountFollowerlist());
      await dispatch(UserAccountFollowinglist());
   };

   return (
      <section className="h-full">
         <div className="flex h-[5%] justify-center items-center w-full mb-2">
            <div className="flex w-[94%] bg-[aliceblue] justify-center items-center rounded-md overflow-hidden">
               <div className="w-[10%] flex">
                  <FaSearch className="pl-2 w-[20px] h-[20px] cursor-pointer" />
               </div>
               <div className="w-[100%] h-[85%]">
                  <input
                     type="text"
                     placeholder={`Find Your Followers`}
                     className="w-full placeholder:text-sm font-medium outline-none pl-1 pr-2 py-1"
                  />
               </div>
            </div>
         </div>
         {datalist?.length ? (
            <>
               <div className="h-[90%] p-0  overflow-scroll no-scrollbar">
                  {datalist?.map((item) => (
                     <div className="h-14 border items-center flex border-black border-y-2 shadow-gl border-r-0 border-l-0 bg-[#fff] hover:bg-[cornsilk] ">
                        <div className="flex justify-between w-full px-2" key={item.id}>
                           <div className="flex items-center col-span-9 rounded-full">
                              <div className="relative">
                                 <Avatar
                                    size="xs"
                                    alt="avatar"
                                    className="h-8 w-8 relative"
                                    src={
                                       item.follower_avatar_url
                                          ? `${item.follower_avatar_url}`
                                          : "https://thtsusa.com/user_data/media/user_icon.png"
                                    }
                                 />

                                 {true && (
                                    <FaCheckCircle
                                       color="green"
                                       className="absolute -right-1 bottom-5 w-3 h-3"
                                    />
                                 )}
                              </div>

                              <p className="px-2 text-[11px] first-letter:uppercase font-bold text-blacks">
                                 {item.follower_first_name} {item.follower_last_name}
                              </p>
                           </div>
                           <div className="flex justify-center items-center">
                              <button
                                 onClick={() => handleFollowFun(item.follower_id)}
                                 className="bg-black hover:bg-[#000000e7] text-[#fff] rounded-md text-[10px] h-7 py-1 px-3 font-bold"
                              >
                                 Follow
                              </button>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>

            </>
         ) : (
            <div className="flex h-[50%] w-full  justify-center items-center flex-col relative">
               <img src={emptyFollowers} alt="" className="w-32 h-32" />
               <p className="text-[10px] text-[#c0b8b8] font-semibold">
                  No Following yet
               </p>
            </div>
         )}
      </section>
   );
};
