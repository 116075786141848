import React, { Fragment, useEffect, useRef, useState } from "react";
import { LuDot } from "react-icons/lu";
import { FaUser } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { RiEditFill } from "react-icons/ri";
import { TbMessage2X } from "react-icons/tb";
import { BiSolidReport } from "react-icons/bi";
import { BiSolidMessage } from "react-icons/bi";
import { Drawer } from "@material-tailwind/react";
import { BiSolidMessageDetail } from "react-icons/bi";
import { HiDotsVertical } from "react-icons/hi";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import { MdDeleteSweep, MdOutlineDeleteForever } from "react-icons/md";
import {
   HomepageVideo,
   HomepageVideoReplayCommentDelete,
} from "../../Redux/ApiSlice/HomePageVideoSlice";
import { useNavigate } from "react-router-dom";

export default function Comments(props) {
   const {
      openBottomComments,
      closeDrawerBottomCommments,
      comments,
      handleChangeComment,
      menuCommentId,
      emptyComments,
      menuRef,
      handleChangeEditComment,
      toggleMenu,
      handleChangeDeleteComment,
      setMenuCommentId,
      originalURL,
      createCommet,
      handleSubmitComment,
      handleChangeRepleyComment,
      repleyitemId,
      handleOpenreplay,
      replay,
      setReplay,
   } = props;
   const inputRef = useRef(null);
   const dispatch = useDispatch();
   const navigate = useNavigate()
   const [popupPosition, setPopupPosition] = useState("bottom");
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const {
      User_profile,
   } = ProfileDetails;

   const handleDeleteComment = async (Id) => {
      await dispatch(HomepageVideoReplayCommentDelete(Id));
      dispatch(HomepageVideo());
   };
   const focusInput = () => {
      inputRef.current.focus();
   };

   const handleGoAccount = (UserId) => {
      if (User_profile.id === UserId) {
         navigate(`/account`)
      }
      else {
         navigate(`/otheruseraccount/${UserId}`)
      }
   }


   useEffect(() => {
      if (replay) {
         focusInput();
      }
      if (menuCommentId) {
         window.scrollTo(0, 0);
      }
      return () => setReplay(false);
   }, [replay, menuCommentId]);

   useEffect(() => {
      if (menuCommentId && menuRef.current) {
         const menuBounds = menuRef.current.getBoundingClientRect();
         const viewportHeight = window.innerHeight;
         // Check if there's enough space below for the popup; if not, show it above
         if (viewportHeight - menuBounds.bottom < 200) {
            setPopupPosition("top");
         } else {
            setPopupPosition("bottom");
         }
      }
   }, [menuCommentId]);
   return (
      <Drawer
         placement="bottom"
         style={{ zIndex: 1 }}
         open={openBottomComments}
         onClose={closeDrawerBottomCommments}
         className={`${!openBottomComments && "hidden"}`}
         size={window.width < 500 ? 320 : 250}
      >
         <Fragment>
            <div className="bg-[black] h-full w-full relative ">
               <h2 className="text-[red] felx justify-center font-bold text-[10px] w-full text-center ">
                  CC COMMENT PAGE / FE V2 / JULY 04, 2024
               </h2>

               <div className="bg-[black] md:h-[25vh] lg:h-[26vh] h-[23vh] absolute pt-0 w-full overflow-y-scroll no-scrollbar">
                  {comments?.map((data) => (
                     <Fragment key={data?.id}>
                        <div className="grid grid-cols-12 hover:bg-[#0b0b0b] py-2 px-2 relative">
                           <div className="col-span-12">
                              <div className="grid grid-cols-10 gap-1 items-start">
                                 <div className="col-span-1">
                                    <img
                                       alt="img"
                                       src={`${data?.created_by?.avatar_url}`}
                                       className="h-[27px] w-[27px] rounded-full object-cover"
                                    />
                                 </div>
                                 <div className="col-span-9 ml-[6px]">
                                    <div className="relative flex justify-between items-center">
                                       <p className="text-[10px] text-[#916767] lowercase flex items-center gap-1">
                                          {`@${data?.created_by?.first_name}1234`} <LuDot className="text-white" /> 4h
                                          ago
                                       </p>
                                       <HiDotsVertical
                                          onClick={() => toggleMenu(data?.id)}
                                          className="text-[14px] hover:text-[#ffffff] text-[#4a4a4a] cursor-pointer"
                                       />

                                       {menuCommentId === data?.id && (
                                          <div
                                             ref={menuRef}
                                             //   ref={menuRef}
                                             // className={`absolute text-[white] bg-gray-900 w-[9.5rem] px-4 py-2 text-[15px] z-50 right-0 ${popupPosition === "top"
                                             //    ? "bottom-full mb-2"
                                             //    : "top-full mt-2"
                                             //    }`}
                                             className="absolute text-[white] bg-gray-900 w-[9.5rem] px-2 py-1 text-[15px] top-0 right-0 rounded-md  z-20"
                                          >
                                             <p className="flex items-center gap-2 mb-[3px] cursor-pointer" onClick={() => handleGoAccount(data?.created_by?.id)}>
                                                <FaUser /> Go to Account
                                             </p>
                                             <p
                                                className="flex items-center gap-2 mb-[3px] cursor-pointer"
                                                onClick={() =>
                                                   handleChangeRepleyComment(data.id, data.body)
                                                }
                                             >
                                                <BiSolidMessage /> Reply
                                             </p>
                                             <p className="flex items-center gap-2 mb-[3px] cursor-pointer">
                                                <BiSolidReport /> Report
                                             </p>
                                             {User_profile.id === data.created_by.id && <>
                                                <p
                                                   className="flex items-center gap-2 mb-[3px] cursor-pointer"
                                                   onClick={() =>
                                                      handleChangeEditComment(data.id, data.body)
                                                   }
                                                >
                                                   <RiEditFill /> Edit
                                                </p>
                                                <p
                                                   className="flex items-center gap-2 mb-[3px] cursor-pointer"
                                                   onClick={() =>
                                                      handleChangeDeleteComment(data.id)
                                                   }
                                                >
                                                   <MdDeleteSweep /> Delete
                                                </p>
                                             </>
                                             }
                                             <p
                                                className="flex items-center gap-2 mb-[3px] cursor-pointer"
                                                onClick={() => setMenuCommentId(null)}
                                             >
                                                <TbMessage2X /> Close
                                             </p>
                                          </div>
                                       )}
                                    </div>
                                    <p className="text-[15px] md:text-[12px] text-white break-words">
                                       {data?.body}
                                    </p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-span-12">
                              <div className="grid grid-cols-10">
                                 <div className="col-span-1"></div>
                                 <div className="col-span-9 flex items-center justify-start gap-[4px] mt-[5px] ml-[6px]">
                                    <div className="flex flex-col">
                                       <AiFillLike className="text-white text-[14px] hover:text-[#42d140] cursor-pointer" />
                                       <p className="text-white text-[5px] text-center">{data?.like}12k</p>
                                    </div>
                                    <div className="flex flex-col">
                                       <AiFillDislike className="text-white text-[14px] hover:text-[#ed4848] cursor-pointer" />
                                       <p className="text-white text-[5px] text-center">
                                          {data?.dislike}1k
                                       </p>
                                    </div>
                                    <div className="flex flex-col">
                                       <BiSolidMessageDetail className="text-white text-[14px] hover:text-[#3fbccf] cursor-pointer" />
                                       <p className="text-white text-[5px] text-center">
                                          {data?.comment} 12k
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           {data?.replies?.length > 0 && (
                              <div className="col-span-12 ml-7 mt-[4px]">
                                 {(!repleyitemId || repleyitemId !== data.id) && (
                                    <p
                                       onClick={() => handleOpenreplay(data.id)}
                                       className="text-blue-500 text-[10px] cursor-pointer font-bold ml-2 hover:text-blue-700"
                                    >
                                       view reply
                                    </p>
                                 )}
                                 {repleyitemId == data.id && (
                                    <p
                                       onClick={() => handleOpenreplay(null)}
                                       className="text-blue-500 text-[10px] cursor-pointer font-bold ml-2 hover:text-blue-700"
                                    >
                                       hide reply
                                    </p>
                                 )}
                              </div>
                           )}
                        </div>

                        <div className="h-[0.5px] w-full bg-[#0e0e0e]" />

                        {data?.replies.map((item) => {
                           return (
                              <>
                                 {repleyitemId == data.id && (
                                    <div className="grid grid-cols-12 py-2 px-2 pl-[38px] bg-[#0d0d0d]">
                                       <div className="col-span-12">
                                          <div className="grid grid-cols-12 items-start">
                                             <div className="col-span-2 flex items-center justify-center">
                                                <img
                                                   alt="img"
                                                   className="h-[30px] w-[30px] rounded-full object-cover"
                                                   src={`${item?.created_by?.avatar_url}`}
                                                />
                                             </div>
                                             <div className="col-span-10">
                                                <div className="flex justify-between items-center">
                                                   <p className="text-[10px] text-[#4a4a4a] lowercase flex items-center gap-1">
                                                      @username456{" "}
                                                      <LuDot className="text-white" /> 1h ago
                                                   </p>
                                                   <HiDotsVertical className="text-[14px] hover:text-[#ffffff] text-[#4a4a4a] cursor-pointer" />
                                                </div>
                                                <p className="text-[15px] md:text-[12px] text-white break-words">
                                                   {item?.body}
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-span-12">
                                          <div className="grid grid-cols-12">
                                             <div className="col-span-2"></div>
                                             <div className="col-span-10 flex items-center justify-start gap-[4px] mt-[5px]">
                                                <div className="flex flex-col">
                                                   <AiFillLike className="text-white text-[14px] hover:text-[#42d140] cursor-pointer" />
                                                   <p className="text-white text-[5px]">
                                                      {data?.like}
                                                   </p>
                                                </div>
                                                <div className="flex flex-col">
                                                   <AiFillDislike className="text-white text-[14px] hover:text-[#ed4848] cursor-pointer" />
                                                   <p className="text-white text-[5px]">
                                                      {data?.dislike}
                                                   </p>
                                                </div>
                                                <div className="flex flex-col">
                                                   <MdOutlineDeleteForever
                                                      onClick={() =>
                                                         handleDeleteComment(item?.id)
                                                      }
                                                      className="text-white text-[14px] hover:text-[#ed4848] cursor-pointer"
                                                   />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                              </>
                           );
                        })}
                     </Fragment>
                  ))}
                  {comments?.length == 0 && (
                     <div className="h-full w-full flex flex-col justify-center items-center">
                        <img src={emptyComments} className="h-18 w-18" />
                     </div>
                  )}
               </div>

               <div className="pl-2 pr-4 absolute bottom-1 grid grid-cols-12 gap-0">
                  <div className="h-[30px] w-[30px] rounded-full col-span-2">
                     <img
                        alt="img"
                        className="h-[30px] w-[30px] rounded-full object-cover"
                        src={`${originalURL}`}
                     />
                  </div>
                  <div className="flex justify-start col-span-10 ">
                     <input
                        type="text"
                        value={createCommet.body}
                        ref={inputRef}
                        placeholder="Type your comment"
                        onChange={handleChangeComment}
                        className="bg-[white] w-full text-[#000] pl-2 pr-10 outline-none rounded"
                     // onFocus={replay}
                     // onBlur={replay}
                     />
                     <div className="absolute flex gap-2 right-0 mt-[5px] mr-[22px]">
                        <IoSend
                           onClick={handleSubmitComment}
                           className="text-[#000] text-[20px] cursor-pointer"
                        />
                     </div>
                  </div>
               </div>
            </div>
         </Fragment>
      </Drawer>
   );
}
