import React from "react";
import { useSelector } from "react-redux";
import { datalist } from "../../Datas/Datas";
import AccountProfileSection from "../../Component/AccountPage/AccountProfileSection";
import { PostsTab } from "../../Component/AccountPage/PostsTab";

export default function Posts() {
  const ProfileDetails = useSelector((state) => state.AccountProfile);
  const { User_profile, User_posts } = ProfileDetails;

  return (
    <div className="bg-[#000] overflow-hidden h-full  relative">
      <p className="text-[#ff3b3b] text-center w-full  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
        CC ACCOUNT POSTS PAGE -FE - V10 /MAR 25,2024
      </p>
      <div className="">
        <AccountProfileSection />
      </div>
      <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
        <PostsTab
          datalist={datalist}
          User_posts={User_posts}
          User_profile={User_profile}
        />
      </div>
    </div>
  );
}
