import React from "react";
import { SocialIcon } from "react-social-icons";
import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,

} from "react-share";

export const shareSocialIcons = [
  {
    id: 1,
    iconname: "snapchat",
  },
  {
    id: 2,
    iconname: "instagram",
  },
  // {
  //   id: 3,
  //   iconname: "discord",
  // },
  {
    id: 4,
    iconname: "whatsapp",
  },
  {
    id: 5,
    iconname: "facebook",
  },
  {
    id: 6,
    iconname: "x",
  },
  // {
  //   id: 7,
  //   iconname: "wechat",
  // },
  {
    id: 8,
    iconname: "tiktok",
  },
  // {
  //   id: 9,
  //   iconname: "pinterest",
  // },
  // {
  //   id: 10,
  //   iconname: "patreon",
  // },
  // {
  //   id: 11,
  //   iconname: "slack",
  // },
  {
    id: 12,
    iconname: "github",
  },
  {
    id: 13,
    iconname: "telegram",
  },
  {
    id: 14,
    iconname: "spotify",
  },
  {
    id: 15,
    iconname: "vimeo",
  },
  {
    id: 16,
    iconname: "youtube",
  },
  // {
  //   id: 17,
  //   iconname: "reddit",
  // },
  // {
  //   id: 18,
  //   iconname: "dropbox",
  // },
  // {
  //   id: 19,
  //   iconname: "mailto",
  // },
  {
    id: 20,
    iconname: "",
  },
];

const LeftSide = () => {
  return (
    <div className="grid grid-cols-4  gap-1 mt-3">
      {/* {shareSocialIcons.map((data) => ( */}
      <div className="flex flex-col justify-center items-start mb-[19px]">
        <FacebookShareButton
          url={"https://thtsusa.com/api/post/folders/"}
          quote={"title"}
        >
          <SocialIcon
            network={"facebook"}
            url="#"
            style={{ height: "40px", width: "40px" }}
          />
        </FacebookShareButton>
      </div>
      <div className="flex flex-col justify-center items-start mb-[19px]">
        <WhatsappShareButton
          url={"https://thtsusa.com/api/post/folders/"}
          quote={"title"}
        >
          <SocialIcon
            network={"whatsapp"}
            url="#"
            style={{ height: "40px", width: "40px" }}
          />
        </WhatsappShareButton>
      </div>
      <div className="flex flex-col justify-center items-start mb-[19px]">
        <InstapaperShareButton
          url={"https://thtsusa.com/api/post/folders/"}
          quote={"title"}
        >
          <SocialIcon
            network={"instagram"}
            url="#"
            style={{ height: "40px", width: "40px" }}
          />
        </InstapaperShareButton>
      </div>
      <div className="flex flex-col justify-center items-start mb-[19px]">
        <TwitterShareButton
          url={"https://thtsusa.com/api/post/folders/"}
          title={"title"}
        >
          <SocialIcon
            network={"x"}
            url="#"
            style={{ height: "40px", width: "40px" }}
          />
        </TwitterShareButton>
      </div>

      <div className="flex flex-col justify-center items-start mb-[19px]">
        <LinkedinShareButton url={"https://thtsusa.com/api/post/folders/"}>
          <SocialIcon
            network={"linkedin"}
            url="#"
            style={{ height: "40px", width: "40px" }}
          />
        </LinkedinShareButton>
      </div>

      <div className="flex flex-col justify-center items-start mb-[19px]">
        <TelegramShareButton
          url="https://thtsusa.com/api/post/folders/"
          title={"https://thtsusa.com/api/post/folders/"}
        >
          <SocialIcon
            network={"telegram"}
            url="#"
            style={{ height: "40px", width: "40px" }}
          />
        </TelegramShareButton>
      </div>

      <div className="flex flex-col justify-center items-start mb-[19px]">
        <EmailShareButton url={"https://thtsusa.com/api/post/folders/"}>
          <SocialIcon
            network={"email"}
            url="#"
            style={{ height: "40px", width: "40px" }}
          />
        </EmailShareButton>
      </div>

      <div className="flex flex-col justify-center items-start mb-[19px]">
        <TumblrShareButton url={"https://thtsusa.com/api/post/folders/"}>
          <SocialIcon
            network={"tumblr"}
            url="#"
            style={{ height: "40px", width: "40px" }}
          />
        </TumblrShareButton>
      </div>

      {/* ))} */}
      <div>
        <img
          alt="img01"
          className="h-[40px] w-[40px]  object-cover rounded-full"
          src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </div>
      <div>
        <img
          alt="img02"
          className="h-[40px] w-[40px]  object-cover rounded-full"
          src="https://images.unsplash.com/photo-1500336624523-d727130c3328?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </div>
      <div>
        <img
          alt="img01"
          className="h-[40px] w-[40px]  object-cover rounded-full"
          src="https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </div>
      <div>
        <img
          alt="img01"
          className="h-[40px] w-[40px]  object-cover rounded-full"
          src="https://images.unsplash.com/photo-1603988089669-c041ac2fe196?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        />
      </div>
      {/* <div>
            <img
               alt="img01"
               className="h-[40px] w-[40px]  object-cover rounded-full"
               src="https://images.unsplash.com/photo-1470072508653-1be229b63562?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
         </div> */}
    </div>
  );
};

export default LeftSide;
