import React from "react";
import { HiSpeakerphone } from "react-icons/hi";
import { TiMessages } from "react-icons/ti";
import { FaFaceSmile, FaRepeat } from "react-icons/fa6";
import { FiSave } from "react-icons/fi";
import { BsMailbox2 } from "react-icons/bs";
import { MdGroups2 } from "react-icons/md";
import { FaShieldAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";

export default function AccountTab({ activeTab }) {
   const navigate = useNavigate();

   return (
      <>
         <div className="flex bg-black pt-2 px-2">
            <div className="bg-[#232323] rounded-md w-full">
               <div className="flex flex-wrap justify-center gap-2 items-center w-[100%] h-[15%] p-1 ">
                  <Button
                     className={`flex flex-col justify-center items-center px-0 h-auto p-[1px] w-[22%] ${activeTab == "Updates"
                        ? "bg-deep-orange-700 text-white"
                        : "text-white hover:bg-[#171717] hover:text-white bg-[#232323] "
                        }`}
                     value={"Updates"}
                     onClick={() => navigate("/account/updates")}
                  >
                     <div className="cursor-pointer h-[15px] w-[15px]">
                        <HiSpeakerphone className="h-[15px] w-[15px]" />
                     </div>
                     <p className={`text-[6px] m-0 font-semibold p-0 whitespace-pre-line cursor-pointer `}>
                        Notification
                     </p>
                  </Button>
                  <Button
                     className={`flex flex-col justify-center items-center h-auto p-[1px] w-[22%] ${activeTab == "Messages"
                        ? " bg-deep-orange-700 text-white"
                        : "text-white hover:bg-[#171717] hover:text-white bg-[#232323]"
                        }`}
                     onClick={() => navigate("/mobileMessages")}
                  >
                     <div className="cursor-pointer h-[15px] w-[15px]">
                        <TiMessages className="h-[15px] w-[15px]" />
                     </div>
                     <p className={`text-[6px] m-0 font-semibold p-0 whitespace-pre-line cursor-pointer`}>
                        Messages
                     </p>
                  </Button>
                  <Button
                     className={`flex flex-col justify-center items-center h-auto p-[1px] w-[22%] ${activeTab == "Saved"
                        ? " bg-deep-orange-700 text-white"
                        : "text-white hover:bg-[#171717] hover:text-white bg-[#232323]"
                        }`}
                     onClick={() => navigate("/account/saved")}
                  >
                     <div className="cursor-pointer h-[15px] w-[15px]">
                        <FiSave className="h-[15px] w-[15px]" />
                     </div>
                     <p className={`text-[6px] m-0 font-semibold p-0 whitespace-pre-line cursor-pointer`}>
                        Saved
                     </p>
                  </Button>
                  <Button
                     className={`flex flex-col justify-center items-center h-auto p-[1px] w-[22%] ${activeTab == "Private"
                        ? " bg-deep-orange-700 text-white"
                        : "text-white hover:bg-[#171717] hover:text-white bg-[#232323]"
                        }`}
                     onClick={() => navigate("/account/private/empty")}
                  >
                     <div className="cursor-pointer h-[15px] w-[15px]">
                        <FaShieldAlt className="h-[15px] w-[15px]" />
                     </div>
                     <p
                        className={`text-[6px] m-0  font-semibold p-0 whitespace-pre-line  cursor-pointer `}
                     >
                        Private
                     </p>
                  </Button>
                  <Button
                     className={`flex flex-col justify-center items-center h-auto p-[1px] w-[22%] ${activeTab == "Repost"
                        ? " bg-deep-orange-700 text-white"
                        : "text-white hover:bg-[#171717] hover:text-white bg-[#232323]"
                        }`}
                     onClick={() => navigate("/account/repost")}
                  >
                     <div className="cursor-pointer h-[15px] w-[15px]">
                        <FaRepeat className="h-[15px] w-[15px] " />
                     </div>
                     <p className={`text-[6px] m-0 font-semibold p-0 whitespace-pre-line cursor-pointer`}>
                        Repost
                     </p>
                  </Button>
                  <Button
                     className={`flex flex-col justify-center items-center h-auto p-[1px] w-[22%] ${activeTab == "Liked"
                        ? " bg-deep-orange-700 text-white"
                        : "text-white hover:bg-[#171717] hover:text-white bg-[#232323]"
                        }`}
                     onClick={() => navigate("/account/liked")}
                  >
                     <div className="cursor-pointer h-[15px] w-[15px]">
                        <FaFaceSmile className="h-[15px] w-[15px]" />
                     </div>
                     <p className={`text-[6px] m-0 font-semibold p-0 whitespace-pre-line cursor-pointer`}>
                        Liked
                     </p>
                  </Button>
                  <Button
                     className={`flex flex-col justify-center items-center h-auto p-[1px] w-[22%] ${activeTab == "Group"
                        ? " bg-deep-orange-700 text-white"
                        : "text-white hover:bg-[#171717] hover:text-white bg-[#232323]"
                        }`}
                     onClick={() => navigate("/account/group")}
                  >
                     <div className="cursor-pointer h-[15px] w-[15px]">
                        <MdGroups2 className="h-[15px] w-[15px]" />
                     </div>
                     <p className={`text-[6px] m-0 font-semibold p-0 whitespace-pre-line cursor-pointer`}>
                        Group
                     </p>
                  </Button>
                  <Button
                     className={`flex flex-col justify-center items-center h-auto p-[1px] w-[22%] ${activeTab == "Posted"
                        ? " bg-deep-orange-700 text-white"
                        : "text-white hover:bg-[#171717] hover:text-white bg-[#232323]"
                        }`}
                     onClick={() => navigate("/account/post")}
                  >
                     <div className="cursor-pointer h-[15px] w-[15px]">
                        <BsMailbox2 className="h-[15px] w-[15px]" />
                     </div>
                     <p className={`text-[6px] m-0  font-semibold p-0 whitespace-pre-line  cursor-pointer`}>
                        Posted
                     </p>
                  </Button>
               </div>
            </div>
         </div>
      </>
   );
}
