import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { BiSolidLeftArrow } from "react-icons/bi";
import { BiSolidRightArrow } from "react-icons/bi";
import HomeVideoView from "../../Component/Home/HomeVideoView";
import { getSavedFolders } from "../../Redux/ApiSlice/SavedVideosSlice";
import { useDispatch, useSelector } from "react-redux";
import VideoCarousel from "../../Component/Home/VideoCarousel";
import { useNavigate } from "react-router-dom";
// import InfiniteScrollVideos from "../../Component/Home/InfiniteScrollVideos";

export default function HomePagePanel(props) {
   const { spilThashTags, setPreButton, setNextButton, AllPosts, id } = props;

   const dispatch = useDispatch();
   // const { spilThashTags, setPreButton, setNextButton, AllPosts } = props;
   const ProfileDetails = useSelector((state) => state.AccountProfile);
   const { User_profile } = ProfileDetails;

   const fetchData = async () => {
      await dispatch(getSavedFolders(User_profile.id));
   };
   useEffect(() => {
      fetchData();
   }, []);

   return (
      <>
         <div className="video-item overflow-hidden h-full w-full hidden md:block">
            <VideoCarousel
               videos={AllPosts}
               setPreButton={setPreButton}
               setNextButton={setNextButton}
               spilThashTags={spilThashTags}
               fetchData={fetchData}
               id={id}
            />
         </div>
         <div className="h-[85vh] overflow-scroll overflow-x-hidden w-full md:hidden no-scrollbar">
            <InfiniteScrollVideos
               spilThashTags={spilThashTags}
               AllPosts={AllPosts}
               fetchData={fetchData}
               id={id}
            />
         </div>
      </>
   );
}
// const HomeVideoContainer = (props) => {
//   const dispatch = useDispatch();
//   const { spilThashTags, setPreButton, setNextButton, AllPosts } = props;
//   const ProfileDetails = useSelector((state) => state.AccountProfile);
//   const { User_profile } = ProfileDetails;

//   const fetchData = async () => {
//     await dispatch(getSavedFolders(User_profile.id));
//   };
//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   return (
//     <>
//       <div className="h-full w-full hidden md:block">
//         <VideoCarousel
//           videos={AllPosts}
//           setPreButton={setPreButton}
//           setNextButton={setNextButton}
//           spilThashTags={spilThashTags}
//           fetchData={fetchData}
//         />
//       </div>
//       <div className="h-[85vh] overflow-scroll overflow-x-hidden w-full md:hidden no-scrollbar">
//         <InfiniteScrollVideos
//           spilThashTags={spilThashTags}
//           AllPosts={AllPosts}
//           fetchData={fetchData}
//         />
//       </div>
//     </>
//   );
// };

// const VideoCarousel = ({
//   videos,
//   setPreButton,
//   setNextButton,
//   spilThashTags,
//   summary,
//   fetchData,
// }) => {
//   const carouselRef = useRef(null);
//   const customPreviousButton = () => {
//     const handlePrevious = () => {
//       if (carouselRef.current) {
//         carouselRef.current.previous();
//       }
//     };

//     return (
//       <button
//         onClick={handlePrevious}
//         className="w-3/4 top-2/4  h-2/4 bg-[#b3acac]  items-center text-center justify-center flex"
//       >
//         <BiSolidLeftArrow />
//       </button>
//     );
//   };

//   const customNextButton = () => {
//     const handleNext = () => {
//       if (carouselRef.current) {
//         carouselRef.current.next();
//       }
//     };

//     return (
//       <button
//         className=" w-3/4 top-2/4 h-2/4 right-0 bg-[#b3acac]  items-center text-center justify-center flex"
//         onClick={handleNext}
//       >
//         <BiSolidRightArrow />
//       </button>
//     );
//   };

//   useEffect(() => {
//     setPreButton(customPreviousButton);
//     setNextButton(customNextButton);
//   }, []);

//   return (
//     <>
//       <Carousel
//         ref={carouselRef}
//         additionalTransfrom={0}
//         arrows={false}
//         autoPlay={false}
//         centerMode={false}
//         className="h-full"
//         containerClass="container-with-dots"
//         dotListClass=""
//         draggable
//         focusOnSelect={false}
//         infinite={false}
//         itemClass=""
//         keyBoardControl
//         minimumTouchDrag={80}
//         renderButtonGroupOutside={true}
//         renderDotsOutside={false}
//         customButtonGroup={<customNextButton />}
//         responsive={{
//           desktop: {
//             breakpoint: { max: 3000, min: 1024 },
//             items: 1,
//             partialVisibilityGutter: 40,
//           },
//           mobile: {
//             breakpoint: { max: 464, min: 0 },
//             items: 1,
//             partialVisibilityGutter: 30,
//           },
//           tablet: {
//             breakpoint: { max: 1024, min: 464 },
//             items: 1,
//             partialVisibilityGutter: 30,
//           },
//         }}
//         showDots={false}
//         sliderClass=""
//         slidesToSlide={1}
//         swipeable
//       >
//         {videos?.map((data) => {
//             return (
//               <HomeVideoView
//                 id={data.id}
//                 src={data.video}
//                 summary={summary}
//                 comments={data.comments}
//                 spilThashTags={spilThashTags}
//                 created_by={data.created_by}
//                 dec={data.body}
//                 like_count={data.likes_count}
//                 comments_count={data.comments_count}
//                 fetchData={fetchData}
//               />
//             );
//           })}
//       </Carousel>
//     </>
//   );
// };

const InfiniteScrollVideos = ({
   spilThashTags,
   summary,
   AllPosts,
   fetchData,
   id,
}) => {
   const videoRef = useRef(null);
   const navigate = useNavigate();
   const mainContRef = useRef(null);

   useEffect(() => {
      AOS.init({
         duration: 10,
         once: true,
         easing: "ease-in-out",
      });
      window.scrollTo({ behavior: "smooth" });
   }, [AllPosts]);

   // Scroll to the selected video
   useEffect(() => {
      if (id) {
         const element = document?.getElementById(`sect ${id}`);
         if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
         }
      }
   }, [id]);

   useEffect(() => {
      const handleScroll = () => {
         // Example condition: if scrolled more than 100px
         if (mainContRef.current && mainContRef.current.scrollTop > 100 && id) {
            // Navigate without disrupting the current scroll
            navigate(`/watchVideo/${id}`, { replace: true }); // Consider using 'replace' to avoid pushing a new entry into the history stack
         }
      };

      const mainCont = mainContRef.current;
      if (mainCont) {
         mainCont.addEventListener("scroll", handleScroll);
      }

      // Cleanup
      return () => {
         if (mainCont) {
            mainCont.removeEventListener("scroll", handleScroll);
         }
      };
   }, [navigate]);

   return (
      <div ref={mainContRef} className="mainCont no-scrollbar">
         {AllPosts?.map((data) => (
            <div id={`sect ${data.id}`} className={`sect ${data.id}`} key={data.id}>
               <HomeVideoView
                  id={data.id}
                  src={data.video_url}
                  summary={summary}
                  comments={data.comments}
                  spilThashTags={spilThashTags}
                  created_by={data.created_by}
                  dec={data.body}
                  videoRef={videoRef}
                  comments_count={data.comments_count}
                  like_count={data.likes_count}
                  fetchData={fetchData}
               />
            </div>
         ))}
      </div>
   );
};
