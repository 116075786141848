import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DesktopNav = () => {
   const navigate = useNavigate();
   const location = useLocation();

   const isAccount = location.pathname === "/settings/account";
   const isSecurity = location.pathname === "/settings/security";
   const isPrivacy = location.pathname === "/settings/privacy";
   const isPreferences = location.pathname === "/settings/preferences";
   const isNotifications = location.pathname === "/settings/notifications";
   const isWhatsnew = location.pathname === "/settings/whatsnew";

   return (
      <div className="hidden lg:block mx-20">
         <p className="text-center text-[22px] my-8">Settings</p>
         <div className="flex justify-between">
            <p 
               onClick={() => navigate("/settings/account")}
               className={`shadow-[rgba(0,0,0,0.50)_0px_2px_10px] rounded-xl px-3 py-2 cursor-pointer ${isAccount ? "bg-black text-white" : ""}`}
            >
               Account
            </p>
            <p 
               onClick={() => navigate("/settings/security")}
               className={`shadow-[rgba(0,0,0,0.50)_0px_2px_10px] rounded-xl px-3 py-2 cursor-pointer ${isSecurity ? "bg-black text-white" : ""}`}
            >
               Security
            </p>
            <p 
               onClick={() => navigate("/settings/privacy")}
               className={`shadow-[rgba(0,0,0,0.50)_0px_2px_10px] rounded-xl px-3 py-2 cursor-pointer ${isPrivacy ? "bg-black text-white" : ""}`}
            >
               Privacy
            </p>
            <p 
               onClick={() => navigate("/settings/preferences")}
               className={`shadow-[rgba(0,0,0,0.50)_0px_2px_10px] rounded-xl px-3 py-2 cursor-pointer ${isPreferences ? "bg-black text-white" : ""}`}
            >
               Preferences
            </p>
            <p 
               onClick={() => navigate("/settings/notifications")}
               className={`shadow-[rgba(0,0,0,0.50)_0px_2px_10px] rounded-xl px-3 py-2 cursor-pointer ${isNotifications ? "bg-black text-white" : ""}`}
            >
               Notify
            </p>
            <p 
               onClick={() => navigate("/settings/whatsnew")}
               className={`shadow-[rgba(0,0,0,0.50)_0px_2px_10px] rounded-xl px-3 py-2 cursor-pointer ${isWhatsnew ? "bg-black text-white" : ""}`}
            >
               What’s new
            </p>
         </div>
      </div>
   )
}

export default DesktopNav