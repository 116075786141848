import React from "react";

export default function VideoCardIconList(props) {
  const { VideoCardHeaderIcons, isfooter, textColor, handleNavigate } = props;

  return (
    <div
      className={
        isfooter
          ? "w-full flex justify-between items-center h-full px-3"
          : "videoCard-main"
      }
    >
      {VideoCardHeaderIcons.map((item, index) => (
        <div
          key={index}
          className="videoCard-content w-10  flex flex-col justify-evenly items-center mt-1"
          onClick={() => handleNavigate(`${item.placeholder}`)}
        >
          <div className="cursor-pointer">{item.icon && <>{item.icon} </>}</div>
          <p
            className={`${isfooter || textColor === "white" ? "text-[#fff]" : "text-[#000]"
              } cursor-pointer text-[7px]`}
          >
            {item.placeholder}
          </p>
        </div>
      ))}
    </div>
  );
}
