import React, { useCallback, useEffect, useState } from "react";
import { CgCloseO } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { SearchData } from "../Redux/ApiSlice/SearchSlice";
import { useNavigate } from "react-router-dom";
import SearchSideBar from "../Component/SearchResult/SearchSideBar";
import SearchSection from "../Component/SearchResult/SearchSection";
import { MdManageSearch } from "react-icons/md";

export default function SearchResultPage() {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const seacrchlist = [
      {
         title: "Recent Searches",
         icon: <MdManageSearch className="text-[1.5rem]" />,
         listofSearches: [
            "Come Back Be Here",
            "SundayFunday",
            "Taylor Swift",
            "Cutest animal Instagram accounts ",
            "CC Official",
            "sachintendulkar",
         ],
      },
      {
         title: "Trending Accounts",
         listofSearches: [
            "Tom Brady",
            "Cutest animal Instagram accounts ",
            "CC Official",
         ],
      },
   ];

   const [SearchItem, setSearchItem] = useState("");
   const [showresult, setShowResult] = useState(false);

   const handleSearchBar = (e) => {
      setSearchItem(e.target.value);
   };

   const handleshowresult = useCallback(() => {
      setShowResult(true);
   }, []);

   const handleSearchItem = () => {
      dispatch(SearchData({ query: SearchItem }));
   };

   const searchedItem = seacrchlist.filter((item) =>
      SearchItem.length ? item.title === SearchItem : item.title
   );

   useEffect(() => {
      handleSearchItem();
   });

   return (
      <div className="bg-black h-full relative overflow-hidden">
         <p className="text-red-700 uppercase font-bold text-[10px] w-full text-center">
            cc SEARCH MAIN PAGE / fe / v4 / JUNE 01, 2024
         </p>

         <div className="h-full">
            <SearchSideBar />
            <SearchSection
               SearchItem={SearchItem}
               handleSearchBar={handleSearchBar}
               handleshowresult={handleshowresult}
               navigate={navigate}
            />
            {showresult && (
               <div className="grid grid-cols-1 overflow-scroll no-scrollbar mb-2 h-[-webkit-fill-available] absolute w-full">
                  <div className="overflow-scroll no-scrollbar px-4">
                     <div className="w-full bg-gray-900 px-2 bg-opacity-50 rounded">
                        {searchedItem?.map((item) => (
                           <div className="search-items mt-2">
                              <h4 className="text-[18px] text-[#fff] font-bold cursor-pointer">
                                 <div className="flex items-center">
                                    {item?.icon}{" "}
                                    <div className="text-[#fff]">{item?.title}</div>
                                 </div>
                                 <div className="bg-[#ffffff] h-[2px] rounded-full" />
                              </h4>
                              {item?.listofSearches.map((sub) => (
                                 <div className="histry-search-items flex justify-between px-2 py-1 w-[100%] mt-2 rounded-sm ">
                                    <p className=" md:text-[12px] text-[14px] line-clamp-1 text-white">
                                       {sub}
                                    </p>
                                    {<div><CgCloseO color="red" className="cursor-pointer" /></div>}
                                 </div>
                              ))}
                           </div>
                        ))}
                     </div>
                     <div className="w-full "></div>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
}
