import { configureStore } from "@reduxjs/toolkit";
import AccountProfileReducer from "./ApiSlice/AccountProfileSlice";
import HomepageVideoReducer from "./ApiSlice/HomePageVideoSlice";
import UploadvideoReducer from "./ApiSlice/UploadVideoSlice";
import lostPasswordReducer from "./ApiSlice/LostPasswordSlice";
import EnterOtpReaducer from "./ApiSlice/EnterOtpSlice";
import FollowsuggestionsReducer from "./ApiSlice/FollowSuggestionSlice";
import SearchReducer from "./ApiSlice/SearchSlice";
import HashTagReducer from "./ApiSlice/HashTagSlice";
import SavedVideoReducer from "./ApiSlice/SavedVideosSlice";
import UserStatusReducer from "./ApiSlice/UserStatusSlice";
// import SelectVideoViewReducer from "./ApiSlice/VideoViewSlice";
import WatchVideoReducer from "./ApiSlice/WatchVideoSlice";
import MessagesReducer from "./ApiSlice/MessagesSlice";
import NotificationReducer from "./ApiSlice/NotificationSlice";
import AccountPrivateReducer from "./ApiSlice/AccountPrivateSlice";
import { setUploadProgress } from "./ApiSlice/UploadVideoSlice";

export const store = configureStore({
  reducer: {
    AccountProfile: AccountProfileReducer,
    followsuggestions: FollowsuggestionsReducer,
    SearchItems: SearchReducer,
    HomePageVideo: HomepageVideoReducer,
    Uploadvideo: UploadvideoReducer,
    lostPassword: lostPasswordReducer,
    EnterOtp: EnterOtpReaducer,
    HashTag: HashTagReducer,
    savedVideos: SavedVideoReducer,
    UserPrivate_Status: UserStatusReducer,
    WatchVideo: WatchVideoReducer,
    messages: MessagesReducer,
    Notification: NotificationReducer,
    AccountPrivate: AccountPrivateReducer,
    ProgressBar: setUploadProgress
  },
});
