import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

export default function FeedBackFromCard({ handleCancel }) {
  return (
    <Card className="rounded-sm w-full shadow-[rgba(0,_0,_0,_0.24)_1px_3px_8px] p-4 border-2">
      <CardBody className="p-1">
        <Typography color="blue-gray" className="mb-1 text-[12px] font-bold">
          Feedback Form
        </Typography>
        <form className="mt-2 flex flex-col ">
          <div className="grid grid-cols-6 gap-3">
            <div className="col-span-3">
              <label
                class="block text-gray-700 text-[10px] font-bold mb-1"
                for="username"
              >
                First Name
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-[9px] "
                id="username"
                type="text"
                placeholder="Enter Your First Name"
              />
            </div>
            <div className="col-span-3">
              <label
                class="block text-gray-700 text-[10px] font-bold mb-1"
                for="username"
              >
                Last Name
              </label>
              <input
                class="shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-[9px]"
                id="username"
                type="text"
                placeholder="Enter Your First Last"
              />
            </div>
          </div>
          <div className="w-full gap-3 mt-2">
            <label class="block text-gray-700 text-[10px] font-bold mb-1">
              Email
            </label>
            <input
              class="shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-[9px] "
              id="username"
              type="text"
              placeholder="Enter Your Email"
            />
          </div>
          <div className="mt-4">
          <label class="block text-gray-700 text-[10px] font-bold mb-1">
              Feedback
            </label>
            <textarea
              id="editor"
              rows="2"
              class="block w-full px-0 text-sm border border-1 text-gray-800 bg-white  dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400 placeholder:text-[10px]"
              placeholder="Enter Your Message..."
              required
            ></textarea>
          </div>
        </form>
      </CardBody>
      <CardFooter className="flex justify-center items-center gap-6 p-2 ">
        <Button className="bg-[#00A3FF] rounded-full text-[6px] px-3 py-2 text-center ">
          Save
        </Button>
        <Button
          className="bg-[#00A3FF] rounded-full text-[6px] px-3 py-2 text-center "
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </CardFooter>
    </Card>
  );
}
