import React from "react";
import { motion } from "framer-motion";
import { IoIosArrowBack } from "react-icons/io";
import { Avatar } from "@material-tailwind/react";

export default function WhatsNew({ openWhatsNew, setOpenWhatsNew }) {
   return (
      <>
         <motion.div
            initial={{ x: "100%" }}
            animate={{ x: openWhatsNew ? 0 : "-100%" }}
            transition={{ duration: 0.5 }}
            className="w-full h-full"
         >
            <div className=" w-full grid grid-col-12 ">
               <div className="col-span-1  pl-1 py-2">
                  <IoIosArrowBack
                     onClick={() => setOpenWhatsNew(false)}
                     className="text-[#433cbb] hover:text-[#353097] text-[1.5rem] cursor-pointer"
                  />
               </div>
               <div className="col-span-11 text-[14px] font-extrabold flex items-center   py-2">
                  What's New
               </div>

               <div className="col-span-12 flex justify-between items-center py-2 px-4 hover:bg-[#e6e6e6] cursor-pointer">
                  <div className="grid grid-cols-12 justify-between items-center  gap-2">
                     <Avatar
                        src="https://cdn.pixabay.com/photo/2017/06/15/22/05/woman-2406963_1280.jpg"
                        className="h-8 w-8 rounded-full object-cover col-span-2"
                     />
                     <p className="text-[10px] font-bold col-span-8">
                        Account Profile is here add{" "}
                        <span className="text-green-500">"short bio"</span> let's try
                        adding now{" "}
                     </p>
                     <p className="text-blue-800 text-[8px] col-span-2 text-end font-bold justify-end flex h-full items-end">
                        10.30am
                     </p>
                  </div>
               </div>

               <div className="col-span-12 flex justify-between items-center py-2 px-4 hover:bg-[#e6e6e6] cursor-pointer">
                  <div className="grid grid-cols-12 justify-between items-center  gap-2">
                     <Avatar
                        src="https://cdn.pixabay.com/photo/2020/10/19/09/44/woman-5667299_1280.jpg"
                        className="h-8 w-8 rounded-full object-cover col-span-2"
                     />
                     <p className="text-[10px] font-bold col-span-8">
                        Show Your account Profile status now by adding
                        <span className="text-green-500">"Add status"</span> and showto
                        your besties......
                     </p>
                     <p className="text-blue-800 text-[8px] col-span-2 text-end font-bold justify-end flex h-full items-end">
                        8.30am
                     </p>
                  </div>
               </div>

               <div className="col-span-12 flex justify-between items-center py-2 px-4 hover:bg-[#e6e6e6] cursor-pointer  ">
                  <div className="grid grid-cols-12 justify-between items-center  gap-2">
                     <Avatar
                        src="https://cdn.pixabay.com/photo/2016/01/06/05/56/woman-1123645_1280.jpg"
                        className="h-8 w-8 rounded-full object-cover col-span-2"
                     />
                     <p className="text-[10px] font-bold col-span-8">
                        Account Profile is here add{" "}
                        <span className="text-green-500">"short bio"</span> let's try
                        adding now{" "}
                     </p>
                     <p className="text-blue-800 text-[8px] col-span-2 text-end font-bold justify-end flex h-full items-end">
                        6.30am
                     </p>
                  </div>
               </div>

               <div className="col-span-12 flex justify-between items-center py-2 px-4 hover:bg-[#e6e6e6] cursor-pointer">
                  <div className="grid grid-cols-12 justify-between items-center  gap-2">
                     <Avatar
                        items-center
                        flex
                        src="https://cdn.pixabay.com/photo/2017/08/09/13/35/model-2614569_1280.jpg"
                        className="h-8 w-8 rounded-full object-cover col-span-2"
                     />
                     <p className="text-[10px] font-bold col-span-8">
                        Account Profile is here add{" "}
                        <span className="text-green-500">"short bio"</span> let's try
                        adding now{" "}
                     </p>
                     <p className="text-blue-800 text-[8px] col-span-2 text-end font-bold justify-end flex h-full items-end">
                        6.30am
                     </p>
                  </div>
               </div>
            </div>
         </motion.div>
      </>
   );
}
