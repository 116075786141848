import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../API/API";

export const getHashtag = createAsyncThunk("getHashtag", async (data) => {
  try {
    const response = await axiosInstance.get(`post/trends/${data}/`);
    const result = response.data;
    return result

  } catch (error) {
    console.error("Error:", error.response.data); 
  }
});

export const HashTagSlice = createSlice({
  name: "HashTag",
  initialState: {
      hashTagList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // getHashtag action promises
    builder.addCase(getHashtag.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHashtag.fulfilled, (state, action) => {
      state.loading = false;
      state.hashTagList = action.payload;
    });
    builder.addCase(getHashtag.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default HashTagSlice.reducer;