import React from 'react'
import AccountProfileSection from '../../Component/AccountPage/AccountProfileSection'
import AccountTab from '../../Component/AccountPage/AccountTab'
import { SavedTab } from '../../Component/AccountPage/SavedTab'

export default function Saved() {
   return (
      <div className="bg-[#000] overflow-hidden h-full relative ">
         <p className="text-[#ff3b3b] text-center w-full mb-2  text-[12px] font-[700] flex justify-center z-50 lg:text-[10px] uppercase">
            CC ACCOUNT SAVED PAGE, FE, V10 / JULY 01, 2024
         </p>
         <div className="">
            <AccountProfileSection />
         </div>
         <AccountTab activeTab={"Saved"} />
         <div className="p-2 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]">
            <SavedTab />
         </div>
      </div>
   )
}
