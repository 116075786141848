import React, { useEffect, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { FaHeart } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import { HiOutlineFaceSmile } from "react-icons/hi2";
import { IoIosCloseCircle } from "react-icons/io";
import {
  getMessages,
  sendMessages,
} from "../../Redux/ApiSlice/MessagesSlice";
import { useDispatch, useSelector } from "react-redux";
import MoonLoader from "react-spinners/MoonLoader";

const UserChat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messagesChatData = useSelector((state) => state.messages);
  const accountProfile = useSelector((state) => state.AccountProfile);
  const { User_profile } = accountProfile;
  const {
    individualMessages,
    createChatRoom,
    messagesList,
    loading,
    post_upload_loader,
  } = messagesChatData;
  const { id } = useParams();
  const menuRef = useRef(null);
  const fileRef = useRef(null);
  const bottomRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [showEmoji1, setShowEmoji1] = useState(false);
  const [showEmoji2, setShowEmoji2] = useState(false);
  const [showEmoji3, setShowEmoji3] = useState(false);
  const [showEmoji4, setShowEmoji4] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [showReplyPopup1, setShowReplyPopup1] = useState(false);
  const [selectImage, setSelectImage] = useState(null);
  const [image, setImage] = useState(null);
  const [emojiId, setEmojiId] = useState(null);
  const [replyId, setReplyId] = useState(null);

  const selectedPerson = messagesList?.find((item) => item.id == id);
  // const { users } = selectedPerson;
  const { users } = individualMessages;

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    // setIsInputFocused(false);
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;
    const fileUrl = URL.createObjectURL(file);
    if (file.size > 100e6) {
      return null;
    } else {
      setSelectImage(fileUrl);
      setImage(file);
    }
  };
  const handleSelectEmoji = (value) => {
    setInputValue((prev) => prev + value);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowReplyPopup(null);
      setShowReplyPopup1(null);
      setReplyId(null);
    }
  };
  const fetchChats = async () => {
    await dispatch(getMessages(id));
  };
  const postMessage = async (event) => {
    event.preventDefault();

    if (inputValue || image) {
      await dispatch(
        sendMessages({
          id: id,
          img: image,
          text: inputValue,
        })
      );
      setInputValue("");
      setSelectImage(null);
      setImage(null);
      setShowEmoji4(null);
      fetchChats();
    }
  };
  useEffect(() => {
    fetchChats();
    document.addEventListener("mousedown", handleClickOutside);
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth", top: 0 });
  }, [inputValue, selectImage]);
  return (
    <>
      {" "}
      <div className="h-full" >
        <div className="sticky top-0 bg-white z-40">
          <p className="text-[9px] text-red-600 font-bold uppercase text-center">
            CC USER MESSAGING CHAT / FE / V3 / MAY 03, 2024
          </p>
          <div className="grid grid-cols-12 px-1">
            <div className="col-span-2 flex items-center justify-start">
              <IoArrowBackCircle
                className="text-[25px] cursor-pointer"
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="col-span-8">
              {users?.map((item) => (
                <div
                  className=" flex items-center justify-center gap-1"
                  key={item.id}
                >
                  <img
                    className="h-6 w-6 rounded-full object-cover cursor-pointer"
                    src={item.avatar_url}
                    onClick={() => navigate(`/mobileMessages/user-detail/${id}`)}
                  />
                  <p
                    className="text-black cursor-pointer"
                    onClick={() => navigate(`/mobileMessages/user-detail/${id}`)}
                  >
                    {item.first_name}
                    {item.last_name}
                  </p>
                </div>
              ))}
            </div>
            <div className="col-span-2 flex items-center justify-end">
              <IoMenu className="text-[25px] cursor-pointer" />
            </div>
          </div>
          <hr className="w-full my-[2px] h-[0.1px] bg-gray-800 border-0" />
        </div>
        <div
          className="px-[5px] bg-white h-[70vh] overflow-scroll no-scrollbar"
          ref={bottomRef}
        >
          {post_upload_loader && (
            <div className="h-full w-full flex justify-center items-center">
              <MoonLoader color="black" size={30} />
            </div>
          )}

          {individualMessages.messages?.map((data) => (
            <>
              {data.sent_to.id !== User_profile.id ? (
                <div className="grid grid-cols-12 pb-[8px] justify-end">
                  <div className="col-span-12 flex items-center justify-center py-4">
                    <p className="text-black text-[12px] font-bold">
                      {data.created_at_formatted}
                    </p>
                  </div>
                  <div className="col-span-12">
                    <div className="flex items-center gap-[5px] justify-end">
                      <p className="text-[#3ea8d8] text-[9px]">
                        {data.sent_time}
                      </p>
                      <p className="text-black text-[12px] font-bold">you</p>
                    </div>
                  </div>
                  {data.image && (
                    <div className="col-span-12 flex justify-end ">
                      <div className=" relative flex">
                        {/* <p className="text-black text-[12px] text-end font-bold">You</p> */}
                        <div className="relative">
                          <img
                            className="h-[11rem] w-[7.5rem] object-cover rounded-lg"
                            src={`${data.image}`}
                          />
                          <div className="absolute -left-7 -bottom-2">
                            <div className="flex items-center justify-center gap-[4px] bg-black px-2 py-[2px] rounded-full cursor-pointer">
                              {/* <p className="text-white text-[10px]">73</p> */}
                              <FaHeart className="text-red-500 hover:text-green-500 text-[12px] mx-[1px]" />
                            </div>
                          </div>
                          {/* <div className="absolute top-1 left-1">
                          <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[4px] py-[2px] rounded-2xl cursor-pointer">
                          <img
                            className="h-3 w-3 rounded-full object-cover"
                            src="https://plus.unsplash.com/premium_photo-1664199486587-37f325d15182?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          />
                          <p className="text-[10px] text-white">Matthew</p>
                        </div>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-span-3"></div>
                  {data.body && (
                    <div className="col-span-9 flex  justify-end mt-[10px]">
                      <p className="text-[12px] text-white bg-[#343333] leading-[14px] p-[5px] rounded-md">
                        {data.body}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="grid grid-cols-12 pb-[8px]">
                  {data.image && (
                    <>
                      <div className="col-span-12"></div>
                      <div className="col-span-6 relative">
                        <div className="flex flex-col">
                          <p className="text-black text-[12px] font-bold">{}</p>
                          <div className="relative">
                            <img
                              className="h-[11rem] w-[7.5rem] object-cover rounded-lg"
                              src={data.image}
                            />
                            <div className="absolute right-2 -bottom-2">
                              <div className="flex items-center justify-center gap-[4px] bg-black px-2 py-[2px] rounded-full cursor-pointer">
                                <p className="text-white text-[10px]">400</p>
                                <FaHeart className="text-red-500 hover:text-green-500 text-[12px] mx-[1px]" />
                              </div>
                            </div>
                            <div className="absolute top-1 left-1">
                              <div className="flex items-center justify-start gap-[2px] bg-[#0000008d] px-[4px] py-[2px] rounded-2xl">
                                <img
                                  className="h-3 w-3 rounded-full object-cover"
                                  src={data.created_by.avatar_url}
                                />
                                <p className="text-[10px] text-white">
                                  {data.created_by.first_name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="absolute top-[50%] right-1">
                          {emojiId ? null : (
                            <HiOutlineFaceSmile
                              className="text-[20px] cursor-pointer"
                              onClick={() => setEmojiId(data.id)}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-span-10">
                    {emojiId == data.id && (
                      <div className="flex flex-row items-center gap-1 text-[20px] border border-gray-500 rounded-full bg-[#ffffff27] px-1 py-[2px] mt-3 w-fit">
                        <p
                          className="cursor-pointer transform transition-transform hover:scale-125"
                          onClick={() => setEmojiId(false)}
                        >
                          👍
                        </p>
                        <p
                          className="cursor-pointer transform transition-transform hover:scale-125"
                          onClick={() => setEmojiId(false)}
                        >
                          ❤️
                        </p>
                        <p
                          className="cursor-pointer transform transition-transform hover:scale-125"
                          onClick={() => setEmojiId(false)}
                        >
                          😃
                        </p>
                        <p
                          className="cursor-pointer transform transition-transform hover:scale-125"
                          onClick={() => setEmojiId(false)}
                        >
                          😥
                        </p>
                        <p
                          className="cursor-pointer transform transition-transform hover:scale-125"
                          onClick={() => setEmojiId(false)}
                        >
                          👎
                        </p>
                        <p
                          className="cursor-pointer transform transition-transform hover:scale-125"
                          onClick={() => setEmojiId(false)}
                        >
                          😡
                        </p>
                        <IoIosCloseCircle
                          className="cursor-pointer transform transition-transform hover:scale-125"
                          onClick={() => setEmojiId(false)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-span-9 mt-2">
                    <div className="flex items-center gap-2">
                      <p className="text-black text-[12px] font-bold">
                        {data.created_by.first_name} {data.created_by.last_name}
                      </p>
                      <p className="text-[#3ea8d8] text-[9px]">
                        {data.sent_time}
                      </p>
                    </div>
                    {data.body && (
                      <div className="grid grid-cols-12 gap-2  mt-[1px]">
                        <div className="col-span-2">
                          <img
                            alt={"item.userName"}
                            src={data.created_by.avatar_url}
                            className="h-5 w-5 object-cover rounded-full"
                          />
                        </div>
                        <div className="col-span-10">
                          <div className="flex">
                            <p
                              className="text-[12px] text-white bg-[#343333] leading-[14px] p-[5px] rounded-md relative"
                              onClick={() => setReplyId(data.id)}
                            >
                              {data.body}
                              {replyId == data.id && (
                                <div
                                  ref={menuRef}
                                  class="absolute -top-24 right-24 z-10 mt-0 w-24 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  role="menu"
                                  aria-orientation="vertical"
                                  aria-labelledby="menu-button"
                                  tabindex="-1"
                                >
                                  <div class="py-0" role="none">
                                    <a
                                      href="#"
                                      class="text-gray-700 block px-2 py-2 text-sm hover:bg-gray-500 hover:text-white"
                                      role="menuitem"
                                      tabindex="-1"
                                      id="menu-item-0"
                                    >
                                      Reply
                                    </a>
                                    <a
                                      href="#"
                                      class="text-gray-700 block px-2 py-2 text-sm hover:bg-gray-500 hover:text-white"
                                      role="menuitem"
                                      tabindex="-1"
                                      id="menu-item-0"
                                    >
                                      Copy
                                    </a>
                                    <a
                                      href="#"
                                      class="text-gray-700 block px-2 py-2 text-sm hover:bg-gray-500 hover:text-white"
                                      role="menuitem"
                                      tabindex="-1"
                                      id="menu-item-0"
                                    >
                                      Report
                                    </a>
                                  </div>
                                </div>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ))}
          {individualMessages.messages?.length == 0 && (
            <div className="h-full w-full flex justify-center items-center">
              <p className="text-gray-700 text-[1rem] font-bold">No Messages</p>
            </div>
          )}
        </div>
        <div className="sticky bottom-0 bg-white z-40 p-1">
          <form onSubmit={postMessage}>
            <div className="flex items-center justify-between gap-1 my-1">
              <div className="bg-black p-2 rounded-full cursor-pointer">
                <FaCamera
                  className="text-white"
                  onClick={() => {
                    fileRef.current?.click();
                  }}
                />
                <input
                  type="file"
                  ref={fileRef}
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              <div className="relative w-full">
                <input
                  value={inputValue}
                  placeholder="Send a message"
                  className="w-full h-8 bg-black text-white px-3 focus:outline-none rounded-xl"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onChange={handleChange}
                />
                <HiOutlineFaceSmile
                  color="white"
                  className="text-[20px] cursor-pointer absolute right-[10px] top-[6px]"
                  onClick={() => setShowEmoji4(true)}
                />

                {showEmoji4 && (
                  <div className="flex flex-col absolute bottom-10 right-0 items-center gap-1 text-[20px] border border-gray-500 rounded-full bg-[#ffffff27] px-1 py-[2px] mt-3 w-fit">
                    <p
                      className="cursor-pointer transform transition-transform hover:scale-125"
                      onClick={() => handleSelectEmoji("👍")}
                    >
                      👍
                    </p>
                    <p
                      className="cursor-pointer transform transition-transform hover:scale-125"
                      onClick={() => handleSelectEmoji("❤️")}
                    >
                      ❤️
                    </p>
                    <p
                      className="cursor-pointer transform transition-transform hover:scale-125"
                      onClick={() => handleSelectEmoji("😃")}
                    >
                      😃
                    </p>
                    <p
                      className="cursor-pointer transform transition-transform hover:scale-125"
                      onClick={() => handleSelectEmoji("😥")}
                    >
                      😥
                    </p>
                    <p
                      className="cursor-pointer transform transition-transform hover:scale-125"
                      onClick={() => handleSelectEmoji("👎")}
                    >
                      👎
                    </p>
                    <p
                      className="cursor-pointer transform transition-transform hover:scale-125"
                      onClick={() => handleSelectEmoji("😡")}
                    >
                      😡
                    </p>
                    <IoIosCloseCircle
                      className="cursor-pointer transform transition-transform hover:scale-125"
                      onClick={() => setShowEmoji4(false)}
                    />
                  </div>
                )}

                {selectImage && (
                  <div className="h-32 w-36 absolute bottom-10 rounded-xl grid grid-cols-4">
                    <img
                      src={selectImage}
                      className="h-32 w-36 col-span-3  object-cover rounded-xl"
                    />
                    <div className="col-span-1 relative">
                      <IoIosCloseCircle
                        onClick={() => setSelectImage(null)}
                        color="red"
                        className="cursor-pointer absolute transform transition-transform hover:scale-125"
                      // onClick={() => setShowEmoji3(false)}
                      />
                    </div>
                  </div>
                )}
              </div>
              {isInputFocused && (
                <button
                  type="submit"
                  className="bg-black p-2 rounded-full cursor-pointer z-50"
                >
                  {!loading ? (
                    <IoSend className="text-white" />
                  ) : (
                    <MoonLoader color="#fff" size={14} />
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
        {/* <UploadVideoWaringModal/> */}
      </div>
    </>
  );
};

export default UserChat;
