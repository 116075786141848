import React, { Fragment } from "react";
import { MdStarRate } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@material-tailwind/react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { IoCaretBackCircle } from "react-icons/io5";
import { Stepper, Step, Typography } from "@material-tailwind/react";

const Payment = () => {
   const navigate = useNavigate();

   return (
      <Fragment>
         <div className="px-2 pt-3">
            <IoCaretBackCircle
               className="text-black text-[24px] cursor-pointer"
               onClick={() => navigate(-1)}
            />
         </div>
         <div className="w-full py-4 px-8">
            <Stepper activeStep={1}>
               <Step className="!bg-[#0F9B36]">1
                  <div className="absolute -bottom-[1.5rem] w-max text-center">
                     <p className="text-[#000] text-[12px]">Address</p>
                  </div>
               </Step>
               <Step className="!bg-[#0F9B36]">2
                  <div className="absolute -bottom-[1.5rem] w-max text-center">
                     <p className="text-[#000] text-[12px]">Payment</p>
                  </div>
               </Step>
               <Step>3
                  <div className="absolute -bottom-[1.5rem] w-max text-center">
                     <p className="text-[#000] text-[12px]">Ordered</p>
                  </div>
               </Step>
            </Stepper>
         </div>
         <div className="px-4 mt-5">
            <div className="flex justify-between items-center">
               <FaArrowAltCircleLeft className="text-[#000] text-[20px] cursor-pointer" onClick={() => navigate(-1)} />
               <p className="font-bold text-[24px] md:text-[16px]">Enter Your Payment Method</p>
            </div>

            <form className="mt-5">
               <div className="flex items-center justify-between mb-5">
                  <p className="flex text-[14px] font-semibold md:text-[11px]">Name on Card <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <input
                     type="text"
                     className="border-gray-500 border-[1px] py-[5px] px-[8px] w-[16rem]  md:w-[11rem] focus:outline-none"
                  />
               </div>
               <div className="flex items-center justify-between mb-5">
                  <p className="flex text-[14px] font-semibold md:text-[11px]">Card number <MdStarRate className="text-red-600 text-[10px]" /></p>
                  <input
                     type="number"
                     className="border-gray-500 border-[1px] py-[5px] px-[8px] w-[16rem]  md:w-[11rem] focus:outline-none"
                  />
                  <div className="flex absolute right-6 gap-[2px]">
                     <img src="/public/payment.png" className="h-[8px] w-[3rem]"  alt="paymentImage"/>
                  </div>
                  <div className="flex absolute right-4 mt-14">
                     <FaLock className="text-[10px]" />
                  </div>
               </div>
               <div className="flex items-center gap-[13px]">
                  <p className="flex text-[14px] md:text-[11px] font-semibold">Expiration date <MdStarRate className="text-red-600 text-[10px] " /></p>
                  <input
                     type="text"
                     className="border-gray-500 border-[1px] p-1 w-[3rem] md:w-[2rem] focus:outline-none"
                  />
                  <input
                     type="text"
                     placeholder="Year"
                     className="border-gray-500 border-[1px] p-1 w-[4.5rem] md:w-[2.9rem] -ml-[14px] focus:outline-none"
                  />
                  <div className="flex items-center gap-[5px]">
                     <p className="flex text-[11px] font-semibold">CVV <MdStarRate className="text-red-600 text-[10px]" /></p>
                     <input
                        type="text"
                        className="border-gray-500 border-[1px] py-[5px] px-[4px] w-[4.5rem] md:w-[2.8rem] focus:outline-none"
                     />
                  </div>
               </div>
               <div className="mt-5">
                  <div className="flex flex-col">
                     <Checkbox
                        label={
                           <Typography color="blue-gray" className="font-medium text-[11px]">
                              Use same billing address as my shipping address
                           </Typography>
                        }
                     />
                     <Checkbox
                        label={
                           <Typography color="blue-gray" className="font-medium text-[11px]">
                              Set as default payment method
                           </Typography>
                        }
                     />
                  </div>
               </div>
               <button
                  type="submit"
                  className="w-full mt-5 bg-black text-[#fff] py-2 font-semibold"
                  onClick={() => navigate("/shoporderplaced")}
               >
                  Pay to Order
               </button>
            </form>
            <div className="mt-5">
               <div className="flex flex-col">
                  <Checkbox
                     label={
                        <Typography color="blue-gray" className="font-medium text-[12px]">
                           PayPal
                        </Typography>
                     }
                  />
                  <Checkbox
                     label={
                        <Typography color="blue-gray" className="font-medium text-[12px]">
                           Pay with UPI or Scan QR
                        </Typography>
                     }
                  />
                  <Checkbox
                     label={
                        <Typography color="blue-gray" className="font-medium text-[12px]">
                           Pay with Amazon UPI
                        </Typography>
                     }
                  />
                  <Checkbox
                     label={
                        <Typography color="blue-gray" className="font-medium text-[12px]">
                           Wallets
                        </Typography>
                     }
                  />
                  <Checkbox
                     label={
                        <Typography color="blue-gray" className="font-medium text-[12px]">
                           Net banking
                        </Typography>
                     }
                  />
               </div>
            </div>
         </div>
      </Fragment>
   )
}

export default Payment