import React, { useState } from "react";
import { Stepper, Step } from "@material-tailwind/react";
import { wishlistProducts } from "../../Datas/Datas";
import { useNavigate } from "react-router-dom";

const OrderDetails = () => {
   const [activeStep, setActiveStep] = useState(2);
   const navigate = useNavigate();

   return (
      <div className="px-2">
         <p className="text-center text-[#000] font-bold text-[20px] mb-2">Order Details</p>
         <p className="text-[13px] font-semibold mb-2">Purchased on Apr 28, 2024</p>
         {wishlistProducts.map((data) => (
            <div className="grid grid-cols-12 gap-2 mb-4" key={data.id}>
               <div className="col-span-4">
                  <img
                     src={data.imgUrl}
                     alt="img"
                     className="img-shadow rounded-2xl h-[5.9rem] w-full object-cover bg-[#fff]"
                  />
                  <p className="line-clamp-1 text-[12px] font-bold mt-[4px]">{data.name}</p>
                  <p className="line-clamp-2 text-[10px] leading-3">{data.description}</p>
               </div>
               <div className="col-span-8">
                  <div className="w-full py-4 px-5 pt-4 pb-6">
                     <Stepper
                        activeStep={activeStep}
                        lineClassName="bg-gray-600"
                        activeLineClassName="bg-gray-600"
                     >
                        <Step
                           className="h-3 w-3 !bg-gray-400 text-[#000] cursor-pointer"
                           activeClassName="ring-0 !bg-red-500 text-[#fff]"
                           completedClassName="!bg-green-500 text-[#fff]"
                           onClick={() => setActiveStep(1)}
                        >
                           <div className="absolute -bottom-[1.3rem] w-max text-center text-[10px]">
                              <p className=" text-[#000]">Shipped</p>
                           </div>
                        </Step>
                        <Step
                           className="h-3 w-3 !bg-gray-400 text-[#000] cursor-pointer"
                           activeClassName="ring-0 !bg-red-500 text-[#000]"
                           completedClassName="!bg-green-500 text-[#fff]"
                           onClick={() => setActiveStep(2)}
                        >
                           <div className="absolute -bottom-[1.3rem] w-max text-center text-[10px]">
                              <p className=" text-[#000]">Transit</p>
                           </div>
                        </Step>
                        <Step
                           className="h-3 w-3 !bg-gray-400 text-[#000] cursor-pointer"
                           activeClassName="ring-0 !bg-red-500 text-[#fff]"
                           completedClassName="!bg-green-500 text-[#fff]"
                           onClick={() => setActiveStep(3)}
                        >
                           <div className="absolute -bottom-[1.3rem] w-max text-center text-[10px]">
                              <p className=" text-[#000]">Delivered</p>
                           </div>
                        </Step>
                     </Stepper>
                  </div>
                  {activeStep === 2 && <p className="text-center text-[10px]">Current Status - Transit in Canada</p>}
                  <div className={`${activeStep === 2 ? "mt-[10px]" : "mt-[25px]"} px-2 leading-[13px] relative`}>
                     <div className="flex flex-col">
                        <p className="text-[10px] font-bold">Price: <span className="text-gray-800">${data.price}</span></p>
                        <p className="text-[10px] font-bold">QTY: <span className="text-gray-800">{data.qty}</span></p>
                        <p className="text-[10px] font-bold">Manufacture: <span className="text-gray-800">{data.manufacture}</span></p>
                        <p className="text-[10px] font-bold text-yellow-900">485 Rating</p>
                     </div>
                     <div className="flex justify-between">
                        <p className="font-bold text-[#000] text-[10px]">Excepted Delivery</p>
                        <p className="font-bold text-green-500 text-[10px]">29, FEB 2024</p>
                     </div>
                     <button 
                        onClick={() => navigate("/ordercancel")}
                        className="absolute text-[10px] right-2 top-0 bg-gray-300 hover:bg-red-700 text-black hover:text-white hover:shadow-2xl px-[6px] py-[3px] rounded-sm">
                        Cancel
                     </button>
                  </div>
                  
               </div>
            </div>
         ))}
      </div>
   )
}

export default OrderDetails