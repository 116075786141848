import React, { Fragment, useRef, useState } from "react";
import { FaFlag } from "react-icons/fa6";
import { AiFillLike } from "react-icons/ai";
import { FaCommentDots } from "react-icons/fa";
import { FaShareSquare } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import CreatedGroupTab from "../Component/CreatedGroup/CreatedGroupTab";
import CreatedGroupProfileSection from "../Component/CreatedGroup/CreatedGroupProfileSection";
import { useNavigate } from "react-router-dom";
import { useOutsideClick } from "../Hooks/useOutsideClick";
import { Card } from "@material-tailwind/react";
import { LockConfirmationModal } from "../Component/Modals/UserCreatedGroup/LockConfirmationModal";
import ToolsModal from "../Component/Modals/UserCreatedGroup/ToolsModal";

export default function CreatedInduvitualGroup() {
   const navigate = useNavigate();
   const menuRef = useRef(null);
   const Toolref = useRef(null);
   const [menuId, setMenuId] = useState(null);
   const [open, setOpen] = useState(false);
   const [openTool, setOpenTool] = useState(false);
   const [groupLock, setGroupLock] = useState(false);

   const handleOpenTool = () => setOpenTool(!openTool);

   const handleOpen = (value) => {
      if (value) {
         setGroupLock(value);
      } else {
         setGroupLock(value);
      }
      setOpen(!open);
   };
   const handleClickThreeDots = (id) => setMenuId(id);

   useOutsideClick(menuRef, () => setMenuId(null));
   useOutsideClick(Toolref, () => setOpenTool(null));

   return (
      <Fragment>
         <section className="h-full overflow-hidden bg-black relative">
            <div className="w-full flex justify-center items-center absolute top-0">
               <p className="text-[#ff3b3b] text-center font-bold text-[8px]">
                  CC  GROUP OWNER PAGE / FE / V8 / JULY 08, 2024
               </p>
            </div>

            <CreatedGroupProfileSection
               handleOpen={handleOpen}
               handleOpenTool={handleOpenTool}
               groupLock={groupLock}
            />

            <CreatedGroupTab />

            <div className="h-[55%] md:h-[50%] overflow-y-scroll">
               <div className="grid grid-cols-12 px-3 pt-2 pb-3">
                  <div className="col-span-2">
                     <img
                        alt="profilepic"
                        className="h-12 md:h-10 w-12 md:w-10 rounded-full object-cover"
                        src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                     />
                  </div>
                  <div className="col-span-9">
                     <p className="text-white text-[18px] md:text-[15px]">John Smith</p>
                     <p className="text-[#3ea8d8] text-[15px] md:text-[12px] font-bold">10 Day Ago</p>
                  </div>
                  <div className="col-span-1 relative">
                     <HiDotsVertical
                        className="text-white text-[25px] absolute -right-2 cursor-pointer"
                        onClick={() => handleClickThreeDots(1)}
                     />
                     {menuId === 1 && (
                        <Card
                           className="w-24 absolute top-4 right-4 rounded-md p-1 text-black bg-white z-10"
                           ref={menuRef}
                        >
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Save
                           </p>
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Hide
                           </p>
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Block User
                           </p>
                           <p
                              className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                              onClick={() => navigate("/createdgroup/postreport")}
                           >
                              Report
                           </p>
                        </Card>
                     )}
                  </div>
                  <div className="col-span-2"></div>
                  <div className="col-span-10">
                     <p className="text-white text-[15px]">
                        "Hey guys, Look at this car."
                     </p>
                     <img
                        alt="postimg"
                        className="w-full object-cover pt-2 rounded"
                        src="https://images.unsplash.com/photo-1632441730372-d8509de481d1?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                     />
                  </div>
                  <div className="col-span-12">
                     <div className="flex justify-end gap-[10px] pt-[6px]">
                        <FaFlag
                           className="text-white cursor-pointer hover:text-[#F80000]"
                           onClick={() => navigate("/createdgroup/postreport")}
                        />
                        <AiFillLike className="text-white cursor-pointer hover:text-[#31c629]" />
                        <FaCommentDots
                           className="text-white cursor-pointer hover:text-[#31c629]"
                           onClick={() => navigate("/createdgroup/post/comment")}
                        />
                        <FaShareSquare className="text-white cursor-pointer hover:text-[#31c629]" />
                     </div>
                  </div>
               </div>
               <div className="grid grid-cols-12 px-3 pt-1 pb-3">
                  <div className="col-span-2">
                     <img
                        alt="profilepic"
                        className="h-12 md:h-10 w-12 md:w-10 rounded-full object-cover"
                        src="https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1961&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                     />
                  </div>
                  <div className="col-span-9">
                     <p className="text-white text-[18px] md:text-[15px]">Emily Johnson</p>
                     <p className="text-[#3ea8d8] text-[15px] md:text-[12px] font-bold">
                        103 Day Ago
                     </p>
                  </div>
                  <div className="col-span-1 relative">
                     <HiDotsVertical
                        className="text-white text-[25px] absolute -right-2 cursor-pointer"
                        onClick={() => handleClickThreeDots(2)}
                     />

                     {menuId == 2 && (
                        <Card
                           className="w-24 absolute top-4 right-4 rounded-md p-1 text-black bg-white z-10"
                           ref={menuRef}
                        >
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Save
                           </p>
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Hide
                           </p>
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Block User
                           </p>
                           <p
                              className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                              onClick={() => navigate("/createdgroup/postreport")}
                           >
                              Report
                           </p>
                        </Card>
                     )}
                  </div>
                  <div className="col-span-2"></div>
                  <div className="col-span-10">
                     <p className="text-white text-[15px]">
                        "Guys, Look at my new car how it is!! Give some feedback."
                     </p>
                     <img
                        alt="postimg"
                        className="w-full object-cover pt-2 rounded"
                        src="https://images.unsplash.com/photo-1609138315745-4e44ac3bbd8d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                     />
                  </div>
                  <div className="col-span-12">
                     <div className="flex justify-end gap-[10px] pt-[6px]">
                        <FaFlag
                           className="text-white cursor-pointer hover:text-[#F80000]"
                           onClick={() => navigate("/createdgroup/postreport")}
                        />
                        <AiFillLike className="text-white cursor-pointer hover:text-[#31c629]" />
                        <FaCommentDots
                           className="text-white cursor-pointer hover:text-[#31c629]"
                           onClick={() => navigate("/createdgroup/post/comment")}
                        />
                        <FaShareSquare className="text-white cursor-pointer hover:text-[#31c629]" />
                     </div>
                  </div>
               </div>
            </div>

            <LockConfirmationModal
               open={open}
               handleOpen={handleOpen}
               groupLock={groupLock}
            />
            
            {openTool && (
               <div className="absolute h-full w-full z-50 top-0 ">
                  <div className="h-full w-full flex justify-center items-center relative">
                     <div className="absolute h-full w-full bg-gray-300 opacity-25"></div>
                     <ToolsModal Toolref={Toolref} />
                  </div>
               </div>
            )}
         </section>
      </Fragment>
   );
}
