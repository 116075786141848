import React from "react";
import { FaCircleArrowDown } from "react-icons/fa6";

export default function StreamComments({
  showMore,
  commants,
  User_profile,
  setShowMore,
  bottomRef,
}) {
  return (
    <React.Fragment>
      <div className="absolute bottom-10 w-full gap-2 grid grid-cols-8 justify-between h-2/4 px-4 z-20">
        <div
          className={` col-span-6 ${
            showMore
              ? "overflow-hidden justify-end items-end"
              : "overflow-scroll"
          } items-end  no-scrollbar `}
        >
          <div className="flex flex-col justify-end h-full w-auto ">
            <div className="flex justify-end mb-2">
              <FaCircleArrowDown
                color="white"
                className={`text-[1rem] cursor-pointer ${
                  showMore ? "rotate-180 duration-150 transform" : ""
                }`}
                onClick={() => setShowMore(!showMore)}
              />
            </div>

            <div
              className={` ${
                showMore ? "" : "overflow-scroll"
              } items-end  no-scrollbar relative `}
            >
              {/* <div className="bg-gray-100 bg-[#3a3a3a82] rounded-2xl p-2 shadow-2xl  grid grid-cols-12  sticky top-0 opacity-45"></div> */}
              {!showMore ? (
                <>
                  {commants?.map((item) => (
                    <div
                      className="mb-1 text-[8px]   px-2 py-1  justify-center items-center w-auto overflow-auto"
                      ref={bottomRef}
                    >
                      {/* <div className="bg-blue-gray-300"> */}
                      <div className=" bg-[#3a3a3a82] rounded-2xl p-2 shadow-2xl   grid grid-cols-12 items-center gap-2 w-auto">
                        <div className="col-span-12 flex items-center gap-2">
                          <img
                            src={
                              item.icon ? item.icon : `${User_profile.avatar}`
                            }
                            alt="avatar"
                            class="relative  inline-block h-5 w-5 !rounded-full  object-cover object-center"
                          />
                          <p className="text-[white] font-bold text-[8px] col-span-10  w-auto">
                            {item.name}
                          </p>
                        </div>
                        <div className="text-[#fff] font-bold text-[10px] col-span-10   w-auto">
                          {item.text}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <p className="mb-2 text-[8px] ">
                    <span className="h-5 w-5">
                      <img
                        src={
                          commants[commants.length - 1].icon
                            ? commants[commants.length - 1].icon
                            : `${User_profile.avatar}`
                        }
                        alt="avatar"
                        class="relative inline-block h-6 w-6 !rounded-full  object-cover object-center"
                      />
                    </span>
                    
                    <span className="ml-2 text-[#fff] font-bold text-[10px] ">
                      {commants[commants.length - 1].text}
                    </span>

                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-2 hidden"></div>
      </div>
    </React.Fragment>
  );
}
