import React, { Fragment } from "react"
import Security from "../../Component/settingspage/Security"

const SecurityPage = ({isMobile}) => {
   return (
      <Fragment>
         <p className="absolute top-1 text-red-700 uppercase font-bold w-full text-[10px] text-center">
            cc settings Security / fe / v1 / july 03, 2024
         </p>
         <div className="pt-1">
            <Security isMobile={isMobile} />
         </div>
      </Fragment>
   )
}

export default SecurityPage