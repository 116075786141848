import React from "react";
import character from "../Assets/LoginPageIcon/01358ade5ae9e8bf5d2cdb81b1598de7.png";
import { useLocation, useNavigate } from "react-router-dom";
import ChangeNewPassWord from "../Components/MobileVersion/ChangeNewPassWord/ChangeNewPassWord";
import * as Yup from "yup";
import { useFormik } from "formik";
import HeaderTab from "../Component/Login/LoginHeader";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Footer from "../Component/Footer/Footer";
import { REACT_APP_MAIN_URL } from "../API/MainUrl";

const ChangePassword = () => {
   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);

   const uid = queryParams.get("uid");
   const token = queryParams.get("token");

   const navigate = useNavigate();
   
   const handleSubmitPassword = async (values) => {
      toast.loading("Loading...");
      try {
         const formData = new FormData();
         formData.append("password", values.password);
         formData.append("confirm_password", values.confirmPassword);
         formData.append("token", token);
         formData.append("uid", uid);
         const response = await axios.post(
            `${REACT_APP_MAIN_URL}api/reset-password/`,
            formData
         );
         const result = response.data;
         if (result.success == true) {
            toast.dismiss();
            await toast.success(result.message);
            formik.resetForm();
            setTimeout(() => {
               navigate("/login");
            }, 2000);
         }
         return result;
      } catch (error) {
         toast.dismiss();
         toast.error(error.response.data.message);
         return error;
      }
   };

   const validateSchema = Yup.object({
      password: Yup.string()
         .required("Please Enter your password")
         .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
         ),

      confirmPassword: Yup.string()
         .required()
         .oneOf([Yup.ref("password"), null], "Passwords must match"),
   });

   const formik = useFormik({
      initialValues: {
         password: "",
         confirmPassword: "",
      },
      validationSchema: validateSchema,

      onSubmit: (values, { resetForm }) => {
         handleSubmitPassword(values);
      },
   });

   return (
      <>
         {/* <div className="hidden md:block overflow-hidden bg-[#74cbfc] h-[100vh]">
            <p className="absolute uppercase text-[#ff3b3b] right-5 font-semibold">
               CC Change Password / V2 / FE / Mar 06, 2024
            </p>
            <HeaderTab />
            <div className="flex justify-center items-center mt-[3rem]">
               <div className="w-[40%] h-[80%] bg-[#fff] rounded-2xl relative flex justify-center items-center shadow-2xl">
                  <img
                     alt="changepasswordimg"
                     src={character}
                     className="absolute h-[30rem] left-[23rem] mt-[25px]"
                  />
                  <div className="p-4 w-[70%]">
                     <p className="text-center text-[2.5rem] font-bold mt-2 tracking-wide">
                        Change Password
                     </p>
                     <p className="text-center text-[1rem] font-semibold mb-3">
                        Lets create new password
                     </p>
                     <form className="px-14" onSubmit={formik.handleSubmit}>
                        <div className="flex flex-col">
                           <span className="text-[#00A3FF] font-bold">
                              marydavis@example.com
                           </span>

                           <div className=" mb-1 h-16 w-full">
                              <input
                                 type="password"
                                 name="password"
                                 placeholder="New password"
                                 onChange={formik.handleChange}
                                 value={formik.values.password}
                                 onBlur={formik.handleBlur}
                                 className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md mb-1 w-full"
                              />
                              {formik.touched.password && formik.errors.password && (
                                 <p className="text-[#ff3b3b] md:text-[15px]  font-medium">
                                    {formik.errors.password}
                                 </p>
                              )}
                           </div>

                           <div className="mt-1 mb-1 h-16 w-full">
                              <input
                                 type="password"
                                 name="confirmPassword"
                                 placeholder="New password"
                                 className="border-[1px] border-black px-3 py-2 focus:outline-none rounded-md w-full"
                                 onChange={formik.handleChange}
                                 value={formik.values.confirmPassword}
                                 onBlur={formik.handleBlur}
                              />
                              {formik.touched.confirmPassword &&
                                 formik.errors.confirmPassword && (
                                    <p className="text-[#ff3b3b] md:text-[15px]  font-medium">
                                       {formik.errors.confirmPassword}
                                    </p>
                                 )}
                           </div>
                        </div>
                        <div className="flex justify-start items-center gap-2  mb-5">
                           <div className="flex items-center gap-2">
                              <input type="checkbox" className="h-4 w-4 cursor-pointer" />
                              <p className="font-medium">Logout from all devices</p>
                           </div>
                        </div>
                        <div className="flex justify-center mb-10">
                           <button
                              type="submit"
                              className="bg-[#00A3FF] text-[#fff] text-[1rem] font-semibold w-[50%] shadow-lg py-[7px] rounded-lg"
                           >
                              Reset & Login
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
            <Footer />
         </div> */}
         <ChangeNewPassWord formik={formik} />
         <Toaster position="top-right" />
      </>
   );
};

export default ChangePassword;
