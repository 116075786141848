import React from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export function UploadVideoWaringModal({ open, handleOpen, message }) {
  const navigate = useNavigate();
  const handleCancel = () => {
    handleOpen();
    navigate("/home");
  };
  return (
    <>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardBody className="flex flex-col gap-4">
            <Typography variant="h4" color="red">
              Warning
            </Typography>
            <Typography>
              {message} 
            </Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <Button variant="gradient" onClick={handleCancel} fullWidth>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
}
