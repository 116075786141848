
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../API/API";



export const enterOtp = createAsyncThunk("enterOtp", async (data) => {
  try {
    const response = await axiosInstance.post(`reset-password/`, data);
    const result = response.data;
    return result
    // Add logic to handle the response here
  } catch (error) {
    console.error("Error:", error.response.data); // Log the error response
  }
});

export const EnterOtpSlice = createSlice({
  name: "enterOtp",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // enterOtp action promises
    builder.addCase(enterOtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(enterOtp.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(enterOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default EnterOtpSlice.reducers;
