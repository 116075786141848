import { Button } from "@material-tailwind/react";
import React from "react";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function EmptyPrivate() {
   const navigate = useNavigate();
   return (
      <div className="h-full relative">
         <div className="relative h-full w-full bg-[#000] z-0">
            <div className="absolute h-full w-full flex justify-center items-center px-3 py-2 flex-col">
               <p className="my-4">
                  <span className="text-[white] text-[12px] font-[700] uppercase">
                     CREATE PASSWORD TO ACCESS PRIVATE PAGE
                  </span>
               </p>
               <Button
                  className="bg-orange-700 hover:bg-orange-800 flex gap-2"
                  onClick={() => navigate("/account/createpassword")}
               >
                  <FaLock className="text-[1rem]" /> Create Password
               </Button>
            </div>
         </div>
      </div>
   );
}
